import React, { useEffect,useState } from 'react';
import { useTranslation } from "react-i18next";import { Card,CardHeader, CardBody, Col, Container, Input, Label, Row, Table,Button,Modal, ModalBody, ModalHeader,ModalFooter, Spinner} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
// import { Modal, Spinner } from 'react-bootstrap';

import { Link } from 'react-router-dom';
//import { BasicTable, ScrollVertical, ScrollHorizontal, AlternativePagination, FixedHeaderDatatables, ModalDataDatatables, AjaxDatatables } from '../Tables/DataTables/datatableCom';
import { useQuery,useMutation, gql } from '@apollo/client';
// import checkTokenValidity from '../../Layouts/Footer'
import DataTable from 'react-data-table-component';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useCreateLog } from  "../../Components/Common/functions";  // Adjust the path as necessary
import Select from 'react-select';
import {getDescendantsUsernames,getUsernameByID} from "../../Components/Common/functions"


const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // Replace with your actual token
  const token = localStorage.getItem("token");

  // Add custom headers
  return {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem("token"),
    },
  };
});
// Initialize Apollo Client with custom headers
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // cache: 'no-cache'
  cache: new InMemoryCache(),
});
// password
// id
// is_enabled
// is_panic
// current_game
// gp_user_id
// credits
// bonus
// username
const DESCENDANTS_QUERY = gql`
query MyQuery($id: Int!) {
  operators_by_pk(id: $id) {
    descendants
  }
}

`;
const USER_QUERY = gql`
query MyQuery($_in: [Int!]!, $_in1: [Int!]!) {
  backoffice_messages(
    where: {_or: [{from_operator_id: {_in: $_in}}, {to_operator_id: {_in: $_in1}}]}
    order_by: {created_at: desc}
  ) {
    created_at
    from_operator_id
    id
    is_read
    message_body
    message_title
    to_operator_id
  }
}
`;
const ENABLE_USER_MUTATION = gql`
mutation MyMutation($from_operator_id: Int!, $is_read: Boolean = false, $message_body: String!, $to_operator_id: Int!, $message_title: String = "") {
  insert_backoffice_messages(
    objects: {from_operator_id: $from_operator_id, is_read: $is_read, message_body: $message_body, message_title: $message_title, to_operator_id: $to_operator_id}
  ) {
    affected_rows
  }
}
`;

// const ENABLE_USER_NOTIN = gql`
// mutation MyMutation($is_enabled: Boolean = false, $_nin: [Int!] = 10) {
//   update_users(where: {id: {_nin: $_nin}}, _set: {is_enabled: $is_enabled}) {
//     affected_rows
//   }
// }
// `;

// const ENABLE_PANIC_USER = gql`
// mutation MyMutation($is_panic: Boolean, $_in: [Int!]) {
//   update_users(where: {id: {_in: $_in}}, _set: {is_panic: $is_panic}) {
//     affected_rows
//   }
// }
// `;

let theNotSelected=[]
let theFilteredData=[]
let initialRows=[]
// const generateTestData = () => {
//   const testData = []
//   for (let i = 10001; i <= 20000; i++) {
//     testData.push({
//       bonus: Math.floor(Math.random() * 100),
//       credits: Math.floor(Math.random() * 1000),
//       current_game: `Game ${i}`,
//       gp_user_id: `user${i}`,
//       id: i,
//       is_enabled: Math.random() < 0.5,
//       password: `password${i}`,
//       shop_id: Math.floor(Math.random() * 10),
//       username: `user${i}`,
//     })
//   }
//   return testData
// }

// const testData = generateTestData()
function compareArrays(arrayA, arrayB) {
  console.log("resultA",arrayA)
  const idsB = arrayB.map((item) => item.id);
  const result = arrayA.filter((item) => !idsB.includes(item.id));
  console.log("result",result)
  return result;
}

const actionObj={1:"Enable Panic ",
2:"Disable Panic",
3:"Enable User",
4:"Disable User"}

const Starter = () => {
const { t } = useTranslation();
document.title ="Messages";
const { createLog } = useCreateLog();

const [rows, setRows] = useState([])
const [isLoading, setIsLoading] = useState(true)
const [selectedOption, setSelectedOption] = useState('');
const [filterValue, setFilterValue] = useState('');
const [sortState, setSortState] = useState({});
const [isLoadingModal, setIsLoadingModal] = useState(false);
const [isModalOpen, setIsModalOpen] = useState(false);
const [queryURL, setQueryURL] = useState('');
const [curMessage, setcurMessage] = useState('');
const [curTitle, setcurTitle] = useState('');

const [currentOp, setCurrentOp] = useState(-1);
const [operatorsDropList, setOperatorsDropList] = useState([]);
const [showSuccessMessage, setShowSuccessMessage] = useState(false);
const [mutationSuccess, setMutationSuccess] = useState(false);
const [render, setRender] = useState(false);

const handleChange = (selectedOption) => {
  setCurrentOp(selectedOption.value);
};
const onKeyPress = (e) => {
  const { key, value } = e;
  if (key === "Enter") {
    setcurMessage(value);
    // addMessage(currentRoomId, currentUser.name);
  }
};

function mergeMessages(arr) {
  // Create a copy of the array and sort it by created_at
  let copiedArray = JSON.parse(JSON.stringify(arr));
  copiedArray.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  let result = [];
  let i = 0;
  while (i < copiedArray.length) {
      let message = copiedArray[i];
      let isReadCount = message.is_read ? 1 : 0;
      let isReadToOperatorIds = message.is_read ? [message.to_operator_id] : [];

      let j = i + 1;
      while (j < copiedArray.length) {
          let nextMessage = copiedArray[j];

          // Check if the next message should be merged with the current one
          let shouldMerge = nextMessage.message_body === message.message_body
              && nextMessage.message_title === message.message_title
              && new Date(nextMessage.created_at).getTime() - new Date(message.created_at).getTime() <= 3000;

          if (!shouldMerge) {
              break;
          }

          if (nextMessage.is_read) {
              isReadCount++;
              isReadToOperatorIds.push(nextMessage.to_operator_id);
          }

          j++;
      }

      message.is_read_count = isReadCount;
      message.is_read_to_operator_ids = isReadToOperatorIds;
      result.push(message);

      i = j;  // Move to the next group of messages
  }

  return result;
}




const handleConfirm = () => {
  // Perform the action you want to execute here
  handleButtonClick()
  setIsModalOpen(false); // Close the modal
};

const handleCancel = () => {
  setIsModalOpen(false); // Close the modal
};

const handleClick = () => {
  if(selectedOption!=0&&selectedRows.length>0){
    setIsModalOpen(true); // Open the modal
  }
};

function transformCreditsToCurrency(credits) {
  const currency = (credits / 100).toFixed(2);
  return currency;
}

const [enableUserMutation, { loading, error }] = useMutation(ENABLE_USER_MUTATION, {
  onCompleted: (data) => {
    console.log(data);
    // const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]

    // for(let i=0;i<idArraysel.length;i++){
    //   createLog(idArraysel[i],"operator",selectedOption==3?"Operator Enabled":"Operator Disabled",true)
    // }
    // // Handle the result of the mutation here
  },
  onError: (error) => {
    // const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]

    // for(let i=0;i<idArraysel.length;i++){
    //   createLog(idArraysel[i],"operator",selectedOption==3?"Operator Enabled":"Operator Disabled",false)
    // }
    alert(error);
    // Handle the error here
  }
});

// const [enablePanicUserMutation, { dataAccept1, loadingAccept1, errorAccept1 }] = useMutation(ENABLE_PANIC_USER);



function handleSelectChange(event) {
  const value = event.target.selectedOptions[0].value;
  setSelectedOption(value);
}


const redirectWithId = (id) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set('id', id);
  const params = urlSearchParams.toString();
  // console.log("paransss",params)
  window.location.href = `/#/userDetails?${params}`;
}

const filterTable = (event, originalData, setData) => {
  setFilterValue(event.target.value);
  const searchTerm = event.target.value.toLowerCase()
  const filteredData = originalData.filter((row) => {
    return Object.keys(row).some((key) => {
      const cellValue = String(row[key]).toLowerCase()
      return cellValue.includes(searchTerm)
    })
  })
  // console.log("filteredSData",originalData)
  theFilteredData=filteredData
  setRows(filteredData)
}



const { refetch } = 
useQuery(
  DESCENDANTS_QUERY,

  {
    onError:(err) =>{
      console.log(err)
      // window.location.href = "/#/login"

    },
    variables:  {"id": localStorage.getItem("opID")},

    // variables: {domain_id: {"_eq": locStorageItem}},
    onCompleted: async(data) => {
      setRender(false)

      const result=parseInt(localStorage.getItem("opID"))==parseInt(1)
      if(!result){
        window.location.href = "/#/home"
      }else{
                setRender(true)
      }
      console.log("TA DATA:",data)
      if(data.operators_by_pk!=null){
        const operatorsResponse = await client.query({
          query: USER_QUERY,
          variables: { "_in": data.operators_by_pk.descendants,"_in1": data.operators_by_pk.descendants },
          fetchPolicy: 'network-only', // or 'cache-and-network'
        });        console.log("operatorsResponse",operatorsResponse)
        if(operatorsResponse.data.backoffice_messages!=undefined){
          const mergedMessages=mergeMessages(JSON.parse(JSON.stringify(operatorsResponse.data.backoffice_messages)))
          console.log("mergedMessages",mergedMessages)
          const queryString = window.location.hash;
          const questionMarkIndex = queryString.indexOf('?');
          console.log("queryString",queryString)

          // const textAfterQuestionMark = ;
          // console.log("queryString",queryString.substring(questionMarkIndex + 1))
          const searchTerm = questionMarkIndex !== -1?queryString.substring(questionMarkIndex + 1):filterValue.toLowerCase()
          console.log("searchTerm",searchTerm)
          setFilterValue(searchTerm)

          const filteredData = (mergedMessages).filter((row) => {
            return Object.keys(row).some((key) => {
              const cellValue = String(row[key]).toLowerCase()
              return cellValue.includes(searchTerm)
            })
          })
          // console.log("filteredSData",originalData)
          theFilteredData=await sortData(filteredData, sortState);
          
  
          // setRows(data.users)
          // initialRows=data.users
          // theFilteredData=data.users
          await setRows([])

          await setRows(theFilteredData)
          initialRows=mergedMessages
          theFilteredData=theFilteredData
        }

      }


      setIsLoading(false)
      setIsLoadingModal(false);

    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only', // or 'cache-and-network'

    // pollInterval:5000
  }
);

// const userId = '123'; // This is the ID you want to pass to the next page

//       {/* Link with parameters */}
//       <Link to={`/userDetails/${userId}`}>User Details</Link>

//       {/* Button with parameters */}
//       <button onClick={() => window.location.href = `/#/userDetails/${userId}`}>
//         User Details
//       </button>




//       import React from 'react';
// import { useParams } from 'react-router-dom';

// const UserDetails = () => {
//   const { userId } = useParams();

//   return (
//     <div>
//       <h1>User Details</h1>
//       <p>User ID: {userId}</p>
//       {/* Other user details */}
//     </div>
//   );
// };

// export default UserDetails;

useEffect(() => {
    
  async function fetchData() {
        const operatorsResponse = await client.query({
        query: DESCENDANTS_QUERY,
        variables:  {"id": localStorage.getItem("opID")},
        fetchPolicy: 'network-only', // or 'cache-and-network'
      });        console.log("operatorsResponse",operatorsResponse)
    // await setCurrentOp(parseInt(localStorage.getItem("opID")))
    const options=await getDescendantsUsernames(operatorsResponse.data.operators_by_pk.descendants)
    // options.unshift({id:parseInt(localStorage.getItem("opID")),username:localStorage.getItem("username")})
    await setOperatorsDropList(options.map((operator) => ({
      value: operator.id,
      label: operator.username,
    })))
  }
  // let theDescendants=localStorage.getItem("descendants")
  // let theUsername=localStorage.getItem("username")

  // console.log("descendants",theDescendants)
  // console.log("theUsername",theUsername)
  // 
  fetchData();
  console.log("operatorsDropList",operatorsDropList)
  if (mutationSuccess) {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000);
    setMutationSuccess(false)
  }
}, [mutationSuccess]);
const [toggledClearRows, setToggledClearRows] = useState(false);

const [selectedRows, setSelectedRows] = React.useState([]);
// let isAllSelected=false
const dataTableRef = React.createRef();

// const handleClearSelection = () => {
//   setSelectedRows([]); // Clear the selected rows state
//   dataTableRef.current.clearSelectedRows(); // Clear the selected rows in the DataTable component
// };
const handleClearSelection = () => {
  setSelectedRows([]);
  setToggledClearRows(!toggledClearRows);
};


	const handleRowSelected = React.useCallback(state => {
    //mporw apla panta na exw ta selected je ta not selected je na stellw jina pu en liotera xwris na kamnw ulla ta ifs
    // if(state.allSelected){
    //   isAllSelected=true
    //   theNotSelected=[]
    // }else{
    //   if(state.selectedCount==0){
    //     isAllSelected=false
    //   }else{
    //     theNotSelected=compareArrays(theFilteredData,state.selectedRows)
    //   }
    // }
    // setSelectedRows([]);

		setSelectedRows(state.selectedRows);
        console.log("eta rows",state.selectedRows)
        console.log("et state",state)
        console.log("eta not selected",theNotSelected)

	}, []);


  
  // const handleEnablePanic = async () => {
  //   setIsLoadingModal(true);
  //     const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]
  //     console.log("the in idArray",idArraysel)
  //     console.log("selection",selectedOption==1)

  //     await enablePanicUserMutation({
  //       variables: {
  //         is_panic: selectedOption==1, // Set to true to enable the user
  //         _in: idArraysel // Set to the ID of the user to enable
  //       }
  //     });
  //   // }
  //   await refetch({})

  // };


  const handleSendMessage= async () => {

if(curMessage.length<=0){
  alert("Message is empty")
  return
}
// if(currentOp==-1){
//   alert("Choose receiver operator")
//   return
// }
for(const operator of operatorsDropList){
  await enableUserMutation({
    variables: {
      from_operator_id: localStorage.getItem("opID"),
      to_operator_id: parseInt(operator.value),
      message_title:curTitle,
      message_body:curMessage,
      // to_operator_id:parseInt(currentOp)
    
    }
  });
}

      await setcurMessage("")
    // }
    await refetch({})
  };

    const handleButtonClick = async () => {

    switch(selectedOption) {
      case '1':
        // await handleEnablePanic()
        // await handleClearSelection()
        console.log('Option 1 selected');
        break;
      case '2':
        // await handleEnablePanic()
        // await handleClearSelection()
        // perform action for option 2
        console.log('Option 2 selected');
        break;
      case '3':
        // await handleEnableUser()
        await handleClearSelection()
        // perform action for option 3
        console.log('Option 3 selected');
        break;
      case '4':
        // await handleEnableUser()
        await handleClearSelection()
        // perform action for option 4
        console.log('Option 4 selected');
        break;
      default:
        // do nothing
        break;
    }
    
  }
  const handleSortChange = (column, sortDirection) => {
    setSortState({ column, sortDirection });
  };
  // onClick={handleButtonClick}
  function sortData(data, sortState) {
    const { column, sortDirection } = sortState;
    if (!column || !sortDirection) return data;
  console.log("current column",column)
  console.log("current sortDirection",sortDirection)
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return sortDirection === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  
    return sortedData;
  }

  const customStyles = {
    // header: {
    //   style: {
    //     border: '1px solid #dee2e6'
    //   }
    // },
    cells: {
      style: {
        border: '0.5px solid #dee2e6',
      },
    },
  };

  function formatDate(dateString, timezone) {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: localStorage.getItem("opTimezone"),
    };
    return date.toLocaleString('en-GB', options).replace(',', '');
  }

  const columns = [

  //   {
  //       name: t("From"),
  //       sortable: true,
  //        width: "100px",
  //       sortFunction:  (a, b) =>  a.from_operator_id.localeCompare(b.from_operator_id),
  //       selector: (cell) => {return (<Link to={`/operatorDetails/${cell.id}`} onClick={(e) => {
  //         e.preventDefault();
          
  //         window.location.href = `/#/operatorDetails/${cell.id}`;
  //       }}
  //       style={{ color: 'red'  }}

  //       >{cell.from_operator_id}</Link>);},
        
  //   },
  //   {
  //     name: t("To"),
  //     sortable: true,
  //     width: "100px",
  //     sortFunction:  (a, b) =>  a.to_operator_id.localeCompare(b.to_operator_id),
  //     selector: (cell) => {return (<Link to={`/operatorDetails/${cell.id}`} onClick={(e) => {
  //       e.preventDefault();
        
  //       window.location.href = `/#/operatorDetails/${cell.id}`;
  //     }}
  //     style={{ color: 'red'  }}

  //     >{cell.to_operator_id}</Link>);},
      
  // },
    {
      name: t("Date"),
      width: "150px",
      selector: row => formatDate(row.created_at,'America/New_York'),
      // sortable: true,
      //             style: {
      //       minWidth: '200px', // set minimum width
      //       // maxWidth: '350px' // set maximum width
            
      //     },


  },
  {
    name: t("Title"),
    sortable: true,
    width: "200px",
    sortFunction:  (a, b) =>  a.message_title.localeCompare(b.message_title),
    selector: (cell) => {return (<Link onClick={(e) => {
      e.preventDefault();
      alert(cell.message_title)
      // window.location.href = `/#/operatorDetails/${cell.id}`;
    }}
    style={{ color: 'black'  }}

    >{cell.message_title}</Link>);},
    
},

    {
      name: t("Message"),
      sortable: true,
      // width: "100px",
      sortFunction:  (a, b) =>  a.message_body.localeCompare(b.message_body),
      selector: (cell) => {return (<Link onClick={(e) => {
        e.preventDefault();
        alert(cell.message_body)
        // window.location.href = `/#/operatorDetails/${cell.id}`;
      }}
      style={{ color: 'black'  }}

      >{cell.message_body}</Link>);},
      
  },
  {
    name: t("Seen By"),
    sortable: true,
    width: "100px",
    sortFunction:  (a, b) =>  a.is_read_count.localeCompare(b.is_read_count),
    selector: (cell) => {return (<Link onClick={(e) => {
      e.preventDefault();
      alert(cell.message_title)
      // window.location.href = `/#/operatorDetails/${cell.id}`;
    }}
    style={{ color: 'black'  }}
  
    >{cell.is_read_count}</Link>);},
    
  },

//     {
//         name: t("Panic"),
//         width: "90px",
//         sortable: true,
//         sortFunction: (a, b) => (a.is_panic === b.is_panic ? 0 : a.is_panic ? 1 : -1),
//         selector: (cell) => {
//           return (
//               <React.Fragment>

// {(cell.is_panic)?<i id="0" className="ri-check-fill" style={{ color: 'red' }}></i>:<i id="1" className=" ri-close-fill" style={{ color: 'green' }}></i>}
            
//               </React.Fragment>
//           );
//       },
//         sortable: true
//     },


];

//     const columns = [
//         {
//             name: <span className='font-weight-bold fs-13'>Username</span>,
//             sortable: true,

//             sortFunction: (a, b) => a.username.localeCompare(b.username),
//             selector: (cell) => {return (<Link to={`/userDetails/${cell.id}`} onClick={(e) => {
//               e.preventDefault();
              
//               window.location.href = `/#/userDetails/${cell.id}`;
//             }}
//             >{cell.username}</Link>);},
            
//         },
//         {
//             name: <span className='font-weight-bold fs-13'>Name</span>,
//             selector: row => row.username,
//             sortable: true
//         },
//         {
//             name: <span className='font-weight-bold fs-13'>Credits</span>,
//             selector: row => row.credits,
//             sortable: true
//         },
//         {
//             name: <span  className='font-weight-bold fs-13'>Last login</span>,
//             selector: row => row.last_login,
//             sortable: true,
//             style: {
//               minWidth: '350px', // set minimum width
//               maxWidth: '350px' // set maximum width
              
//             },
//             headerWrapStyles: { 
//               textAlign: "center",
//               minWidth: '350px', // set minimum width
//               maxWidth: '350px' // set maximum width
              
//              }

//         },
//         {
//             name: <span style={{"minWidth":"50px"}} className='font-weight-bold fs-13'>Enabled</span>,
//             sortable: true,
//             sortFunction: (a, b) => (a.is_enabled === b.is_enabled ? 0 : a.is_enabled ? 1 : -1),
//             selector: (cell) => {
//                 return (
//                     <React.Fragment>

//                             {(cell.is_enabled)?<i id="0" className="ri-check-fill" style={{ color: 'green' }}></i>:<i id="1" className=" ri-close-fill" style={{ color: 'red' }}></i>}
                  
//                     </React.Fragment>
//                 );
//             },
//             style: {
//               minWidth: '50px', // set minimum width
//               maxWidth: '50px' // set maximum width
              
//             },
//             headerWrapStyles: { 
//               textAlign: "center",
//               minWidth: '50px', // set minimum width
//               maxWidth: '50px' // set maximum width
              
//              }
//         },
//         {
//             name: <span className='font-weight-bold fs-13'>Panic</span>,
//             sortable: true,
//             sortFunction: (a, b) => (a.is_panic === b.is_panic ? 0 : a.is_panic ? 1 : -1),
//             selector: (cell) => {
//               return (
//                   <React.Fragment>

// {(cell.is_panic)?<i id="0" className="ri-check-fill" style={{ color: 'red' }}></i>:<i id="1" className=" ri-close-fill" style={{ color: 'green' }}></i>}
                
//                   </React.Fragment>
//               );
//           },
//             sortable: true
//         },


//     ];

const rowsPerPageSample={ rowsPerPageText: t('Rows per page:'), rangeSeparatorText: t('of'), noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }

  return (
    <React.Fragment>      
     {render? <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Messages")} pageTitle={t("Home")} />
          {showSuccessMessage && (
        <div className="alert alert-success text-center" role="alert">
                   {t("Success!")}

        </div>
      )}
          <Row>
            <Col xs={12}>
            <Card>
                                {/* <CardHeader>
                                    <h5 className="card-title mb-0">Operators</h5>
                                </CardHeader> */}
                                            <CardHeader>
                              
                              <h5 className="card-title mb-0">New Message</h5>

                              </CardHeader>
                                <CardBody>
                                {/* <Row>
                    <Col md={3} >
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                  >
                    {t("Select Operators")}
                  </Label>
                </Col>
                    <Col md={4} >
                      
                <Select
                isMulti
style={{ backgroundColor: "lightBlue" }} // Set the background color inline
                    onChange={handleChange}
                    placeholder={t("ALL")}
                    options={operatorsDropList}
                    value={operatorsDropList.find((option) => option.value === currentOp)}
                    isSearchable
                    styles={{ 
                      container: (provided) => ({ ...provided, zIndex: 9999 }), 
                      menuPortal: (provided) => ({ ...provided, zIndex: 9999 }), 
                      menu: (provided) => ({ ...provided, zIndex: 9999 }), 
                      control: (provided) => ({ ...provided, zIndex: 9999,backgroundColor: "lightBlue" }),
                  }} 
                  >
                  </Select>
                </Col>
                </Row> */}
                                <div className="chat-input-section p-3 p-lg-4">

                      <form id="chatinput-form">
                      <Row className="g-0 align-items-center" style={{"marginBottom":"20px"}}>


                      <Col md={4} >
  <div className="chat-input-feedback">
    Please Enter a Message
  </div>
  <input
    type="text"
    value={curTitle}
    
    onChange={e => setcurTitle(e.target.value)}
    className="form-control chat-input bg-light border-light"
    id="chat-input"
    placeholder="MESSAGE TITLE"
  />
</Col>


</Row>
                        <Row className="g-0 align-items-center">


                          <div className="col">
                            <div className="chat-input-feedback">
                              Please Enter a Message
                            </div>
                            <input
                              type="text"
                              value={curMessage}
                              onKeyPress={onKeyPress}
                              onChange={e => setcurMessage(e.target.value)}
                              className="form-control chat-input bg-light border-light"
                              id="chat-input"
                              placeholder="Type your message..."
                            />
                          </div>

                          <div className="col-auto">
                            <div className="chat-input-links ms-2">
                              <div className="links-list-item">
                                <Button
                                  type="button"
                                  color="success"
                                  onClick={handleSendMessage}
                                  className="chat-send waves-effect waves-light"
                                >
                                  <i className="ri-send-plane-2-fill align-bottom"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                          
                        </Row>

                      </form>
                    </div>
                    </CardBody>
                            </Card>
                            <Card>
                            <CardHeader>
                            <h5 className="card-title mb-0"> View Messages</h5>

                              </CardHeader>
                            <CardBody>
                                <Row className="g-4 mb-3">
                                            {/* <Col className="col-sm-auto"> */}
                                            <Col md="3">
                                                {/* <div>
                                                <select className="form-select" aria-label=".form-select-sm example" onChange={handleSelectChange}>

                                                    <option value="0">{t("Bulk action")}</option>
             
                                                    <option value="3">{t("Enable Operator")}</option>
                                                    <option value="4">{t("Disable Operator")}</option>

                                                </select>
                                                </div> */}
                                            </Col>
                                            {/* <Col className="col-sm-auto"> */}
                                            <Col md="3">
                                                {/* <div>
                                              <Button color="light" onClick={handleClick}> {t("Apply")} </Button>

                                                </div> */}
                                            </Col>
                                            {/* <Col className="col-md"> */}
                                            <Col md="6">
                                                <div className="d-flex justify-content-md-end">
                                                    <div className="search-box sm-12" style={{"width":"300px"}} >
                                                   
                                                        <input type="text" className="form-control search" placeholder={t("Search...")} value={filterValue} onChange={(e)=>{filterTable(e,initialRows,setRows)}} />
                                                        <i className="ri-search-line search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
            <DataTable
            // fixedHeader
            // fixedHeaderScrollHeight="300px"
            // fixedHeaderScrollHeight="100px"
            onSort={handleSortChange}
            striped
            progressPending={isLoading}
            dense
            highlightOnHover
            columns={columns}
            data={rows}
            selectableRows
            onSelectedRowsChange={handleRowSelected}
            pagination
            paginationRowsPerPageOptions={[10, 15, 20,100]}
paginationComponentOptions={rowsPerPageSample}
            clearSelectedRows={toggledClearRows}
            // style={{ borderCollapse: "collapse" }}
            // className="react-data-table"
            customStyles={customStyles}

        />
                                </CardBody>
                            </Card>
            </Col>
          </Row>
        </Container>
        {/* <Modal show={isLoadingModal} backdrop="static" keyboard={false}>
      <Modal.Body className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="primary" />
        <span className="ml-2">Loading...</span>
      </Modal.Body>
    </Modal> */}
    <Modal
                isOpen={isLoadingModal}
                toggle={() => {
                  setIsLoadingModal();
                }}
                backdrop={'static'}
                id="staticBackdrop"
                centered
                size='xs'
            >

                <ModalBody className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
        <span className="ml-2">Loading...</span>
                </ModalBody>
            </Modal>

            <Modal isOpen={isModalOpen} toggle={handleCancel}>
        <ModalHeader toggle={handleCancel}>Confirm</ModalHeader>
        <ModalBody>
          Are you sure you want {actionObj[selectedOption]} for the selected items?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirm}>
            Yes
          </Button>
          <Button color="secondary" onClick={handleCancel}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      </div>:""}

    </React.Fragment>
  );
};

export default Starter; 