import React, { useEffect,useState } from 'react';
import { useTranslation } from "react-i18next";import { Card,CardHeader, CardBody, Col, Container, Input, Label, Row, Table,Button,Modal, ModalBody, ModalHeader,ModalFooter, Spinner} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
// import { Modal, Spinner } from 'react-bootstrap';

import { Link } from 'react-router-dom';
//import { BasicTable, ScrollVertical, ScrollHorizontal, AlternativePagination, FixedHeaderDatatables, ModalDataDatatables, AjaxDatatables } from '../Tables/DataTables/datatableCom';
import { useQuery,useMutation, gql } from '@apollo/client';
// import checkTokenValidity from '../../Layouts/Footer'
import DataTable from 'react-data-table-component';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useCreateLog } from  "../../Components/Common/functions";  // Adjust the path as necessary


const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // Replace with your actual token
  const token = localStorage.getItem("token");

  // Add custom headers
  return {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem("token"),
    },
  };
});
// Initialize Apollo Client with custom headers
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // cache: 'no-cache'
  cache: new InMemoryCache(),
});
// password
// id
// is_enabled
// is_panic
// current_game
// gp_user_id
// credits
// bonus
// username
const DESCENDANTS_QUERY = gql`
query MyQuery($id: Int!) {
  operators_by_pk(id: $id) {
    descendants
  }
}

`;

// password
// id
// is_enabled
// is_panic
// current_game
// gp_user_id
// credits
// bonus
// username

const USER_QUERY = gql`
query MyQuery($_in: [Int!]) {
  users(
    order_by: {id: asc}
    where: {shop: {operator_id: {_in: $_in}}, is_deleted: {_eq: false}, is_online: {_eq: true}}
  ) {
    id
    is_enabled
    credits
    username
    name
    last_login
    shop {
      is_panic
    }
  }
}
`;
const ENABLE_USER_MUTATION = gql`
mutation MyMutation($is_enabled: Boolean, $_in: [Int!] ) {
  update_users(where: {id: {_in: $_in}}, _set: {is_enabled: $is_enabled}) {
    affected_rows
  }
}
`;

const ENABLE_USER_NOTIN = gql`
mutation MyMutation($is_enabled: Boolean = false, $_nin: [Int!] = 10) {
  update_users(where: {id: {_nin: $_nin}}, _set: {is_enabled: $is_enabled}) {
    affected_rows
  }
}
`;

// const ENABLE_PANIC_USER = gql`
// mutation MyMutation($is_panic: Boolean, $_in: [Int!]) {
//   update_users(where: {id: {_in: $_in}}, _set: {is_panic: $is_panic}) {
//     affected_rows
//   }
// }
// `;



let theNotSelected=[]
let theFilteredData=[]
let initialRows=[]
// const generateTestData = () => {
//   const testData = []
//   for (let i = 0; i <= 2000; i++) {
//     testData.push({
//       bonus: Math.floor(Math.random() * 100),
//       credits: Math.floor(Math.random() * 1000),
//       current_game: `Game ${i}`,
//       gp_user_id: `user${i}`,
//       id: i,
//       is_enabled: Math.random() < 0.5,
//       password: `password${i}`,
//       shop_id: Math.floor(Math.random() * 10),
//       username: `user${i}`,
//     })
//   }
//   return testData
// }

// const shops = [];

// for (let i = 0; i < 2000; i++) {
//   const shop = {
//     bonus_percentage: Math.floor(Math.random() * 100),
//     credits: Math.floor(Math.random() * 1000),
//     currency: Math.random() < 0.5 ? "USD" : "EUR",
//     gp_cur: Math.random() < 0.5 ? "BTC" : "ETH",
//     gp_maxbet: Math.floor(Math.random() * 10000),
//     gp_maxbet_roulette: Math.floor(Math.random() * 10000),
//     gp_maxbet_tablegames: Math.floor(Math.random() * 10000),
//     gp_minbet: Math.floor(Math.random() * 100),
//     gp_minbet_roulette: Math.floor(Math.random() * 100),
//     gp_minbet_tablegames: Math.floor(Math.random() * 100),
//     gp_rtplevel: Math.floor(Math.random() * 10),
//     gp_shop_id: Math.random().toString(36).substring(2, 10),
//     id: i,
//     is_enabled: Math.random() < 0.5,
//     is_panic: Math.random() < 0.5,
//     last_login: new Date().toISOString(),
//     name: Math.random().toString(36).substring(2, 10),
//     operator_id: Math.floor(Math.random() * 1000),
//     password: Math.random().toString(36).substring(2, 10),
//     new_user_limit: Math.floor(Math.random() * 1000),
//     username: Math.random().toString(36).substring(2, 10)
//   };
//   shops.push(shop);
// }

// console.log(shops);




// const transactions = [];

// for (let i = 0; i < 2000; i++) {
//   const transaction = {
//     amount: parseFloat((Math.random() * 1000).toFixed(2)),
//     created_at: new Date().toISOString(),
//     description: Math.random().toString(36).substring(2, 10),
//     from_id: Math.floor(Math.random() * 1000),
//     from_type: Math.floor(Math.random() * 10),
//     from_username: Math.random().toString(36).substring(2, 10),
//     id: i,
//     to_id: Math.floor(Math.random() * 1000),
//     to_type: Math.random() < 0.5 ? "user" : "shop",
//     to_username: Math.random().toString(36).substring(2, 10)
//   };
//   transactions.push(transaction);
// }

// console.log(transactions);




// const testData = generateTestData()
// function compareArrays(arrayA, arrayB) {
//   console.log("resultA",arrayA)
//   const idsB = arrayB.map((item) => item.id);
//   const result = arrayA.filter((item) => !idsB.includes(item.id));
//   console.log("result",result)
//   return result;
// }


const actionObj={
3:"Enable Banner",
4:"Disable Banner"}

const Starter = () => {
const { t } = useTranslation();
document.title ="Bonus";
const { createLog } = useCreateLog();

// const { dataUSERS,loadingUSERS,errorUSERS } = useQuery(USER_QUERY);
const [rows, setRows] = useState([])
const [isLoading, setIsLoading] = useState(true)
const [selectedOption, setSelectedOption] = useState('');
const [filterValue, setFilterValue] = useState('');
const [sortState, setSortState] = useState({});
const [isLoadingModal, setIsLoadingModal] = useState(false);
const [isModalOpen, setIsModalOpen] = useState(false);

const [dateRange, setDateRange] = useState({
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection'
});

const handleSelect = (date) => {
  setDateRange(date);
};

const handleConfirm = () => {
  // Perform the action you want to execute here
  handleButtonClick()
  setIsModalOpen(false); // Close the modal
};

const handleCancel = () => {
  setIsModalOpen(false); // Close the modal
};

const handleClick = () => {
  if(selectedOption!=0&&selectedRows.length>0){
    setIsModalOpen(true); // Open the modal
  }
};

function transformCreditsToCurrency(credits) {
  const currency = (credits / 100).toFixed(2);
  return currency;
}

const [enableUserMutation, { loading, error }] = useMutation(ENABLE_USER_MUTATION, {
  onCompleted: (data) => {
    console.log(data);
    const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]

    for(let i=0;i<idArraysel.length;i++){
      createLog(idArraysel[i],"user",selectedOption==3?"User Enabled":"User Disabled",true)
    }
    // Handle the result of the mutation here
  },
  onError: (error) => {
    const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]

    for(let i=0;i<idArraysel.length;i++){
      createLog(idArraysel[i],"user",selectedOption==3?"User Enabled":"User Disabled",false)
    }
    console.log(error);
    // Handle the error here
  }
});

// const [enablePanicUserMutation, { dataAccept1, loadingAccept1, errorAccept1 }] = useMutation(ENABLE_PANIC_USER, {
//   onCompleted: (data) => {
//     console.log(data);
//     const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]

//     for(let i=0;i<idArraysel.length;i++){
//       createLog(idArraysel[i],"user",selectedOption==1?"Panic Enabled":"Panic Disabled",true)
//     }
//     // Handle the result of the mutation here
//   },
//   onError: (error) => {
//     const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]

//     for(let i=0;i<idArraysel.length;i++){
//       createLog(idArraysel[i],"user",selectedOption==1?"Panic Enabled":"Panic Disabled",false)
//     }
//     console.log(error);
//     // Handle the error here
//   }
// });



function handleSelectChange(event) {
  const value = event.target.selectedOptions[0].value;
  setSelectedOption(value);
}


const redirectWithId = (id) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set('id', id);
  const params = urlSearchParams.toString();
  // console.log("paransss",params)
  window.location.href = `/#/userDetails?${params}`;
}

const filterTable = (event, originalData, setData) => {
  setFilterValue(event.target.value);
  const searchTerm = event.target.value.toLowerCase()
  // const filteredData = originalData.filter((row) => {
  //   return Object.keys(row).some((key) => {
  //     const cellValue = String(row[key]).toLowerCase()
  //     return cellValue.includes(searchTerm)
  //   })
  // })
  // console.log("filteredSData",originalData)
//   const filteredData = originalData.filter((row) => {
//     const username = String(row.username).toLowerCase();
//     return username.includes(searchTerm);
// });
const filteredData = originalData.filter((row) => {
  const username = String(row.name).toLowerCase();
  return username.includes(searchTerm);
});
  theFilteredData=filteredData
  setRows(filteredData)
}



const { refetch } = 
useQuery(
  DESCENDANTS_QUERY,

  {
    onError:(err) =>{
      console.log(err)
      // window.location.href = "/#/login"

    },
    variables:  {"id": localStorage.getItem("opID")},

    // variables: {domain_id: {"_eq": locStorageItem}},
    onCompleted: async(data) => {
      console.log("TA DATA:",data)
      if(data.operators_by_pk!=null){
        // console.log("thedescentants",data.operators_by_pk.descendants.push(localStorage.getItem("opID")))
        const theDescendants=data.operators_by_pk.descendants
        await theDescendants.push(localStorage.getItem("opID"))
        const usersResponse = await client.query({
          query: USER_QUERY,
          variables: { "_in": theDescendants },
          fetchPolicy: 'network-only', // or 'cache-and-network'
        });        console.log("operatorsResponse",usersResponse)
        if(usersResponse.data.users!=undefined){
          const bonuses = [
            { id: 1, name: "Birthday Bonus", type: "Special Occasion", amount: "100 Credits", criteria: "Active users on birthday" },
            { id: 2, name: "Deposit Bonus", type: "Deposit", amount: "50% of deposit", criteria: "Min. deposit of 100 Credits" },
            { id: 3, name: "Sign Up Bonus", type: "Sign Up", amount: "50 Credits", criteria: "First login" },
        ];
        
        
          const searchTerm = filterValue.toLowerCase()
          // const filteredData = (usersResponse.data.users).filter((row) => {
          //   return Object.keys(row).some((key) => {
          //     const cellValue = String(row[key]).toLowerCase()
          //     return cellValue.includes(searchTerm)
          //   })
          // })
        //   const filteredData = usersResponse.data.users.filter((row) => {
        //     const username = String(row.username).toLowerCase();
        //     return username.includes(searchTerm);
        // });
        const filteredData = bonuses.filter((row) => {
          const username = String(row.name).toLowerCase();
          return username.includes(searchTerm);
      });
        
          // console.log("filteredSData",originalData)
          theFilteredData=await sortData(filteredData, sortState);
          
  
          // setRows(data.users)
          // initialRows=data.users
          // theFilteredData=data.users
          await setRows([])

          await setRows(theFilteredData)
          // initialRows=usersResponse.data.users
          initialRows=bonuses
          theFilteredData=theFilteredData
        }

      }


      setIsLoading(false)
      setIsLoadingModal(false);

    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only', // or 'cache-and-network'

    // pollInterval:5000
  }
);

// const userId = '123'; // This is the ID you want to pass to the next page

//       {/* Link with parameters */}
//       <Link to={`/userDetails/${userId}`}>User Details</Link>

//       {/* Button with parameters */}
//       <button onClick={() => window.location.href = `/#/userDetails/${userId}`}>
//         User Details
//       </button>




//       import React from 'react';
// import { useParams } from 'react-router-dom';

// const UserDetails = () => {
//   const { userId } = useParams();

//   return (
//     <div>
//       <h1>User Details</h1>
//       <p>User ID: {userId}</p>
//       {/* Other user details */}
//     </div>
//   );
// };

// export default UserDetails;

useEffect(() => {
}, []);
const [toggledClearRows, setToggledClearRows] = useState(false);

const [selectedRows, setSelectedRows] = React.useState([]);
// let isAllSelected=false
const dataTableRef = React.createRef();

// const handleClearSelection = () => {
//   setSelectedRows([]); // Clear the selected rows state
//   dataTableRef.current.clearSelectedRows(); // Clear the selected rows in the DataTable component
// };
const handleClearSelection = () => {
  setSelectedRows([]);
  setToggledClearRows(!toggledClearRows);
};


	const handleRowSelected = React.useCallback(state => {
    //mporw apla panta na exw ta selected je ta not selected je na stellw jina pu en liotera xwris na kamnw ulla ta ifs
    // if(state.allSelected){
    //   isAllSelected=true
    //   theNotSelected=[]
    // }else{
    //   if(state.selectedCount==0){
    //     isAllSelected=false
    //   }else{
    //     theNotSelected=compareArrays(theFilteredData,state.selectedRows)
    //   }
    // }
    // setSelectedRows([]);

		setSelectedRows(state.selectedRows);
        console.log("eta rows",state.selectedRows)
        console.log("et state",state)
        console.log("eta not selected",theNotSelected)

	}, []);


  
  const handleEnablePanic = async () => {
    // setIsLoadingModal(true);
    //   const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]
    //   console.log("the in idArray",idArraysel)
    //   console.log("selection",selectedOption==1)

    //   await enablePanicUserMutation({
    //     variables: {
    //       is_panic: selectedOption==1, // Set to true to enable the user
    //       _in: idArraysel // Set to the ID of the user to enable
    //     }
    //   });
    // // }
    // await refetch({})

  };


  const handleEnableUser = async () => {
    setIsLoadingModal(true);

    // if(false&&isAllSelected && theNotSelected.length<selectedRows.length){
    //   const idArray = theNotSelected.map(obj => obj.id); // [1, 2, 3]
    //   console.log("the not in idArray",idArray)
    //   console.log("selection",selectedOption==3)

    //   // selectedOption===3
    //   await enableNOTINUserMutation({
    //     variables: {
    //       is_enabled: selectedOption==3, // Set to true to enable the user
    //       _nin: idArray // Set to the ID of the user to enable
    //     }
    //   });
    // }else{
      const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]
      console.log("the in idArray",idArraysel)
      console.log("selection",selectedOption==3)

      await enableUserMutation({
        variables: {
          is_enabled: selectedOption==3, // Set to true to enable the user
          _in: idArraysel // Set to the ID of the user to enable
        }
      });
    // }
    await refetch({})
  };

    const handleButtonClick = async () => {

    switch(selectedOption) {
      case '1':
        await handleEnablePanic()
        await handleClearSelection()
        console.log('Option 1 selected');
        break;
      case '2':
        await handleEnablePanic()
        await handleClearSelection()
        // perform action for option 2
        console.log('Option 2 selected');
        break;
      case '3':
        await handleEnableUser()
        await handleClearSelection()
        // perform action for option 3
        console.log('Option 3 selected');
        break;
      case '4':
        await handleEnableUser()
        await handleClearSelection()
        // perform action for option 4
        console.log('Option 4 selected');
        break;
      default:
        // do nothing
        break;
    }
    
  }
  const handleSortChange = (column, sortDirection) => {
    setSortState({ column, sortDirection });
  };
  // onClick={handleButtonClick}
  function sortData(data, sortState) {
    const { column, sortDirection } = sortState;
    if (!column || !sortDirection) return data;
  console.log("current column",column)
  console.log("current sortDirection",sortDirection)
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return sortDirection === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  
    return sortedData;
  }

  const customStyles = {
    // header: {
    //   style: {
    //     border: '1px solid #dee2e6'
    //   }
    // },
    cells: {
      style: {
        border: '0.5px solid #dee2e6',
      },
    },
  };

  function formatDate(dateString, timezone) {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: localStorage.getItem("opTimezone"),
    };
    return date.toLocaleString('en-GB', options).replace(',', '');
  }

  const columns = [
    { name: "Name", selector: row => row.name },
    { name: "Type", selector: row => row.type },
    { name: "Amount", selector: row => row.amount },
    { name: "Eligibility Criteria", selector: row => row.criteria },
    {
        name: "Settings",
        cell: (row) => (
            <Button color="warning" 
            // onClick={() => handleSettings(row.id)}
            >
                <i className="fas fa-cog"></i>
            </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
];






const rowsPerPageSample={ rowsPerPageText: t('Rows per page:'), rangeSeparatorText: t('of'), noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }

  return (
    <React.Fragment>      
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Bonus")} pageTitle={t("Home")} />
          <Row>
            <Col xs={12}>
            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">{t("Bonus")}</h5>
                                </CardHeader>
                                <CardBody>

                                <Row className="g-4 mb-3">

                                            {/* <Col className="col-sm-auto"> */}
                                            <Col md="3">
                                                <div>
                                                <select className="form-select" aria-label=".form-select-sm example" onChange={handleSelectChange}>
                                                <option value="0">{t("Bulk action")}</option>
                                                    {/* <option value="1">Enable Panic</option>
                                                    <option value="2">Disable Panic</option> */}
                                                    <option value="3">{t("Enable Bonus")}</option>
                                                    <option value="4">{t("Disable Bonus")}</option>

                                                    {/* <option value="3">Three</option> */}
                                                </select>
                                                </div>
                                            </Col>
                                            {/* <Col className="col-sm-auto"> */}
                                            <Col md="3">
                                                <div>
                                              <Button color="light" 
                                              // onClick={handleClick}
                                              > {t("Apply")} </Button>

                                                </div>
                                            </Col>
                                            {/* <Col className="col-md"> */}
                                            <Col md="6">
                                                <div className="d-flex justify-content-md-end">
                                                    <div className="search-box sm-12" style={{"width":"300px"}} >
                                                   
                                                        <input type="text" className="form-control search" placeholder={t("Search...")} value={filterValue} onChange={(e)=>{filterTable(e,initialRows,setRows)}} />
                                                        <i className="ri-search-line search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
            <DataTable

            onSort={handleSortChange}
            striped
            progressPending={isLoading}
            dense
            highlightOnHover
            columns={columns}
            data={rows}
            selectableRows
            onSelectedRowsChange={handleRowSelected}
            pagination
            paginationRowsPerPageOptions={[10, 15, 20,100]}
paginationComponentOptions={rowsPerPageSample}
            clearSelectedRows={toggledClearRows}
            // style={{ borderCollapse: "collapse" }}
            // className="react-data-table"
            customStyles={customStyles}
            // customHeader={<CustomHeader />}

            // npm i react-data-table-component-footer
        />
                                </CardBody>
                            </Card>
            </Col>
          </Row>
        </Container>
        {/* <Modal show={isLoadingModal} backdrop="static" keyboard={false}>
      <Modal.Body className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="primary" />
        <span className="ml-2">Loading...</span>
      </Modal.Body>
    </Modal> */}
    <Modal
                isOpen={isLoadingModal}
                toggle={() => {
                  setIsLoadingModal();
                }}
                backdrop={'static'}
                id="staticBackdrop"
                centered
                size='xs'
            >

                <ModalBody className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
        <span className="ml-2">Loading...</span>
                </ModalBody>
            </Modal>

            <Modal isOpen={isModalOpen} toggle={handleCancel}>
        <ModalHeader toggle={handleCancel}>Confirm</ModalHeader>
        <ModalBody>
          Are you sure you want {actionObj[selectedOption]} for the selected items?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirm}>
            Yes
          </Button>
          <Button color="secondary" onClick={handleCancel}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      </div>

    </React.Fragment>
  );
};

export default Starter; 