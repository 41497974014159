import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useQuery, gql } from '@apollo/client';
import { Card, CardBody, Col, Container, Row, Table, Button } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from 'react-data-table-component';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'; // Import rsuite CSS

// Query to fetch completed appointments
const TOP_CLIENTS_QUERY = gql`
  query TopClients($service_provider_id: Int!, $start_date: timestamptz!, $end_date: timestamptz!) {
     appointments(
      where: {
        status: { _eq: "COMPLETED" }
        appointment_to_staff_to_service_relationship: {
          staff_to_service_relationship: { service: { service_provider_id: { _eq: $service_provider_id } } }
        }
        start_time: { _gte: $start_date, _lte: $end_date }
      }
    ) {
      user {
        id
        first_name
        last_name
        email
        phone_number
        address_city
      }
    }
  }
`;

const TopClientsReport = () => {
  const serviceProviderId = parseInt(localStorage.getItem('opID'), 10);
  const [clientData, setClientData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]); // Default date range

  // Handler for date range change
  const handleDateChange = (value) => {
    setDateRange(value);
  };

  // Fetching top clients by completed appointments
  const { data, loading, error, refetch } = useQuery(TOP_CLIENTS_QUERY, {
    variables: {
      service_provider_id: serviceProviderId,
      start_date: dateRange[0].toISOString(),
      end_date: dateRange[1].toISOString(),
    },
    onCompleted: (data) => {
      if (data) {
        const clients = data.appointments.reduce((acc, appointment) => {
          const userId = appointment.user.id;
          if (acc[userId]) {
            acc[userId].appointmentsCount += 1;
          } else {
            acc[userId] = { ...appointment.user, appointmentsCount: 1 };
          }
          return acc;
        }, {});

        const sortedClients = Object.values(clients).sort((a, b) => b.appointmentsCount - a.appointmentsCount);
        setClientData(sortedClients.slice(0, 10));
        setIsLoading(false);
      }
    },
  });

  // Data for the pie chart
  const chartData = {
    labels: clientData.map((client) => `${client.first_name} ${client.last_name}`),
    datasets: [
      {
        data: clientData.map((client) => client.appointmentsCount),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#E7E9ED', '#A8B3C5'],
      },
    ],
  };

  // Table columns
  const columns = [
    {
      name: 'Name',
      selector: (row) => `${row.first_name} ${row.last_name}`,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
    },
    {
      name: 'Phone',
      selector: (row) => row.phone_number,
    },
    {
      name: 'City',
      selector: (row) => row.address_city || 'N/A',
    },
    {
      name: 'Completed Appointments',
      selector: (row) => row.appointmentsCount,
      sortable: true,
    },
  ];

  const predefinedRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
    },
    {
      label: 'Yesterday',
      value: [new Date(new Date().setDate(new Date().getDate() - 1)), new Date(new Date().setDate(new Date().getDate() - 1))],
    },
    {
      label: 'Last 7 Days',
      value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
    },
    {
      label: 'Last 30 Days',
      value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
    },
    {
      label: 'All Time',
      value: [new Date('2020-01-01'), new Date()],
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Top Clients" pageTitle="Home" />
          <Row>
            <Col md={12}>
              <h5>Select Date Range</h5>
              <DateRangePicker
                ranges={predefinedRanges}
                format="yyyy-MM-dd HH:mm:ss"
                value={dateRange}
                placeholder="Choose Range"
                style={{ width: 600 }}
                onChange={handleDateChange}
              />
              {/* <Button color="primary" onClick={() => refetch()}>Apply Date Filter</Button> */}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={6}>
              <Card>
                <CardBody>
                  <h5>Top 10 Clients by Completed Appointments</h5>
                  <Pie data={chartData} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={12}>
              <Card>
                <CardBody>
                  <DataTable
                    title="Top 10 Clients"
                    columns={columns}
                    data={clientData}
                    progressPending={isLoading || loading}
                    pagination
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TopClientsReport;
