import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import avatar1 from "../../assets/images/users/user-dummy-img.jpg";
import { useQuery, useMutation, gql } from "@apollo/client";

const USER_QUERY = gql`

query MyQuery($id: Int!) {
    operators_by_pk(id: $id) {
      credits
      new_user_limit
    }
  }
  `;

const ProfileDropdown = () => {
    // const [credit, setCredit] = useState(0);
    const [accountLimit, setAccountLimit] = useState(0);
    const [credits, setCredits] = useState(0);
    const { user } = useSelector(state => ({
        // user: state.Profile.user,
        // credit: state.shopDetails.credits
        user: "AHMED",

    }));


      // const { refetch } = useQuery(USER_QUERY, {
      //   onError: (err) => {
      //     console.log(err);
      //     // window.location.href = "/#/login"
      //   },
      //   variables: { id: localStorage.getItem("opID") },
      //   onCompleted: (data) => {
      //   //   console.log("TA DATA:", data);
      //     setCredits(data.operators_by_pk.credits/100);
      //     setAccountLimit(data.operators_by_pk.new_user_limit)
      //   //   console.log("user:", user);

      //   },
      //   notifyOnNetworkStatusChange: true,
      //   // pollInterval:5000
      // });
    
    
    const logout = () => {
        const token = localStorage.getItem("token")
      
        fetch(process.env.REACT_APP_BACKEND_URL+"/api/frontend/logout/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ "token":localStorage.getItem("token") })
        })
        // .then(response => console.log("responseee",response))
        //   .then(response => response.json())
          .then(data => {
            if (data && data.ok) {
                // localStorage.clear();
              localStorage.removeItem("token")
              localStorage.removeItem("isOP")

              window.location.href = "/#/login"
            } else {
              console.error("Error logging out:", data && data.message)
            }
          })
          .catch(error => {
            console.error("Error logging out:", error)
          })
      }

      const username1=localStorage.getItem("username")
    //   const username1=localStorage.getItem("username")

    const [userName, setUserName] = useState(username1);


    useEffect(() => {
        if (sessionStorage.getItem("authUser")) {
            const obj = JSON.parse(sessionStorage.getItem("authUser"));
            setUserName(process.env.REACT_APP_DEFAULTAUTH === "fake" ? obj.username === undefined ? user.first_name ? user.first_name : obj.data.first_name : "Admin" || "Admin" :
                process.env.REACT_APP_DEFAULTAUTH === "firebase" ? obj.providerData[0].email : "Admin"
            );
        }
    }, [userName, user]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={avatar1}
                            alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{username1}</span>
                            {/* <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span> */}
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Welcome {userName}!</h6>
                    {/* <DropdownItem disabled href={process.env.PUBLIC_URL + "/pages-profile"}><i
                        className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Credits : <b>{credits}</b></span></DropdownItem>
                        <DropdownItem disabled href={process.env.PUBLIC_URL + "/pages-profile"}><i
                        className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Account Limt : <b>{accountLimit}</b></span></DropdownItem> */}
                                                    <div className="dropdown-divider"></div>

                    <DropdownItem  href={"/#/profile"}><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>

                        <span className="align-middle">Profile</span></DropdownItem>

                    {/* <DropdownItem href={process.env.PUBLIC_URL + "/apps-chat"}><i
                        className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Messages</span></DropdownItem>
                    <DropdownItem href="#"><i
                        className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Taskboard</span></DropdownItem> */}
                    <DropdownItem href={"/#/FAQ"}><i
                        className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Help</span></DropdownItem>
{/* 
                    <DropdownItem href={process.env.PUBLIC_URL + "/pages-profile-settings"}><span
                        className="badge bg-soft-success text-success mt-1 float-end">New</span><i
                            className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                                className="align-middle">Settings</span></DropdownItem>
                    <DropdownItem href={process.env.PUBLIC_URL + "/auth-lockscreen-basic"}><i
                        className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span></DropdownItem> */}
                     <div className="dropdown-divider"></div>
                    <DropdownItem onClick={logout}><i
                        className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle" data-key="t-logout" >Logout</span></DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;