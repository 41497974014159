import React from 'react';
import { useSpring, animated } from 'react-spring';

function RotatingGif({ gifUrl }) {
  const animatedProps = useSpring({
    to: async (next) => {
      while (true) {
        await next({ transform: 'rotate(360deg)' }); // Rotate to 360 degrees
      }
    },
    from: { transform: 'rotate(0deg)' }, // Initial rotation
    config: { duration: 3000, loop: true }, // Duration and loop settings
  });

  return <animated.img src={gifUrl} alt="Rotating GIF" style={animatedProps} />;
}

export default RotatingGif;
