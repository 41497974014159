import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Card, CardHeader, CardBody, Col, Container, Row,
  Button, Spinner, Input
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { useQuery, gql, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      authorization: token,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const USERS_QUERY = gql`
query MyQuery {
  users(order_by: {id: asc}) {
    id
    username
    first_name
    last_name
    phone_number
    email
    address_city
  }
}
`;

const Starter = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const { data } = await client.query({
        query: USERS_QUERY,
        fetchPolicy: 'network-only' // Use network-only to always fetch latest data
      });
      if (data && data.users) {
        setUsers(data.users);
        setFilteredData(data.users); // Initially no filter
      }
    } catch (error) {
      console.error("Error fetching users:", error.message);
    }
    setIsLoading(false);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = users.filter(user => 
      user.username.toLowerCase().includes(value) ||
      `${user.first_name} ${user.last_name}`.toLowerCase().includes(value) ||
      user.phone_number.toLowerCase().includes(value) ||
      user.email.toLowerCase().includes(value) ||
      user.address_city.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };
  

  const columns = [
    {
      name: t("Username"),
      sortable: true,
      selector: row => row.username,
      cell: row => (
        <Link to={`/userDetails/${row.id}`} onClick={(e) => {
          e.preventDefault();
          window.location.href = `/#/userDetails/${row.id}`;
        }}>
          {row.username}
        </Link>
      )
    },
    {
      name: t("Name"),
      selector: row => `${row.first_name} ${row.last_name}`,
      sortable: true,
    },
    {
      name: t("Phone"),
      selector: row => row.phone_number,
      sortable: true,
    },
    {
      name: t("Email"),
      selector: row => row.email,
      sortable: true,
    },
    {
      name: t("City"),
      selector: row => row.address_city,
      sortable: true,
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Users")} pageTitle={t("Home")} />
          <Card>
            <CardBody>
              <Row className="g-4 mb-3">
                <Col md="6">
                  <div className="d-flex justify-content-md-end">
                    <div className="search-box sm-12" style={{ "width": "300px" }}>
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={t("Search...")}
                        value={searchTerm}
                        onChange={handleSearch}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </Col>
              </Row>
              <DataTable
                columns={columns}
                data={filteredData}
                progressPending={isLoading}
                pagination
                highlightOnHover
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Starter;
