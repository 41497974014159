import React, { useEffect,useState } from 'react';
import { useTranslation } from "react-i18next";import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Input, Label, Row,Button } from 'reactstrap';
import ParticlesAuth from "./ParticlesAuth";
import {getAllDescendants,getProviderID} from "../../Components/Common/functions"
import { useQuery, useMutation, gql } from "@apollo/client";
import moment from "moment";
//import images
// import logoLight from "../../../assets/images/logo-light.png";
// console.log("the data",JSON.stringify(data))

const LASTLOGIN_MUTATION = gql`
  mutation setLastLogin($username: String = "", $last_login: timestamptz = "") {
    update_operators(
      where: { username: { _eq: $username } }
      _set: { last_login: $last_login }
    ) {
      affected_rows
    }
  }
`;

async function loginUser(username, password) {


    const url = process.env.REACT_APP_BACKEND_URL+"/api/frontend/authenticate/";
    const data = { "username":String(username), "password":String(password), "client_type":"service_provider" };
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(data),
        // body: data
      });
      console.log("response0",response)

      if (!response.ok) {
        console.log("response1",response)

        throw new Error("Failed to log in.");
      }

      const token = await response.json();
      console.log("response",token.token)

      return token.token;
    } catch (error) {
      console.error(error);
      alert(error)
    }
  }




const BasicSignIn = () => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [set_Last_Login_Game] = useMutation(LASTLOGIN_MUTATION,
        {
            onError:(err) =>{
              console.log(err)
              // window.location.href = "/#/login"
        
            },
        }
        );
document.title="Log In";
function login() {
  //  localStorage.setItem("isOP",0)

    const username = document.getElementById("username").value.toLowerCase();
    const password = document.getElementById("password-input").value;
    loginUser(username, password)
      .then(async(token) => {
        if(token!=undefined){
        console.log("Logged in successfully:", token);
        await localStorage.setItem("token", token)

        // Add code here to redirect the user to the appropriate page

        const theDetails=await getProviderID(username)
        console.log("theDetails",theDetails)
        await localStorage.setItem("username", username)
        await localStorage.setItem("opID",await parseInt(theDetails["id"]))

        const right_now = moment().format("YYYY-MM-DDTHH:mm:ssZ");
        // await set_Last_Login_Game({
        //     variables: {
        //       username: username,
        //       last_login: right_now,
        //     },
        //   });

          window.location.href =  "/#/home"
        }else{
            // alert("Wrong Credentials")
        }

      })
      .catch((error) => {
        alert("Failed to log in:", error);
        console.log("Failed to log in:", error);

      });
  }
// const [username, setUsername] = useState("")
// const [password, setPassword] = useState("")
const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        login();
    }
  };
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };


    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content" >                
                    <Container >
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            {/* <img src={logoLight} alt="" height="20" /> */}
                                        </Link>
                                    </div>
                                    {/* <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> */}
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={7} lg={5} xl={4}>
                               
                           
                                        <div className="text-center mt-2">
                                            <h5 className="text" style={{"color":"#58666e","fontWeight":"bold"}}>PlanMate Admin</h5>
                                        </div>
                                        <div style={{marginTop:"20px"}} >
                                        
                                        </div>
                                        <div className="text-center mt-2" >
                                        <p className="text"  style={{"color":"#58666e",fontWeight:"bold"}}>Sign in</p>
                                        </div>

                                        <div className="p-2 mt-4">
                                            <form action="#">

                                                <div className="mb-3">
                                                    {/* <Label htmlFor="username" className="form-label">Username</Label> */}
                                                    <Input type="text" className="form-control" id="username" placeholder="Username"          onKeyPress={handleKeyPress}
/>                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input           type={isPasswordVisible ? 'text' : 'password'}
 className="form-control pe-5 password-input" placeholder="Password" id="password-input"        onKeyPress={handleKeyPress}
 />
        <button
          className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
          type="button"
          id="password-addon"
          onClick={togglePasswordVisibility}
        >
          <i className={isPasswordVisible ? 'ri-eye-off-fill' : 'ri-eye-fill'} align-middle />
        </button>                                                    </div>
                                                </div>

                                               

                                                <div >
                                                    <Button style={{"backgroundColor":"#080721", width:"100%"}}  onClick={login}><h5>Log In</h5></Button>
                                                </div>
                                                {/* <Link
  to="/loginop"
  style={{ backgroundColor: "#FFFFFF", color: "#D3D3D3", width: "100%" }}
>
  Go to admin login
</Link> */}
                                                {/* className="btn btn w-100"  */}
                                                {/* className="mt-4" */}
                                                {/* <div className="mt-4 text-center">
                                                    <div className="signin-other-title">
                                                        <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                                    </div>
                                                    <div>
                                                        <Button color="primary" className="btn-icon"><i className="ri-facebook-fill fs-16"></i></Button>{" "}
                                                        <Button color="danger" className="btn-icon"><i className="ri-google-fill fs-16"></i></Button>{" "}
                                                        <Button color="dark" className="btn-icon"><i className="ri-github-fill fs-16"></i></Button>{" "}
                                                        <Button color="info" className="btn-icon"><i className="ri-twitter-fill fs-16"></i></Button>
                                                    </div>
                                                </div> */}
                                            </form>
                                        </div>
                                    
{/* 
                                <div className="mt-4 text-center">
                                    <p className="mb-0">Don't have an account ? <Link to="/auth-signup-basic" className="fw-semibold text-primary text-decoration-underline"> Signup </Link> </p>
                                </div> */}

                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default BasicSignIn;