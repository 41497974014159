import React from 'react';
import RotatingGif from './RotatingGif';

function App() {
  const gifUrl = 'https://media.tenor.com/Q65Z2bfeRbMAAAAC/testing-simpsons.gif'; // Replace with the URL of your GIF image

  return (
    <div>
      <RotatingGif gifUrl={gifUrl} />
    </div>
  );
}

export default App;

    
    // export default Starter;