import React, { useEffect, useState } from "react";import { useTranslation } from "react-i18next";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Button,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";

// import { FormGrid, Gutters, VerticalForm, HorizontalForm, HorizontalFormLabelSizing, ColumnSizing, AutoSizing, InlineForms, FloatingLabels } from './FormlayoutsCode';
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import { useQuery, useMutation, gql } from "@apollo/client";
import {getDescendantsUsernames,getAllDescendants} from "../../Components/Common/functions"
import Select from 'react-select';
// import {getAllDescendants,getOperatorID} from "../../Components/Common/functions"
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useCreateLog } from "../../Components/Common/functions";
import go2spin_screenshot from '../../assets/images/Screenshot_Lobby_go2spin.png'
import dog_screenshot from '../../assets/images/Screenshot_Lobby_dog.png'
import euroslot_screenshot from '../../assets/images/euroslotlobby.png'
import online_screenshot from '../../assets/images/onlineLobby.png'
import palmTreasures_screenshot from '../../assets/images/palmTreasures.png'
import islandTreasures_screenshot from '../../assets/images/islandTreasures.png'
import spin_city_screenshot from '../../assets/images/spin_city_screenshot.png'
import stargames_screenshot from '../../assets/images/stargames.png'

import aviatorA_screenshot from '../../assets/images/aviator-spribe-game.png'
import aviatorB_screenshot from '../../assets/images/zeppelinScreenshot.png'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // Replace with your actual token
  const token = localStorage.getItem("token");

  // Add custom headers
  return {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem("token"),
    },
  };
});
// Initialize Apollo Client with custom headers
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // cache: 'no-cache'
  cache: new InMemoryCache(),
});


const DESCENDANTS_QUERY = gql`
query MyQuery($id: Int!) {
  operators_by_pk(id: $id) {
    descendants
    permissions
  }
}

`;

const EDIT_PERMISSIONS_MUTATION = gql`
mutation MyMutation($_eq: Int , $permissions: jsonb ) {
  update_operators(where: {id: {_eq: $_eq}}, _set: {permissions: $permissions}) {
    affected_rows
  }
}
`;


const testPermdata = [
  { key: 'Allow Create Operators', value: false ,editChildren:false },
  { key: 'Jackpot', value: "0" ,editChildren:false},
  { key: 'Edit Bonus', value: false ,editChildren:false},
  { key: 'Bonus X-X', value: false ,editChildren:false},
  { key: 'Bonus 30%', value: false ,editChildren:false},
  { key: 'Bank RTP', value: false,editChildren:false },
  { key: 'Reset Bank', value: false ,editChildren:false},
  // { key: 'Allow Go To Spin', value: false ,editChildren:false},
  // { key: 'Allow Casino Land', value: false ,editChildren:false},
];
// let operatorsDropList
let operatorId = -1


const NewOperator = () => {
  const { createLog } = useCreateLog();

  const [currentID, setcurrentID] = useState(-1);
  const [activeTab, setactiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  const { t } = useTranslation();
document.title = "New Operator";
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [operatorsDropList, setOperatorsDropList] = useState([]);
  const [currentOp, setCurrentOp] = useState(0);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [mutationSuccess, setMutationSuccess] = useState(false);

  const [GoToSpinEnabled, setGoToSpinEnabled] = useState(false);
  const [CasinoLandEnabled, setCasinoLandEnabled] = useState(false);
  const [EuroslotEnabled, setEuroslotEnabled] = useState(false);
  const [GoToHRSpinEnabled, setGoToHRSpinEnabled] = useState(false);
  const [onlineEnabled, setOnlineEnabled] = useState(false);
  const [palmEnabled, setPalmEnabled] = useState(false);
  const [palmNewEnabled, setPalmNewEnabled] = useState(false);

  const [islandEnabled, setIslandEnabled] = useState(false);
  const [stargamesEnabled, setStargamesEnabled] = useState(false);

  

    const [AviatorAEnabled, setAviatorAEnabled] = useState(false);
  const [AviatorBEnabled, setAviatorBEnabled] = useState(false);
  const [AviatorCEnabled, setAviatorCEnabled] = useState(false);
  const [GoToSpinInput, setGoToSpinInput] = useState(true);
  const [CasinoLandInput, setCasinoLandInput] = useState(true);
  const [EuroslotInput, setEuroslotInput] = useState(true);
  const [GoToHRSpinInput, setGoToHRSpinInput] = useState(true);
  const [onlineInput, setOnlineInput] = useState(true);
  const [palmInput, setPalmInput] = useState(true);
  const [palmNewInput, setPalmNewInput] = useState(true);

  const [islandInput, setIslandInput] = useState(true);
  const [stargamesInput, setStargamesInput] = useState(true);

  
  const [AviatorAInput, setAviatorAInput] = useState(true);
  const [AviatorBInput, setAviatorBInput] = useState(true);
  const [AviatorCInput, setAviatorCInput] = useState(false);

  

  const [editPermissionsMutation, ] =
  useMutation(EDIT_PERMISSIONS_MUTATION,
    {
      onCompleted: async (data) => {
        await createLog(parseInt(operatorId),"operator","Operator Permissions Edited",true)
        .then(  ()=>{window.location.reload()}              );

        console.log("success",data);
        // Handle the result of the mutation here
      },
      onError: (error) => {
        createLog(parseInt(operatorId),"operator","Operator Permissions Edited",false)

        console.log("erros",error);
        // Handle the error here
      },
    });

  const handleChange = (selectedOption) => {
    setCurrentOp(selectedOption.value);
  };
  useEffect(() => {
    
    async function fetchData() {
          const operatorsResponse = await client.query({
          query: DESCENDANTS_QUERY,
          variables:  {"id": localStorage.getItem("opID")},
          fetchPolicy: 'network-only', // or 'cache-and-network'
        });        console.log("operatorsResponse",operatorsResponse)
      await setCurrentOp(parseInt(localStorage.getItem("opID")))
      const options=await getDescendantsUsernames(operatorsResponse.data.operators_by_pk.descendants)


      const the_permissions = JSON.parse(operatorsResponse.data.operators_by_pk.permissions)
      const arrayOfObjects = Object.values(the_permissions);


      options.unshift({id:parseInt(localStorage.getItem("opID")),username:localStorage.getItem("username")})
      await setOperatorsDropList(options.map((operator) => ({
        value: operator.id,
        label: operator.username,
      })))

      const go_to_spin_allow = arrayOfObjects.find(
        (obj) =>
          obj["key"] ===
          "Allow Go To Spin"
      ).editChildren;
      const casino_land_allow = arrayOfObjects.find(
        (obj) =>
          obj["key"] ===
          "Allow Casino Land"
      ).editChildren;
      const euroslot_allow = arrayOfObjects.find(
        (obj) =>
          obj["key"] ===
          "Allow Euroslot"
      ).editChildren;
      const go_hr_to_spin_allow = arrayOfObjects.find(
        (obj) =>
          obj["key"] ===
          "Allow HR Go To Spin"
      ).value;
      const online_allow = arrayOfObjects.find(
        (obj) =>
          obj["key"] ===
          "Allow Online"
      ).value;
      const palm_allow = arrayOfObjects.find(
        (obj) =>
          obj["key"] ===
          "Allow Palm Treasures"
      ).value;
      const palmNew_allow = arrayOfObjects.find(
        (obj) =>
          obj["key"] ===
          "Allow Palm Treasures New"
      ).value;
      const island_allow = arrayOfObjects.find(
        (obj) =>
          obj["key"] ===
          "Allow Island Treasures"
      ).value;
      const stargames_allow = arrayOfObjects.find(
        (obj) =>
          obj["key"] ===
          "Allow Stargames"
      ).value;
      const aviator_a = arrayOfObjects.find(
        (obj) =>
          obj["key"] ===
          "Allow Aviator A"
      ).value;
      const aviator_b = arrayOfObjects.find(
        (obj) =>
          obj["key"] ===
          "Allow Aviator B"
      ).value;
      const aviator_c = arrayOfObjects.find(
        (obj) =>
          obj["key"] ===
          "Allow Aviator C"
      ).value;

      setGoToSpinEnabled(go_to_spin_allow)
      setCasinoLandEnabled(casino_land_allow)
      setEuroslotEnabled(euroslot_allow)
      setGoToHRSpinEnabled(go_hr_to_spin_allow)
      setOnlineEnabled(online_allow)
      setPalmEnabled(palm_allow)
      setPalmNewEnabled(palmNew_allow)
      setIslandEnabled(island_allow)
      setStargamesEnabled(stargames_allow)

      
      setAviatorAEnabled(aviator_a)
      setAviatorBEnabled(aviator_b)
      setAviatorCEnabled(aviator_c)

    }
    // let theDescendants=localStorage.getItem("descendants")
    // let theUsername=localStorage.getItem("username")
  
    // console.log("descendants",theDescendants)
    // console.log("theUsername",theUsername)
    // 
    fetchData();
    console.log("operatorsDropList",operatorsDropList)
    if (mutationSuccess) {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
      setMutationSuccess(false)
    }
  }, [mutationSuccess]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token")
    const data = { "token":token, 
    "parent_operator_id":parseInt(currentOp),
      "username":username.toLowerCase(),
       "password":password,
       "new_user_limit":0,
      "is_enabled":false,
    "allow_create_child_operator":false,
  "name":""};
  console.log("arguments",data)
  // return
    fetch(process.env.REACT_APP_BACKEND_URL+"/api/frontend/create-operator/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => {
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        return response.json();
      })
      .then(async (data) => {
        console.log(data);
        if(data.detail!==undefined&&data.name.includes("error")){
          throw new Error((data.detail));
 
        }
        if(data.includes!==undefined&&data.includes("Error")){

          console.log(data.detail!==undefined);

          throw new Error((data));
        }
        // localStorage.setItem("descendants",getAllDescendants(localStorage.getItem("username")))
        createLog(parseInt(localStorage.getItem("opID")),"operator",username+" Operator created",true)



        operatorId = data.id
        const GoToSpinText = { key: 'Allow Go To Spin', value: GoToSpinEnabled && GoToSpinInput ,editChildren: GoToSpinEnabled && GoToSpinInput}
        const CasinoLandText = { key: 'Allow Casino Land', value: CasinoLandEnabled && CasinoLandInput ,editChildren: CasinoLandEnabled && CasinoLandInput}
        const EuroslotText = { key: 'Allow Euroslot', value: EuroslotEnabled && EuroslotInput ,editChildren: EuroslotEnabled && EuroslotInput}
        const HRText = { key: 'Allow HR Go To Spin', value: GoToHRSpinEnabled && GoToHRSpinInput ,editChildren:  GoToHRSpinEnabled && GoToHRSpinInput}
        const OnlineText = { key: 'Allow Online', value: onlineEnabled && onlineInput ,editChildren:   onlineEnabled && onlineInput}
        const PalmText = { key: 'Allow Palm Treasures', value: palmEnabled && palmInput ,editChildren: palmEnabled && palmInput}
        const PalmNewText = { key: 'Allow Palm Treasures New', value: palmNewEnabled && palmNewInput ,editChildren: palmNewEnabled && palmNewInput}

        const IslandText = { key: 'Allow Island Treasures', value: islandEnabled && islandInput ,editChildren: islandEnabled && islandInput}
        const StargamesText = { key: 'Allow Stargames', value: stargamesEnabled && stargamesInput ,editChildren: stargamesEnabled && stargamesInput}

        const AviatorAText = { key: 'Allow Aviator A', value: AviatorAEnabled && AviatorAInput ,editChildren: AviatorAEnabled && AviatorAInput}
        const AviatorBText = { key: 'Allow Aviator B', value: AviatorBEnabled && AviatorBInput ,editChildren: AviatorBEnabled && AviatorBInput}
        const AviatorCText = { key: 'Allow Aviator C', value: AviatorCEnabled && AviatorCInput ,editChildren: AviatorCEnabled && AviatorCInput}
        const JackpotCreatePerm = { key: 'Enable Disable Jackpots', value: false ,editChildren: false}

        // testPermdata.slice(0)
        let permissionsCopy =[...testPermdata]
        // .then((permissionsCopy) => {
        permissionsCopy.push(GoToSpinText)
        permissionsCopy.push(CasinoLandText)
        permissionsCopy.push(EuroslotText)
        permissionsCopy.push(HRText)
        permissionsCopy.push(OnlineText)
        permissionsCopy.push(PalmText)
        permissionsCopy.push(PalmNewText)

        permissionsCopy.push(IslandText)
        permissionsCopy.push(StargamesText)

        permissionsCopy.push(AviatorAText)
        permissionsCopy.push(AviatorBText)
        permissionsCopy.push(AviatorCText)
        permissionsCopy.push(JackpotCreatePerm)




        const jsonString = await JSON.stringify(await Object.assign({}, permissionsCopy))
        editPermissionsMutation({
          variables: {
            _eq:  parseInt(data.id),
            permissions: jsonString,
          },
        });
        // })
        //console.log("testPermidata",permissionsCopy)
    
        // const jsonString = 

        // await 


        setMutationSuccess(true);
        setPassword("")
        setUsername("")
        window.location.href ="/#/operatorDetails/" + data.id;
        // do something with the response data, e.g. show a success message
      })
      .catch((error) => {
        createLog(parseInt(localStorage.getItem("opID")),"operator",username+" Operator created",false)

        alert(error);
        // show an error message to the user

      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("New Operator")} pageTitle={t("Home")} />

          {showSuccessMessage && (
        <div className="alert alert-success text-center" role="alert">
                   {t("Success!")}

        </div>
      )}
          {/* <Row>
            <Col xs={12}> */}
          <Card>
            <CardHeader>
              <h5 className="card-title mb-0">{t("New Operator")}</h5>

            </CardHeader>
            <CardBody>
              {/* className="mb-3" */}
              <Row>
                <Col md={5} xl={3}>
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                  >
                    {t("Username")}
                  </Label>
                </Col>
                <Col md={6} lg={4}>
                  <div className="form-icon">
                    <Input
                      type="email"
                      className="form-control form-control-icon"
                      id="nameInput"
                      placeholder={t("Username")}
                      value={username}
                      onChange={(event) => setUsername(event.target.value)}
                    />
                    <i className=" ri-user-fill"></i>
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop: "20px" }}>
                <Col md={5} xl={3}>
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                  >
                    {t("Password")}
                  </Label>
                </Col>
                <Col md={6} lg={4}>
                  <div className="form-icon">
                    <Input
                      type="email"
                      className="form-control form-control-icon"
                      id="nameInput"
                      placeholder={t("Password")}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <i className="ri-lock-password-fill"></i>
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop: "20px" }}>
                <Col md={5} xl={3}>
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                  >
                    {t("Assign To Operator")}
                  </Label>
                </Col>
                <Col md={6} lg={4}>
                <Select
                    style={{ backgroundColor: "lightBlue" }} // Set the background color inline
                    // className="form-select mb-4"
                    // aria-label="Default select example"
                    onChange={handleChange}
                    defaultValue={({value:localStorage.getItem("opID"),label:localStorage.getItem("username")})}
                    options={operatorsDropList}
                    value={operatorsDropList.find((option) => option.value === currentOp)}
                    isSearchable
                    styles={{ control: (styles) => ({ ...styles, backgroundColor: 'lightBlue' }) }} // Set the background color

                  >
{/* {operatorsDropList.map((operator) => (
    <option key={operator.id} value={operator.id}>{operator.username}</option>
  ))} */}
                  </Select>
                </Col>
              </Row>

              {GoToSpinEnabled &&
              <Row style={{ paddingTop: "20px", alignItems: "center" }}>
                <Col md={5} xl={3}>
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                    style={{width: "100%"}}
                  >
                    {"GO TO SPIN ALLOW"}
                  </Label>
                </Col>
                <Col md={2}>
              
                <Input
                  type="checkbox"
                  // id={`checkbox-${index}`}
                  checked={GoToSpinInput}
                  onChange={e => setGoToSpinInput(e.target.checked)}
                  inline
                />
                </Col>
                <Col md={5} lg={3}>
                <img src={go2spin_screenshot} className="figure-img img-fluid rounded" alt="..." />
                </Col>
              </Row>}
              {CasinoLandEnabled &&
              <Row style={{ paddingTop: "20px", alignItems: "center" }}>
                <Col md={5} xl={3}>
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                    style={{width: "100%"}}
                  >
                    {"CASINO LAND ALLOW"}
                  </Label>
                </Col>
                <Col md={2}>
              
                <Input
                  type="checkbox"
                  
                  checked={CasinoLandInput}
                  onChange={e => setCasinoLandInput(e.target.checked)}
                  inline
                />
                </Col>
                <Col md={5} lg={3}>
                <img src={dog_screenshot} className="figure-img img-fluid rounded" alt="..." />
                </Col>
              </Row>}
              {EuroslotEnabled &&
              <Row style={{ paddingTop: "20px", alignItems: "center" }}>
                <Col md={5} xl={3}>
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                    style={{width: "100%"}}
                  >
                    {"EUROSLOT ALLOW"}
                  </Label>
                </Col>
                <Col md={2}>
              
                <Input
                  type="checkbox"
                  
                  checked={EuroslotInput}
                  onChange={e => setEuroslotInput(e.target.checked)}
                  inline
                />
                </Col>
                <Col md={5} lg={3}>
                <img src={euroslot_screenshot} className="figure-img img-fluid rounded" alt="..." />
                </Col>
              </Row>}
              {GoToHRSpinEnabled &&
              <Row style={{ paddingTop: "20px", alignItems: "center" }}>
                <Col md={5} xl={3}>
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                    style={{width: "100%"}}
                  >
                    {"SPIN CITY ALLOW"}
                  </Label>
                </Col>
                <Col md={2}>
              
                <Input
                  type="checkbox"
                  
                  checked={GoToHRSpinInput}
                  onChange={e => setGoToHRSpinInput(e.target.checked)}
                  inline
                />
                </Col>
                <Col md={5} lg={3}>
                <img src={spin_city_screenshot} className="figure-img img-fluid rounded" alt="..." />
                </Col>
              </Row>}
              {onlineEnabled &&
              <Row style={{ paddingTop: "20px", alignItems: "center" }}>
                <Col md={5} xl={3}>
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                    style={{width: "100%"}}
                  >
                    {"ONLINE ALLOW"}
                  </Label>
                </Col>
                <Col md={2}>
              
                <Input
                  type="checkbox"
                  
                  checked={onlineInput}
                  onChange={e => setOnlineInput(e.target.checked)}
                  inline
                />
                </Col>
                <Col md={5} lg={3}>
                <img src={online_screenshot} className="figure-img img-fluid rounded" alt="..." />
                </Col>
              </Row>}
              {palmEnabled &&
              <Row style={{ paddingTop: "20px", alignItems: "center" }}>
                <Col md={5} xl={3}>
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                    style={{width: "100%"}}
                  >
                    {"PALM TREASURE ALLOW"}
                  </Label>
                </Col>
                <Col md={2}>
              
                <Input
                  type="checkbox"
                  
                  checked={palmInput}
                  onChange={e => setPalmInput(e.target.checked)}
                  inline
                />
                </Col>
                <Col md={5} lg={3}>
                <img src={palmTreasures_screenshot} className="figure-img img-fluid rounded" alt="..." />
                </Col>
              </Row>}
              {palmNewEnabled &&
              <Row style={{ paddingTop: "20px", alignItems: "center" }}>
                <Col md={5} xl={3}>
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                    style={{width: "100%"}}
                  >
                    {"PALM TREASURE NEW ALLOW"}
                  </Label>
                </Col>
                <Col md={2}>
              
                <Input
                  type="checkbox"
                  
                  checked={palmNewInput}
                  onChange={e => setPalmNewInput(e.target.checked)}
                  inline
                />
                </Col>
                <Col md={5} lg={3}>
                <img src={palmTreasures_screenshot} className="figure-img img-fluid rounded" alt="..." />
                </Col>
              </Row>}
              {islandEnabled &&
              <Row style={{ paddingTop: "20px", alignItems: "center" }}>
                <Col md={5} xl={3}>
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                    style={{width: "100%"}}
                  >
                    {"ISLAND TREASURE ALLOW"}
                  </Label>
                </Col>
                <Col md={2}>
              
                <Input
                  type="checkbox"
                  
                  checked={islandInput}
                  onChange={e => setIslandInput(e.target.checked)}
                  inline
                />
                </Col>
                <Col md={5} lg={3}>
                <img src={islandTreasures_screenshot} className="figure-img img-fluid rounded" alt="..." />
                </Col>
              </Row>}
              
              {stargamesEnabled &&
              <Row style={{ paddingTop: "20px", alignItems: "center" }}>
                <Col md={5} xl={3}>
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                    style={{width: "100%"}}
                  >
                    {"STARGAMES ALLOW"}
                  </Label>
                </Col>
                <Col md={2}>
              
                <Input
                  type="checkbox"
                  
                  checked={stargamesInput}
                  onChange={e => setStargamesInput(e.target.checked)}
                  inline
                />
                </Col>
                <Col md={5} lg={3}>
                <img src={stargames_screenshot} className="figure-img img-fluid rounded" alt="..." />
                </Col>
              </Row>}
              {AviatorAEnabled &&
              <Row style={{ paddingTop: "20px", alignItems: "center" }}>
                <Col md={5} xl={3}>
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                    style={{width: "100%"}}
                  >
                    {"AVIATOR A ALLOW"}
                  </Label>
                </Col>
                <Col md={2}>
              
                <Input
                  type="checkbox"
                  
                  checked={AviatorAInput}
                  onChange={e => setAviatorAInput(e.target.checked)}
                  inline
                />
                </Col>
                <Col md={5} lg={3}>
                <img src={aviatorA_screenshot} className="figure-img img-fluid rounded" alt="..." />
                </Col>
              </Row>}
              {AviatorBEnabled &&
              <Row style={{ paddingTop: "20px", alignItems: "center" }}>
                <Col md={5} xl={3}>
                  <Label
                    id="nameLabel"
                    htmlFor="nameInput"
                    className="col-md-5 col-form-label"
                    style={{width: "100%"}}
                  >
                    {"AVIATOR B ALLOW"}
                  </Label>
                </Col>
                <Col md={2}>
              
                <Input
                  type="checkbox"
                  
                  checked={AviatorBInput}
                  onChange={e => setAviatorBInput(e.target.checked)}
                  inline
                />
                </Col>
                <Col md={5} lg={3}>
                <img src={aviatorB_screenshot} className="figure-img img-fluid rounded" alt="..." />
                </Col>
              </Row>}







              <div className="text-end">
                                                <button type="submit" onClick={handleSubmit} className="btn btn-primary">{t("Create")}</button>
                                            </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewOperator;
