import React, { useEffect,useState } from 'react';
import { useTranslation } from "react-i18next";import { Card,CardHeader, CardBody, Col, Container, Input, Label, Row, Table,Button ,Form, Nav, NavItem, NavLink,TabContent, TabPane,UncontrolledTooltip, ListGroup, ListGroupItem,Accordion, AccordionItem,Collapse} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Link } from 'react-router-dom';
//import { BasicTable, ScrollVertical, ScrollHorizontal, AlternativePagination, FixedHeaderDatatables, ModalDataDatatables, AjaxDatatables } from '../Tables/DataTables/datatableCom';
// import { FormGrid, Gutters, VerticalForm, HorizontalForm, HorizontalFormLabelSizing, ColumnSizing, AutoSizing, InlineForms, FloatingLabels } from './FormlayoutsCode';
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import { useQuery,useMutation, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import changepass from '../../assets/images/imagesFAQ/changepass.png'
import profileimg from '../../assets/images/imagesFAQ/profile.png'
import timezonechangeimg from '../../assets/images/imagesFAQ/timezonechange.png'
import menuOperators from '../../assets/images/imagesFAQ/menuOperators.png'
import operatorsTable from '../../assets/images/imagesFAQ/operatorsTable.png'
import creditPreview from '../../assets/images/imagesFAQ/creditPreview.png'
import accountLimitPreview from '../../assets/images/imagesFAQ/accountLimitPreview.png'
import OPpermissionsAllowCreateOp from '../../assets/images/imagesFAQ/OPpermissionsAllowCreateOp.png'
import new_Operator from '../../assets/images/imagesFAQ/new_Operator.png'
import new_OperatorDialog from '../../assets/images/imagesFAQ/new_OperatorDialog.png'

import new_Shop from '../../assets/images/imagesFAQ/new_Shop.png'
import newShopGeneral from '../../assets/images/imagesFAQ/newShopGeneral.png'
import newShopChooseOp from '../../assets/images/imagesFAQ/newShopChooseOp.png'
import newShopChooseLobby from '../../assets/images/imagesFAQ/newShopChooseLobby.png'

import menuShops from '../../assets/images/imagesFAQ/menuShops.png'
import shopsTable from '../../assets/images/imagesFAQ/shopsTable.png'

import shopsAddCredits from '../../assets/images/imagesFAQ/shopsAddCredits.png'

import createNewUser from '../../assets/images/imagesFAQ/createNewUser.png'

import switchLobby from '../../assets/images/imagesFAQ/switchLobby.png'

import changeBonus from '../../assets/images/imagesFAQ/changeBonus.png'

import activateJackpotShop from '../../assets/images/imagesFAQ/activateJackpotShop.png'

import minmaxBet from '../../assets/images/imagesFAQ/min-maxBet.png'
















const USER_QUERY = gql`
query MyQuery($id: Int! ) {
    operators_by_pk(id: $id) {
      password
      username
    }
  }
`;
const EDIT_PASSWORD_MUTATION = gql`
mutation MyMutation($password: String, $id: Int!) {
    update_users_by_pk(pk_columns: {id: $id}, _set: {password: $password}){
        id
    }
  }  
  `;

  const EDIT_DETAILS_MUTATION = gql`
  mutation MyMutation($name: String, $id: Int!) {
    update_users_by_pk(pk_columns: {id: $id}, _set: {name: $name}){
        id
    }
  }  
  `;


  function SuccessMessage(props) {
    const [show, setShow] = useState(true);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setShow(false);
      }, 3000);
  
      return () => {
        clearTimeout(timer);
      };
    }, []);
  
    if (!show) {
      return null;
    }
  
    return (
      <div className="row">
        <div className="col">
          <div className="alert alert-success" role="alert">
            Success!
          </div>
        </div>
      </div>
    );
  }

let currID=null



const FAQ = () => {
  const [currentID, setcurrentID] = useState(-1);
  const [activeTab, setactiveTab] = useState("1");
  const [coll1, setcoll1] = useState(true);

    // Default Accordion
    const [col1, setCol1] = useState(false);
    const [col2, setCol2] = useState(false);
    const [col3, setCol3] = useState(false);
    const [col4, setCol4] = useState(false);
    const [col5, setCol5] = useState(false);
    const [col6, setCol6] = useState(false);
    const [col7, setCol7] = useState(false);
    const [col8, setCol8] = useState(false);
    const [col9, setCol9] = useState(false);
    const [col10, setCol10] = useState(false);
    const [col11, setCol11] = useState(false);
    const [col12, setCol12] = useState(false);

    const t_col1 = () => {
      setCol1(!col1);
      setCol2(false);
      setCol3(false);
      setCol4(false);
      setCol5(false);
      setCol6(false);
      setCol7(false);
      setCol8(false);
      setCol9(false);
      setCol10(false);
      setCol11(false);
      setCol12(false);
    };
    
    const t_col2 = () => {
      setCol1(false);
      setCol2(!col2);
      setCol3(false);
      setCol4(false);
      setCol5(false);
      setCol6(false);
      setCol7(false);
      setCol8(false);
      setCol9(false);
      setCol10(false);
      setCol11(false);
      setCol12(false);
    };
    
    const t_col3 = () => {
      setCol1(false);
      setCol2(false);
      setCol3(!col3);
      setCol4(false);
      setCol5(false);
      setCol6(false);
      setCol7(false);
      setCol8(false);
      setCol9(false);
      setCol10(false);
      setCol11(false);
      setCol12(false);
    };
    
    const t_col4 = () => {
      setCol1(false);
      setCol2(false);
      setCol3(false);
      setCol4(!col4);
      setCol5(false);
      setCol6(false);
      setCol7(false);
      setCol8(false);
      setCol9(false);
      setCol10(false);
      setCol11(false);
      setCol12(false);
    };
    
    const t_col5 = () => {
      setCol1(false);
      setCol2(false);
      setCol3(false);
      setCol4(false);
      setCol5(!col5);
      setCol6(false);
      setCol7(false);
      setCol8(false);
      setCol9(false);
      setCol10(false);
      setCol11(false);
      setCol12(false);
    };
    
    const t_col6 = () => {
      setCol1(false);
      setCol2(false);
      setCol3(false);
      setCol4(false);
      setCol5(false);
      setCol6(!col6);
      setCol7(false);
      setCol8(false);
      setCol9(false);
      setCol10(false);
      setCol11(false);
      setCol12(false);
    };
    
    const t_col7 = () => {
      setCol1(false);
      setCol2(false);
      setCol3(false);
      setCol4(false);
      setCol5(false);
      setCol6(false);
      setCol7(!col7);
      setCol8(false);
      setCol9(false);
      setCol10(false);
      setCol11(false);
      setCol12(false);
    };
    
    const t_col8 = () => {
      setCol1(false);
      setCol2(false);
      setCol3(false);
      setCol4(false);
      setCol5(false);
      setCol6(false);
      setCol7(false);
      setCol8(!col8);
      setCol9(false);
      setCol10(false);
      setCol11(false);
      setCol12(false);
    };
    
    const t_col9 = () => {
      setCol1(false);
      setCol2(false);
      setCol3(false);
      setCol4(false);
      setCol5(false);
      setCol6(false);
      setCol7(false);
      setCol8(false);
      setCol9(!col9);
      setCol10(false);
      setCol11(false);
      setCol12(false);
    };
    
    const t_col10 = () => {
      setCol1(false);
      setCol2(false);
      setCol3(false);
      setCol4(false);
      setCol5(false);
      setCol6(false);
      setCol7(false);
      setCol8(false);
      setCol9(false);
      setCol10(!col10);
      setCol11(false);
      setCol12(false);
      };
      
      const t_col11 = () => {
      setCol1(false);
      setCol2(false);
      setCol3(false);
      setCol4(false);
      setCol5(false);
      setCol6(false);
      setCol7(false);
      setCol8(false);
      setCol9(false);
      setCol10(false);
      setCol11(!col11);
      setCol12(false);
      };
      
      const t_col12 = () => {
      setCol1(false);
      setCol2(false);
      setCol3(false);
      setCol4(false);
      setCol5(false);
      setCol6(false);
      setCol7(false);
      setCol8(false);
      setCol9(false);
      setCol10(false);
      setCol11(false);
      setCol12(!col12);
      };    
//form state variables
  const [apiKey, setAPIkey] = useState("f9414980b249c4a03312b976adc2f3eb");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [cpass, setcpass] = useState("");


  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [mutationSuccess, setMutationSuccess] = useState(false);

  


  const [editPasswordMutation, { dataAccept1, loadingAccept1, errorAccept1 }] = useMutation(EDIT_PASSWORD_MUTATION);
//   const [editDetailsMutation, { dataAccept, loadingAccept, errorAccept }] = useMutation(EDIT_DETAILS_MUTATION);

  const [editDetailsMutation, { loading, error }] = useMutation(EDIT_DETAILS_MUTATION, {
    onCompleted: (data) => {
      console.log(data);
      // Handle the result of the mutation here
    },
    onError: (error) => {
      console.log(error);
      // Handle the error here
    }
  });


  function transformCreditsToCurrency(credits) {
    const currency = (credits / 100).toFixed(2);
    return currency;
  }

  const toggle = (tab) => {
      if (activeTab !== tab) {
          setactiveTab(tab);
      }
  };
  const { t } = useTranslation();
document.title ="Users";

  const loadDropdownObjects = async() => {
    var query = `query MyQuery {
        operators {
          allow_create_child_operator
          ancestors
          credits
          id
        }
      }`;
    const token = "notoken"
  const GraphQLrequestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'authorization': localStorage.getItem("token") },
    body: JSON.stringify({
      query
    })
  };
  
  const GraphQLresponse = await fetch(process.env.REACT_APP_API_URL, GraphQLrequestOptions)
  if(GraphQLresponse.status!=200){
    alert("Could not fetch data", GraphQLresponse.statusText)
    return
  }
  
  const GraphQ_json = await GraphQLresponse.json();
  const objectList= await GraphQ_json.data;
  console.log("ta dataa:",objectList)
  return
  }


  const { userId } = useParams();

  const { refetch } = 
  useQuery(
    USER_QUERY,
    {
      onError:(err) =>{
        console.log(err)
        // window.location.href = "/#/login"
  
      },
      variables: {id: localStorage.getItem("opID")},
      onCompleted: (data) => {
        console.log("TA DATA:",data)
        // setCredit(data.users_by_pk.credits)
        // setName(data.users_by_pk.name)
        setUsername(data.operators_by_pk.username)
        setPassword(data.operators_by_pk.password)
  

        // if(data.users!=undefined){
        //   const searchTerm = filterValue.toLowerCase()
        //   const filteredData = (data.users).filter((row) => {
        //     return Object.keys(row).some((key) => {
        //       const cellValue = String(row[key]).toLowerCase()
        //       return cellValue.includes(searchTerm)
        //     })
        //   })
        //   // console.log("filteredSData",originalData)
        //   theFilteredData=sortData(filteredData, sortState);
          
  
        //   // setRows(data.users)
        //   // initialRows=data.users
        //   // theFilteredData=data.users
  
        //   setRows(theFilteredData)
        //   initialRows=data.users
        //   theFilteredData=theFilteredData
        // }
  
        // setIsLoading(false)
        // setIsLoadingModal(false);
  
      },
      notifyOnNetworkStatusChange: true,
      // pollInterval:5000
    }
  );


  const handleSavePasswordClick = async() => {
    
    await editPasswordMutation({
      variables: {
        id: parseInt(userId),
        password: password
      }
    });
    setMutationSuccess(true);
  }

  const handleSaveDetailsClick = async() => {
   
    // await editDetailsMutation({
    //     variables: {
    //       id: parseInt(userId),
    //       name: name
    //     }
    //   });
    try {
        await editDetailsMutation({
          variables: {
            id: parseInt(userId),
            // name: name
          }
        });
        setMutationSuccess(true);
      } catch (error) {
        console.error(error);
      }
  }

  

useEffect(() => {
    if (mutationSuccess) {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
      setMutationSuccess(false)
    }
    console.log("the id",userId)
    currID=userId
    setcurrentID(userId)

  }, [mutationSuccess]);

// const { loading, error, data } = useQuery(USER_QUERY, {
//     variables: { id: parseInt(userId) } // pass the id as a variable to the query
//   });

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error</p>;

//   const { name1, credits, username } = data.users_by_pk;




const rowsPerPageSample={ rowsPerPageText: t('Rows per page:'), rangeSeparatorText: t('of'), noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }

  return (
    <React.Fragment>      
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="FAQ" pageTitle={("Home")} />
          {showSuccessMessage && (
        <div className="alert alert-success text-center" role="alert">
          Success!
        </div>
      )}
          <Row>
            <Col xs={12}>
            <Card>

{/* <PreviewCardHeader title="Default Accordion" /> */}

<CardBody>


</CardBody>
</Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default FAQ; 