import React, { useEffect,useState } from 'react';
import { useTranslation } from "react-i18next";import { Card,CardHeader, CardBody, Col, Container, Input, Label, Row, Table,Button,Modal, ModalBody, ModalHeader,ModalFooter, Spinner} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
// import { Modal, Spinner } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { useQuery,useMutation, gql } from '@apollo/client';
// import checkTokenValidity from '../../Layouts/Footer'
import DataTable from 'react-data-table-component';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, PieController, Tooltip, Legend } from 'chart.js';
import {formatWithCommas} from "../../Components/Common/functions"

Chart.register(ArcElement, PieController, Tooltip, Legend);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // Replace with your actual token
  const token = localStorage.getItem("token");

  // Add custom headers
  return {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem("token"),
    },
  };
});
// Initialize Apollo Client with custom headers
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // cache: 'no-cache'
  cache: new InMemoryCache(),
});
// password
// id
// is_enabled
// is_panic
// current_game
// gp_user_id
// credits
// bonus
// username
const DESCENDANTS_QUERY = gql`
query MyQuery($id: Int!) {
  operators_by_pk(id: $id) {
    descendants
  }
  games {
    gp_game_id
    gp_game_name
  }
}

`;

// password
// id
// is_enabled
// is_panic
// current_game
// gp_user_id
// credits
// bonus
// username
const SHOPIDS_QUERY = gql`

query MyQuery($_in: [Int!], $_ilike: String = "%%") {
  shops(order_by: {id: asc}, where: {operator_id: {_in: $_in}, gp_cur: {_ilike: $_ilike}}) {
    gp_shop_id
  }
}
`;

const BETS_AND_GAMES_QUERY_TODAY = gql`
query BetsAndGamesQuery($_gte: timestamptz = "", $_lte: timestamptz = "") {
    bets(where: {gp_date: {_gte: $_gte, _lte: $_lte}}) {
      gp_bet
      gp_win_lose
      game {
        id
      }
    }
    gv_bets(where: {date: {_gte: $_gte, _lte: $_lte}}) {
      bet
      win_lose
      gv_game {
        id
      }
    }
  }
`;

const BETS_AND_GAMES_QUERY = gql`
query MyQuery($_gte: timestamptz = "2023-07-1", $_lte: timestamptz = "2023-08-30") {
  games {
    id
    gp_game_id
    gp_game_name
    gp_game_category
  }
  daily_summary_games(
    order_by: {date: desc}
    where: {date: {_gte: $_gte, _lte: $_lte}}
  ) {
    date
    net_win_lose
    game_id
    total_bet
    total_win_lose
    game_provider
    game {
      gp_game_name
    }
  }
  settings_by_pk(id: 9) {
    json_value
    key
  }
  gv_games {
    id
    name
    pname
  }
  gr_games {
    id
    name
    label
  }
  gvm_games {
    id
    name
    pname
  }
  grm_games {
    id
    name
    label
  }
}
`;

const BETS_AND_GAMES_QUERY_OLD = gql`
query BetsAndGamesQuery($_in: [String!] = "", $_gte: timestamptz = "", $_lte: timestamptz = "") {
  games {
    id
    gp_game_id
    gp_game_name
    gp_game_category
  }
  bets(where: {gp_shop_id: {_in: $_in}, gp_date: {_gte: $_gte, _lte: $_lte}}) {
    gp_bet
    gp_win_lose
    gp_game_id
  }
    settings_by_pk(id: 9) {
  json_value
  key
}
}
`;
const BET_QUERY = gql`
query MyQuery($_eq: Int!, $_in: [String!] = "") {
  bets(where: {gp_shop_id: {_in: $_in}, gp_game_id: {_eq: $_eq}}) {
    gp_bet
    gp_win_lose
  }
}

`;
const GAME_QUERY = gql`
query MyQuery {
  games {
    gp_game_id
    gp_game_name
  }
}
`;
const USER_QUERY = gql`
query MyQuery($_in: [Int!] ) {
  users(order_by: {id: asc}, where: {shop: {operator_id: {_in: $_in}}}) {
    id
    is_enabled
    is_panic
    credits
    username
    name
    last_login
  }
}
`;
const ENABLE_USER_MUTATION = gql`
mutation MyMutation($is_enabled: Boolean, $_in: [Int!] ) {
  update_users(where: {id: {_in: $_in}}, _set: {is_enabled: $is_enabled}) {
    affected_rows
  }
}
`;

const ENABLE_USER_NOTIN = gql`
mutation MyMutation($is_enabled: Boolean = false, $_nin: [Int!] = 10) {
  update_users(where: {id: {_nin: $_nin}}, _set: {is_enabled: $is_enabled}) {
    affected_rows
  }
}
`;

const ENABLE_PANIC_USER = gql`
mutation MyMutation($is_panic: Boolean, $_in: [Int!]) {
  update_users(where: {id: {_in: $_in}}, _set: {is_panic: $is_panic}) {
    affected_rows
  }
}
`;
const SET_TOP_GAMES = gql`

mutation MyMutation($json_value: jsonb !) {
  update_settings_by_pk(pk_columns: {id: 9}, _set: {json_value: $json_value}) {
    id
  }
}
`;
let theNotSelected=[]
let theFilteredData=[]
let initialRows=[]
const predefinedRanges = [
  {
    label: 'Today',
    value: [new Date(new Date().setUTCHours(0, 0, 0, 0)), new Date(new Date().setUTCHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(new Date().setUTCHours(0, 0, 0, 0)), -1), addDays(new Date(new Date().setUTCHours(23, 59, 59, 999)), -1)],
    placement: 'left'
  },
  {
    label: 'This week',
    value: [startOfWeek(new Date(new Date().setUTCHours(0, 0, 0, 0)),{ weekStartsOn: 1 }), endOfWeek(new Date(new Date().setUTCHours(23, 59, 59, 999)),{ weekStartsOn: 1 })],
    placement: 'left'
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(new Date().setUTCHours(0, 0, 0, 0)), 6), new Date(new Date().setUTCHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(new Date().setUTCHours(0, 0, 0, 0)), 29), new Date(new Date().setUTCHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date(new Date().setUTCHours(0, 0, 0, 0))), new Date(new Date().setUTCHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(new Date().setUTCHours(0, 0, 0, 0)), -1)), endOfMonth(addMonths(new Date(new Date().setUTCHours(23, 59, 59, 999)), -1))],
    placement: 'left'
  },
  {
    label: 'This year',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().setUTCHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Last year',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    placement: 'left'
  },
  {
    label: 'All time',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().setUTCHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Last week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
      ];
    },
    appearance: 'default'
  },
  {
    label: 'Next week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
      ];
    },
    appearance: 'default'
  }
];
// const generateTestData = () => {
//   const testData = []
//   for (let i = 0; i <= 2000; i++) {
//     testData.push({
//       bonus: Math.floor(Math.random() * 100),
//       credits: Math.floor(Math.random() * 1000),
//       current_game: `Game ${i}`,
//       gp_user_id: `user${i}`,
//       id: i,
//       is_enabled: Math.random() < 0.5,
//       password: `password${i}`,
//       shop_id: Math.floor(Math.random() * 10),
//       username: `user${i}`,
//     })
//   }
//   return testData
// }

// const shops = [];

// for (let i = 0; i < 2000; i++) {
//   const shop = {
//     bonus_percentage: Math.floor(Math.random() * 100),
//     credits: Math.floor(Math.random() * 1000),
//     currency: Math.random() < 0.5 ? "USD" : "EUR",
//     gp_cur: Math.random() < 0.5 ? "BTC" : "ETH",
//     gp_maxbet: Math.floor(Math.random() * 10000),
//     gp_maxbet_roulette: Math.floor(Math.random() * 10000),
//     gp_maxbet_tablegames: Math.floor(Math.random() * 10000),
//     gp_minbet: Math.floor(Math.random() * 100),
//     gp_minbet_roulette: Math.floor(Math.random() * 100),
//     gp_minbet_tablegames: Math.floor(Math.random() * 100),
//     gp_rtplevel: Math.floor(Math.random() * 10),
//     gp_shop_id: Math.random().toString(36).substring(2, 10),
//     id: i,
//     is_enabled: Math.random() < 0.5,
//     is_panic: Math.random() < 0.5,
//     last_login: new Date().toISOString(),
//     name: Math.random().toString(36).substring(2, 10),
//     operator_id: Math.floor(Math.random() * 1000),
//     password: Math.random().toString(36).substring(2, 10),
//     new_user_limit: Math.floor(Math.random() * 1000),
//     username: Math.random().toString(36).substring(2, 10)
//   };
//   shops.push(shop);
// }

// console.log(shops);




// const transactions = [];

// for (let i = 0; i < 2000; i++) {
//   const transaction = {
//     amount: parseFloat((Math.random() * 1000).toFixed(2)),
//     created_at: new Date().toISOString(),
//     description: Math.random().toString(36).substring(2, 10),
//     from_id: Math.floor(Math.random() * 1000),
//     from_type: Math.floor(Math.random() * 10),
//     from_username: Math.random().toString(36).substring(2, 10),
//     id: i,
//     to_id: Math.floor(Math.random() * 1000),
//     to_type: Math.random() < 0.5 ? "user" : "shop",
//     to_username: Math.random().toString(36).substring(2, 10)
//   };
//   transactions.push(transaction);
// }

// console.log(transactions);




// const testData = generateTestData()
// function compareArrays(arrayA, arrayB) {
//   console.log("resultA",arrayA)
//   const idsB = arrayB.map((item) => item.id);
//   const result = arrayA.filter((item) => !idsB.includes(item.id));
//   console.log("result",result)
//   return result;
// }
const currencies = [
  { id: 0, name: "ALL" },
  { id: 1, name: "BLR" },
  { id: 2, name: "CHF" },
  { id: 3, name: "EUR" },
  { id: 4, name: "FC" },
  { id: 5, name: "KES" },
  { id: 6, name: "LEU" },
  { id: 7, name: "NGN" },
  { id: 8, name: "PEN" },
  { id: 9, name: "PHP" },
  { id: 10, name: "RMB" },
  { id: 11, name: "TND" },
  { id: 12, name: "TRY" },
  { id: 13, name: "USD" },
  { id: 14, name: "XAF" },
  { id: 15, name: "ZAR" },
  { id: 16, name: "ZMW" },
  { id: 17, name: "ALL" },
];
const actionObj={1:"Enable Panic ",
2:"Disable Panic",
3:"Enable User",
4:"Disable User"}
            let top10TotalBet = [];
            let top10TotalWinLose = [];
            let top10NetWinLose = [];
            
const RTP = () => {
const { t } = useTranslation();
document.title ="Top Providers";
// const { dataUSERS,loadingUSERS,errorUSERS } = useQuery(USER_QUERY);
const [rows, setRows] = useState([])
const [isLoading, setIsLoading] = useState(true)
const [selectedOption, setSelectedOption] = useState('');
const [filterValue, setFilterValue] = useState('');
const [sortState, setSortState] = useState({});
const [isLoadingModal, setIsLoadingModal] = useState(true);
const [isModalOpen, setIsModalOpen] = useState(false);
const [theTopGames, setTheTopGames] = useState([]);
const [selectedCurrency, setSelectedCurrency] = useState('ALL');
const [startDate,setStartDate] = useState(new Date(new Date().setUTCHours(0, 0, 0, 0)));
const [endDate,setEndDate] = useState(formatDateToUTCISOString(new Date(new Date().setUTCHours(23, 59, 59, 999))));

// const [top10TotalBet, settop10TotalBet] = useState([]);
// const [top10TotalWinLose, settop10TotalWinLose] = useState([]);
// const [top10NetWinLose, settop10NetWinLose] = useState([]);

const [dateRange, setDateRange] = useState({
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection'
});

// const removeTopGame = async(game) => {
//   // Create a new array that does not include the game.gameId
//   const updatedTopGames = theTopGames.filter(topGame => topGame !== game.gameIdOG);

//   await setTopGames({
//     variables: {
//       json_value: updatedTopGames, 
//     }
//   })
//   // .then(()=>{window.location.reload()});;

//   // await refetch({});
// };

const removeTopGame = async(game) => {
  // Filter out the game based on gameIdOG and provider
  const updatedTopGames = game.gameProvider==="gvgames"?theTopGames.filter(topGame => 
    !(topGame.id === game.gameIdOG && topGame.provider === "gvgames")
  ):
  game.gameProvider==="grgames"?theTopGames.filter(topGame => 
    !(topGame.id === game.gameIdOG && topGame.provider === "grgames")
  ):
  theTopGames.filter(topGame => 
    !(topGame.id === game.gameIdOG && topGame.provider === "gpgames")
  );

  await setTopGames({
    variables: {
      json_value: updatedTopGames, 
    }
  })
  // .then(()=>{window.location.reload()});;
  // await refetch({});
};

const addTopGame = async(game) => {
  // Add the game to the existing list
  const updatedTopGames = [...theTopGames, {id:game.gameIdOG,provider:game.gameProvider==="gvgames"||game.gameProvider==="grgames"?game.gameProvider:"gpgames"}];

  await setTopGames({
    variables: {
      json_value: updatedTopGames, 
    }
  })
  // .then(()=>{window.location.reload()});

  // await refetch({});
};

const handleCurrencyChange = (e) => {
  setIsLoadingModal(true)
  setSelectedCurrency(e.target.value)
  refetch({})
  // getSummary(currentOp, startDate, endDate,e.target.value)
  // .then(summary => {setCashRows(summary);setCashFooter([createSummary(summary)]);console.log(summary)})
  // .catch(error => console.error('Error fetching data:', error));
};

const handleConfirm = () => {
  // Perform the action you want to execute here
  handleButtonClick()
  setIsModalOpen(false); // Close the modal
};

const handleCancel = () => {
  setIsModalOpen(false); // Close the modal
};

const handleClick = () => {
  if(selectedOption!=0&&selectedRows.length>0){
    setIsModalOpen(true); // Open the modal
  }
};

function transformCreditsToCurrency(credits) {
  const currency = (credits).toFixed(2);
  return currency;
}

const [enableUserMutation, { loading, error }] = useMutation(ENABLE_USER_MUTATION, {
  onCompleted: (data) => {
    console.log(data);
    // Handle the result of the mutation here
  },
  onError: (error) => {
    console.log(error);
    // Handle the error here
  }
});

const [enablePanicUserMutation, { dataAccept1, loadingAccept1, errorAccept1 }] = useMutation(ENABLE_PANIC_USER);

const [setTopGames, { dataAccept12, loadingAccept12, errorAccept12 }] = useMutation(SET_TOP_GAMES, {
  onCompleted: async(data) => {
    await refetch({})
    console.log(data);
    // Handle the result of the mutation here
  },
  onError: (error) => {
    console.log(error);
    // Handle the error here
  }
});

function handleSelectChange(event) {
  const value = event.target.selectedOptions[0].value;
  setSelectedOption(value);
}


const redirectWithId = (id) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set('id', id);
  const params = urlSearchParams.toString();
  // console.log("paransss",params)
  window.location.href = `/#/userDetails?${params}`;
}

const filterTable = (event, originalData, setData) => {
  setFilterValue(event.target.value);
  const searchTerm = event.target.value.toLowerCase()
  const filteredData = originalData.filter((row) => {
    return Object.keys(row).some((key) => {
      const cellValue = String(row[key]).toLowerCase()
      return cellValue.includes(searchTerm)
    })
  })
  // console.log("filteredSData",originalData)
  theFilteredData=filteredData
  setRows(filteredData)
}

async function fetchBetsSummary() {
  const today = new Date().toISOString().split('T')[0];

  // Convert startDate and endDate to 'YYYY-MM-DD' format
  const startDateStr = new Date(startDate).toISOString().split('T')[0];
  const endDateStr = new Date(endDate).toISOString().split('T')[0];

  // Check if the dates are for today
  const isToday = endDateStr >= today 
  console.log("isToday",isToday)
  let dataToday=[];
  let data;

  // if (isToday) {
  //   const responseToday = await client.query({
  //     query: BETS_AND_GAMES_QUERY_TODAY,
  //     variables: {
  //       // _in: shopIds,
  //       _gte: today,
  //       _lte: endDate,
  //     },
  //     fetchPolicy: 'network-only',

  //   });
  //   dataToday = responseToday.data;
  // } 
    const response = await client.query({
      query: BETS_AND_GAMES_QUERY,
      variables: {
        // _in: shopIds,
        _gte:startDate,
        _lte:endDate,
      },
      
              fetchPolicy: 'network-only', // 
    });
    data = response.data;
  

  console.log("ta batched data:", data);
  console.log("ta batched dataToday:", dataToday);


  console.log("games",data.games)
  const gameSummaries = {};
  await setTheTopGames(data.settings_by_pk.json_value.slice(0))
  // Initialize game summaries
  data.games.forEach((game) => {
    const isTopGame = data.settings_by_pk.json_value.some(item => 
      item.id === game.id && item.provider === "gpgames"
    );
    gameSummaries[game.id+"ggslot"] = {
      gameIdOG: game.id,
      gameId: game.gp_game_id,
      gameName: game.gp_game_name,
      gameCategory: game.gp_game_category,
      gameProvider:"ggslot",
      isTop: isTopGame,
      totalBet: 0,
      totalWinLose: 0,
      netWinLose: 0,
      
      // freespins:0,
      // numofspins:0
    };
  });

  data.gv_games.forEach((game) => {
    const isTopGame = data.settings_by_pk.json_value.some(item => 
      item.id === game.id && item.provider === "gvgames"
    );
    gameSummaries[game.id+"gvgames"] = {
      gameIdOG: game.id,
      gameId: game.id,
      gameName: game.name,
      gameCategory: game.pname.toLowerCase(),
      gameProvider:"gvgames",
      isTop: isTopGame,
      totalBet: 0,
      totalWinLose: 0,
      netWinLose: 0,
      
      // freespins:0,
      // numofspins:0
    };
  });

  data.gr_games.forEach((game) => {
    const isTopGame = data.settings_by_pk.json_value.some(item => 
      item.id === game.id && item.provider === "grgames"
    );
    gameSummaries[game.id+"grgames"] = {
      gameIdOG: game.id,
      gameId: game.id,
      gameName: game.name,
      gameCategory: game.label.toLowerCase(),
      gameProvider:"grgames",
      isTop: isTopGame,
      totalBet: 0,
      totalWinLose: 0,
      netWinLose: 0,
      
      // freespins:0,
      // numofspins:0
    };
  });

  data.gvm_games.forEach((game) => {
    const isTopGame = data.settings_by_pk.json_value.some(item => 
      item.id === game.id && item.provider === "gvmgames"
    );
    gameSummaries[game.id+"gvmgames"] = {
      gameIdOG: game.id,
      gameId: game.id,
      gameName: game.name,
      gameCategory: game.pname.toLowerCase(),
      gameProvider:"gvmgames",
      isTop: isTopGame,
      totalBet: 0,
      totalWinLose: 0,
      netWinLose: 0,
      
      // freespins:0,
      // numofspins:0
    };
  });

  data.grm_games.forEach((game) => {
    const isTopGame = data.settings_by_pk.json_value.some(item => 
      item.id === game.id && item.provider === "grmgames"
    );
    gameSummaries[game.id+"grmgames"] = {
      gameIdOG: game.id,
      gameId: game.id,
      gameName: game.name,
      gameCategory: game.label.toLowerCase(),
      gameProvider:"grmgames",
      isTop: isTopGame,
      totalBet: 0,
      totalWinLose: 0,
      netWinLose: 0,
      
      // freespins:0,
      // numofspins:0
    };
  });

  
  // Calculate the summary for each bet
  data.daily_summary_games.forEach((daily_summaries) => {
    const summary = gameSummaries[daily_summaries.game_id+daily_summaries.game_provider];
    if (!summary) {
      console.log("No summary for game:", daily_summaries.game_id+daily_summaries.game_provider);
      return;
    }
    summary.totalBet += daily_summaries.total_bet;
    summary.totalWinLose += daily_summaries.total_win_lose;
    summary.netWinLose += daily_summaries.net_win_lose;
    // summary.freespins+=bet.gp_bet<0.00001?1:0
    // summary.numofspins+=1
  });
  if (dataToday && dataToday.bets) {

  dataToday.bets.forEach((bet) => {
    if(bet.game===null){
      console.log("BET GAME NOT FOUND",bet)
      return
    }
    const summary = gameSummaries[bet.game.id+"ggslot"];
    if (!summary) {
      console.log("No summary for game:", bet.game.id+"ggslot");
      return;
    }
    summary.totalBet += bet.gp_bet;
    summary.totalWinLose +=  bet.gp_bet - (bet.gp_win_lose + bet.gp_bet);
    summary.netWinLose += (bet.gp_win_lose + bet.gp_bet);
    // summary.freespins+=bet.gp_bet<0.00001?1:0
    // summary.numofspins+=1
  });
  }
  if (dataToday && dataToday.gv_bets) {

  dataToday.gv_bets.forEach((bet) => {
    const summary = gameSummaries[bet.gv_game.id+"gvgames"];
    if (!summary) {
      console.log("No summary for game:", bet.gv_game.id+"gvgames");
      return;
    }
    summary.totalBet += bet.bet;
    summary.totalWinLose +=  bet.bet - (bet.win_lose + bet.bet);
    summary.netWinLose += (bet.win_lose + bet.bet);
    // summary.freespins+=bet.gp_bet<0.00001?1:0
    // summary.numofspins+=1
  });
  }
  return Object.values(gameSummaries);
}

const getTop10 = (property,dataArray) => {
  const sortedArray = dataArray.slice().sort((a, b) => b[property] - a[property]);
  return sortedArray.slice(0, 10);
};
const { refetch } = 
useQuery(
  DESCENDANTS_QUERY,

  {
    onError:(err) =>{
      console.log("jjh"+err)
      // window.location.href = "/#/login"

    },
    variables:  {"id": localStorage.getItem("opID")},

    // variables: {domain_id: {"_eq": locStorageItem}},
    onCompleted: async(data) => {
      console.log("TA DATA:",data)
      
      if(data.operators_by_pk!=null){
        // console.log("thedescentants",data.operators_by_pk.descendants.push(localStorage.getItem("opID")))
        // const theDescendants=data.operators_by_pk.descendants
        // await theDescendants.push(localStorage.getItem("opID"))
        // const shopsResponse = await client.query({
        //   query: SHOPIDS_QUERY,
        //   variables: selectedCurrency==="ALL"?{ "_in": theDescendants, }:{ "_in": theDescendants, _ilike:"%"+selectedCurrency+"%"},
        //   fetchPolicy: 'network-only', // or 'cache-and-network'
        // });        
        // console.log("operatorsResponse",shopsResponse)
        // if(shopsResponse.data.shops!=undefined){
          // const shopsResponse = await client.query({
          //   query: SHOPIDS_QUERY,
          //   variables: { "_in": theDescendants },
          //   fetchPolicy: 'network-only', // or 'cache-and-network'
          // });        
          // console.log("shopsResponse",shopsResponse)
          // const shopIds=shopsResponse.data.shops.map(shop => shop.gp_shop_id);
          // console.log("shopIds",shopIds)

          fetchBetsSummary().then((betsSummary) => {
            console.log("betsSummary",betsSummary);
            // const searchTerm = filterValue.toLowerCase()
             top10TotalBet = getTop10('totalBet',betsSummary);
             top10TotalWinLose = getTop10('netWinLose',betsSummary);
             top10NetWinLose = getTop10('totalWinLose',betsSummary);
            // settop10TotalBet(getTop10('totalBet',betsSummary))
            // settop10TotalWinLose(getTop10('totalWinLose',betsSummary))
            // settop10NetWinLose(getTop10('netWinLose',betsSummary))

            // const filteredData = (betsSummary).filter((row) => {
            //   return Object.keys(row).some((key) => {
            //     const cellValue = String(row[key]).toLowerCase()
            //     return cellValue.includes(searchTerm)
            //   })
            // })
            // const filteredData = (betsSummary)
            betsSummary.sort((a, b) => b.totalBet - a.totalBet);

// Keep only the top 10 objects
          // const top10 = betsSummary.slice(0, 10);
            // console.log("filteredSData",originalData)
            // theFilteredData= sortData(filteredData, sortState);
            
    
            // setRows(data.users)
            // initialRows=data.users
            // theFilteredData=data.users
             setRows([])
  
             setRows(betsSummary)
             setIsLoading(false)
             setIsLoadingModal(false);
            // initialRows=betsSummary
            // theFilteredData=theFilteredData
          });

        // }

      }




    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only', // or 'cache-and-network'

    // pollInterval:5000
  }
);
const createPieChartData = (top10Array, property) => {
  return {
    labels: top10Array.map(item => item.gameName),
    datasets: [
      {
        data: top10Array.map(item => item[property]),
        backgroundColor: [
          // Add colors for the pie chart slices
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#E7E9ED', '#A8B3C5', '#FF3333', '#BBBBBB'
        ]
      }
    ]
  };
};
// const userId = '123'; // This is the ID you want to pass to the next page

//       {/* Link with parameters */}
//       <Link to={`/userDetails/${userId}`}>User Details</Link>

//       {/* Button with parameters */}
//       <button onClick={() => window.location.href = `/#/userDetails/${userId}`}>
//         User Details
//       </button>




//       import React from 'react';
// import { useParams } from 'react-router-dom';

// const UserDetails = () => {
//   const { userId } = useParams();

//   return (
//     <div>
//       <h1>User Details</h1>
//       <p>User ID: {userId}</p>
//       {/* Other user details */}
//     </div>
//   );
// };

// export default UserDetails;

useEffect(() => {
}, []);
const [toggledClearRows, setToggledClearRows] = useState(false);

const [selectedRows, setSelectedRows] = React.useState([]);
// let isAllSelected=false
const dataTableRef = React.createRef();

// const handleClearSelection = () => {
//   setSelectedRows([]); // Clear the selected rows state
//   dataTableRef.current.clearSelectedRows(); // Clear the selected rows in the DataTable component
// };
const handleClearSelection = () => {
  setSelectedRows([]);
  setToggledClearRows(!toggledClearRows);
};


	const handleRowSelected = React.useCallback(state => {
    //mporw apla panta na exw ta selected je ta not selected je na stellw jina pu en liotera xwris na kamnw ulla ta ifs
    // if(state.allSelected){
    //   isAllSelected=true
    //   theNotSelected=[]
    // }else{
    //   if(state.selectedCount==0){
    //     isAllSelected=false
    //   }else{
    //     theNotSelected=compareArrays(theFilteredData,state.selectedRows)
    //   }
    // }
    // setSelectedRows([]);

		setSelectedRows(state.selectedRows);
        console.log("eta rows",state.selectedRows)
        console.log("et state",state)
        console.log("eta not selected",theNotSelected)

	}, []);


  
  const handleEnablePanic = async () => {
    setIsLoadingModal(true);
      const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]
      console.log("the in idArray",idArraysel)
      console.log("selection",selectedOption==1)

      await enablePanicUserMutation({
        variables: {
          is_panic: selectedOption==1, // Set to true to enable the user
          _in: idArraysel // Set to the ID of the user to enable
        }
      });
    // }
    await refetch({})

  };


  const handleEnableUser = async () => {
    setIsLoadingModal(true);

    // if(false&&isAllSelected && theNotSelected.length<selectedRows.length){
    //   const idArray = theNotSelected.map(obj => obj.id); // [1, 2, 3]
    //   console.log("the not in idArray",idArray)
    //   console.log("selection",selectedOption==3)

    //   // selectedOption===3
    //   await enableNOTINUserMutation({
    //     variables: {
    //       is_enabled: selectedOption==3, // Set to true to enable the user
    //       _nin: idArray // Set to the ID of the user to enable
    //     }
    //   });
    // }else{
      const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]
      console.log("the in idArray",idArraysel)
      console.log("selection",selectedOption==3)

      await enableUserMutation({
        variables: {
          is_enabled: selectedOption==3, // Set to true to enable the user
          _in: idArraysel // Set to the ID of the user to enable
        }
      });
    // }
    await refetch({})
  };

    const handleButtonClick = async () => {

    switch(selectedOption) {
      case '1':
        await handleEnablePanic()
        await handleClearSelection()
        console.log('Option 1 selected');
        break;
      case '2':
        await handleEnablePanic()
        await handleClearSelection()
        // perform action for option 2
        console.log('Option 2 selected');
        break;
      case '3':
        await handleEnableUser()
        await handleClearSelection()
        // perform action for option 3
        console.log('Option 3 selected');
        break;
      case '4':
        await handleEnableUser()
        await handleClearSelection()
        // perform action for option 4
        console.log('Option 4 selected');
        break;
      default:
        // do nothing
        break;
    }
    
  }
  const handleSortChange = (column, sortDirection) => {
    setSortState({ column, sortDirection });
  };
  // onClick={handleButtonClick}
  function sortData(data, sortState) {
    const { column, sortDirection } = sortState;
    if (!column || !sortDirection) return data;
  console.log("current column",column)
  console.log("current sortDirection",sortDirection)
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return sortDirection === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  
    return sortedData;
  }
  function formatDateToUTCISOString(dateInput) {
    const date = new Date(dateInput);
    const formattedDate = date.toISOString().replace('Z', '+00:00');;
    return formattedDate;
  }
  function formatDateToUTCISOStringStart(date) {
    date.setUTCHours(0, 0, 0, 0);  // Set time to 00:00
    return date.toISOString();
}

function formatDateToUTCISOStringEnd(date) {
    date.setUTCHours(23, 59, 59, 999);  // Set time to 23:59:59.999
    return date.toISOString();
}
    const handleDateChange = (dates) => {
    if(dates===null){
      return
    }
    console.log("formatDateToUTCISOString(dates[0])",formatDateToUTCISOStringStart(dates[0]))
    console.log("formatDateToUTCISOStringEnd(dates[1])",formatDateToUTCISOStringEnd(dates[1]))
    setIsLoadingModal(true)
    // setSelectedDates(dates);
    setStartDate(formatDateToUTCISOStringStart(dates[0]))
    setEndDate(formatDateToUTCISOStringEnd(dates[1]))
    refetch({})
    // // console.log('Selected Dates:', formatDateToUTCISOString(dates[0]));
    // getSummary(currentOp, formatDateToUTCISOString(dates[0]), formatDateToUTCISOString(dates[1]),selectedCurrency)
    // .then(summary => {setCashRows(summary);setCashFooter([createSummary(summary)]);console.log(summary)})
    // .catch(error => console.error('Error fetching data:', error));
  };
  const customStyles = {
    // header: {
    //   style: {
    //     border: '1px solid #dee2e6'
    //   }
    // },
    cells: {
      style: {
        border: '0.5px solid #dee2e6',
      },
    },
  };

  function formatDate(dateString, timezone) {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: localStorage.getItem("opTimezone"),
    };
    return date.toLocaleString('en-GB', options).replace(',', '');
  }
  const donutOptions = {
    cutout: '50%',
    plugins: {
      legend: {
        position: 'left', // Move the legend to the left side
      },
    },
  };
  
  const columns = [
    // {
    //     name: t("Username"),
    //     sortable: true,
    //     // width: "150px",
    //     sortFunction: (a, b) => a.username.localeCompare(b.username),
    //     selector: (cell) => {return (<Link to={`/userDetails/${cell.id}`} onClick={(e) => {
    //       e.preventDefault();
          
    //       window.location.href = `/#/userDetails/${cell.id}`;
    //     }}
    //     >{cell.username}</Link>);},
        
    // },
    {
        name: t("Game"),
        // width: "150px",
            //     sortFunction: (a, b) => a.username.localeCompare(b.username),
        selector: row => row.gameName,
        sortable: true
    },
    {
      name: t("Provider"),
      // width: "150px",
          //     sortFunction: (a, b) => a.username.localeCompare(b.username),
      selector: row => row.gameProvider,
      sortable: true
  },
    
    {
        name: t("Credits In"),
        // width: "150px",
        sortFunction:  (a, b) => (a.totalBet >= b.totalBet ? 1 : a.totalBet == b.totalBet ? 0 : -1),
        selector: row => formatWithCommas(transformCreditsToCurrency(row.totalBet/100)),
        sortable: true
    },
    {
      name: t("Credits Out"),
      // width: "150px",
      sortFunction:  (a, b) => (a.netWinLose >= b.netWinLose ? 1 : a.netWinLose == b.netWinLose ? 0 : -1),

      selector: row => formatWithCommas(transformCreditsToCurrency(row.netWinLose/100)),
      sortable: true
  },
  {
    name: t("Credits Diff"),
    // width: "150px",
    sortFunction:  (a, b) => (a.totalWinLose >= b.totalWinLose ? 1 : a.totalWinLose == b.totalWinLose ? 0 : -1),

    selector: row => formatWithCommas(transformCreditsToCurrency(row.totalWinLose/100)),
    sortable: true
},
{
  name: t("RTP %"),
  // width: "150px",
  sortFunction:  (a, b) => ((!a.totalBet<0.00001?((a.netWinLose)/(a.totalBet)*100):0) >= (!b.totalBet<0.00001?((b.netWinLose)/(b.totalBet)*100):0) ? 1 : (!a.totalBet<0.00001?((a.netWinLose)/(a.totalBet)*100):0) == (!b.totalBet<0.00001?((b.netWinLose)/(b.totalBet)*100):0) ? 0 : -1),
  selector: row => transformCreditsToCurrency(!row.totalBet<0.00001?((row.netWinLose)/(row.totalBet)*100):0),
  sortable: true
},
{
  name: t("Top"),
  // width: "150px",
  cell: (row) => (
    <div className="form-check form-switch">
      <Input
        className="form-check-input"
        type="checkbox"
        role="switch"
        id={`flexSwitchCheckCheckedDisabled${row.gameName}`}
        checked={row.isTop}
        // checked={true}

        onChange={() => {
          row.isTop
            ? removeTopGame(row)
            : addTopGame(row);
        }}
      />
    </div>
  ),
  // sortable: true
},


//   {
//     name: t("Spins"),
//     // width: "150px",
//     selector: row => (row.freespins),
//     sortable: true
// },
// {
//   name: t("RTP %"),
//   // width: "150px",
//   selector: row => transformCreditsToCurrency(!row.totalBet<0.00001?((row.netWinLose)/(row.totalBet)*100):0),
//   sortable: true
// },
    // {
    //     name: t("Last login"),
    //     width: "200px",
    //     selector: row => formatDate(row.last_login,'America/New_York'),
    //     sortable: true,

    //     //             style: {
    //     //       minWidth: '200px', // set minimum width
    //     //       // maxWidth: '350px' // set maximum width
              
    //     //     },


    // },
   


];

//     const columns = [
//         {
//             name: <span className='font-weight-bold fs-13'>Username</span>,
//             sortable: true,

//             sortFunction: (a, b) => a.username.localeCompare(b.username),
//             selector: (cell) => {return (<Link to={`/userDetails/${cell.id}`} onClick={(e) => {
//               e.preventDefault();
              
//               window.location.href = `/#/userDetails/${cell.id}`;
//             }}
//             >{cell.username}</Link>);},
            
//         },
//         {
//             name: <span className='font-weight-bold fs-13'>Name</span>,
//             selector: row => row.username,
//             sortable: true
//         },
//         {
//             name: <span className='font-weight-bold fs-13'>Credits</span>,
//             selector: row => row.credits,
//             sortable: true
//         },
//         {
//             name: <span  className='font-weight-bold fs-13'>Last login</span>,
//             selector: row => row.last_login,
//             sortable: true,
//             style: {
//               minWidth: '350px', // set minimum width
//               maxWidth: '350px' // set maximum width
              
//             },
//             headerWrapStyles: { 
//               textAlign: "center",
//               minWidth: '350px', // set minimum width
//               maxWidth: '350px' // set maximum width
              
//              }

//         },
//         {
//             name: <span style={{"minWidth":"50px"}} className='font-weight-bold fs-13'>Enabled</span>,
//             sortable: true,
//             sortFunction: (a, b) => (a.is_enabled === b.is_enabled ? 0 : a.is_enabled ? 1 : -1),
//             selector: (cell) => {
//                 return (
//                     <React.Fragment>

//                             {(cell.is_enabled)?<i id="0" className="ri-check-fill" style={{ color: 'green' }}></i>:<i id="1" className=" ri-close-fill" style={{ color: 'red' }}></i>}
                  
//                     </React.Fragment>
//                 );
//             },
//             style: {
//               minWidth: '50px', // set minimum width
//               maxWidth: '50px' // set maximum width
              
//             },
//             headerWrapStyles: { 
//               textAlign: "center",
//               minWidth: '50px', // set minimum width
//               maxWidth: '50px' // set maximum width
              
//              }
//         },
//         {
//             name: <span className='font-weight-bold fs-13'>Panic</span>,
//             sortable: true,
//             sortFunction: (a, b) => (a.is_panic === b.is_panic ? 0 : a.is_panic ? 1 : -1),
//             selector: (cell) => {
//               return (
//                   <React.Fragment>

// {(cell.is_panic)?<i id="0" className="ri-check-fill" style={{ color: 'red' }}></i>:<i id="1" className=" ri-close-fill" style={{ color: 'green' }}></i>}
                
//                   </React.Fragment>
//               );
//           },
//             sortable: true
//         },


//     ];

const rowsPerPageSample={ rowsPerPageText: t('Rows per page:'), rangeSeparatorText: t('of'), noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }

  return (
    <React.Fragment>      
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Top Providers")} pageTitle={("Home")} />
          <Row>
            <Col xs={12}>

    <Row className="g-4 mb-3">

{/* <Col className="col-sm-auto"> */}
<Col md="6">
<DateRangePicker
ranges={predefinedRanges}
format="yyyy-MM-dd HH:mm:ss"
placeholder="Choose Range"
style={{ width: 600 }}
onChange={handleDateChange}


/>
</Col>

{/* <Col md="6">
    <div className="d-flex justify-content-md-end">
        <div className="search-box sm-12" style={{"width":"300px"}} >
       
            <input type="text" className="form-control search" placeholder={t("Search...")} value={filterValue} onChange={(e)=>{filterTable(e,initialRows,setRows)}} />
            <i className="ri-search-line search-icon"></i>
        </div>
    </div>
</Col> */}
</Row>
            <Row>
            <Col md="4">
            <h5>{t("Top 10 Providers by Appointments")}</h5>
      <Pie data={createPieChartData(top10TotalBet, 'totalBet')} options={donutOptions}/>

            </Col>
            <Col md="4">
            <h5>{t("Top 10 Providers by Reviews")}</h5>
      <Pie data={createPieChartData(top10NetWinLose, 'netWinLose')} options={donutOptions}/>

            </Col>
            {/* <Col md="4">
            <h5>{t("Top 10 Providers by DIFF")}</h5>
      <Pie data={createPieChartData(top10TotalWinLose , 'totalWinLose')} options={donutOptions}/>

            </Col> */}
            </Row>

            {/* <div>



    </div> */}
            <Card>
                                {/* <CardHeader>
                                    <h5 className="card-title mb-0">RTP</h5>
                                </CardHeader> */}
                                <CardBody>


            <DataTable

            onSort={handleSortChange}
            striped
            progressPending={isLoading}
            dense
            highlightOnHover
            columns={columns}
            data={rows}
            // selectableRows
            // onSelectedRowsChange={handleRowSelected}
            pagination
            paginationRowsPerPageOptions={[10, 15, 20,100]}
paginationComponentOptions={rowsPerPageSample}
            clearSelectedRows={toggledClearRows}
            // style={{ borderCollapse: "collapse" }}
            // className="react-data-table"
            customStyles={customStyles}
            // customHeader={<CustomHeader />}

            // npm i react-data-table-component-footer
        />
                                </CardBody>
                            </Card>
            </Col>
          </Row>
        </Container>
        {/* <Modal show={isLoadingModal} backdrop="static" keyboard={false}>
      <Modal.Body className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="primary" />
        <span className="ml-2">Loading...</span>
      </Modal.Body>
    </Modal> */}
    <Modal
                isOpen={isLoadingModal}
                toggle={() => {
                  setIsLoadingModal();
                }}
                backdrop={'static'}
                id="staticBackdrop"
                centered
                size='xs'
            >

                <ModalBody className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
        <span className="ml-2">Loading...</span>
                </ModalBody>
            </Modal>

            <Modal isOpen={isModalOpen} toggle={handleCancel}>
        <ModalHeader toggle={handleCancel}>Confirm</ModalHeader>
        <ModalBody>
          Are you sure you want {actionObj[selectedOption]} for the selected items?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirm}>
            Yes
          </Button>
          <Button color="secondary" onClick={handleCancel}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      </div>

    </React.Fragment>
  );
};

export default RTP; 