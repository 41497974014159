import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Card, CardBody, Col, Container, Row, Button, Modal, ModalBody, ModalHeader, ModalFooter, Input, Label, Alert, Table
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from 'react-data-table-component';
import { useQuery, gql } from '@apollo/client';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import moment from 'moment';

const USERS_QUERY = gql`
  query GetUsersWithAppointments($serviceProviderId: Int!) {
    appointments(
      where: {
        appointment_to_staff_to_service_relationship: {
          staff_to_service_relationship: { service: { service_provider_id: { _eq: $serviceProviderId } } }
        }
      }
    ) {
      id
      start_time
      status
      customer_user_id
      user {
        first_name
        last_name
        email
        phone_number
        address_city
      }
      appointment_to_staff_to_service_relationship {
        staff_to_service_relationship {
          service {
            name
          }
        }
      }
    }
  }
`;

const UserAppointments = () => {
  const { t } = useTranslation();
  const serviceProviderId = parseInt(localStorage.getItem("opID"), 10);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUserAppointments, setSelectedUserAppointments] = useState([]);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { loading, data, refetch } = useQuery(USERS_QUERY, {
    variables: { serviceProviderId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log(data)
      if (data && data.appointments) {
        // Group appointments by user
        const users = data.appointments.reduce((acc, appointment) => {
          const userId = appointment.customer_user_id;
          if (!userId) return acc;

          if (!acc[userId]) {
            acc[userId] = { ...appointment.user, appointments: [] };
          }
          acc[userId].appointments.push({
            id: appointment.id,
            start_time: appointment.start_time,
            status: appointment.status,
            services: appointment.appointment_to_staff_to_service_relationship.map(rel => rel.staff_to_service_relationship.service.name).join(", "),
          });
          return acc;
        }, {});

        setFilteredUsers(Object.values(users));
      }
    },
  });

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = Object.values(filteredUsers).filter(user => 
      (user.first_name + " " + user.last_name).toLowerCase().includes(value) ||
      user.email.toLowerCase().includes(value) ||
      user.phone_number.includes(value)
    );
    setFilteredUsers(filtered);
  };

  const handleUserClick = (user) => {
    const userAppointments = user.appointments
      .map((appointment) => ({
        date: moment(appointment.start_time).format("MMMM Do YYYY, h:mm A"),
        status: appointment.status,
        services: appointment.services,
      }))
      .sort((a, b) => new Date(b.start_time) - new Date(a.start_time));

    setSelectedUserAppointments(userAppointments);
    setUserModalOpen(true);
  };

  const columns = [
    {
      name: t("Name"),
      selector: (user) => `${user.first_name} ${user.last_name}`,
      sortable: true,
      cell: (user) => (
        <Button color="link" onClick={() => handleUserClick(user)}>
          {user.first_name} {user.last_name}
        </Button>
      ),
    },
    {
      name: t("Email"),
      selector: (user) => user.email,
      sortable: true,
    },
    {
      name: t("Phone"),
      selector: (user) => user.phone_number,
      sortable: true,
    },
    {
      name: t("City"),
      selector: (user) => user.address_city || 'N/A',
      sortable: true,
    },
    {
      name: t("Total Appointments"),
      selector: (user) => user.appointments.length,
      sortable: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Users with Appointments")} pageTitle={t("Home")} />
          <Card>
            <CardBody>
              <Row className="g-4 mb-3">
                <Col md="6">
                  <div className="d-flex justify-content-md-end">
                    <div className="search-box" style={{ "width": "300px" }}>
                      <input 
                        type="text" 
                        className="form-control search" 
                        placeholder={t("Search...")} 
                        value={searchTerm} 
                        onChange={handleSearch} 
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </Col>
              </Row>
              <DataTable
                columns={columns}
                data={filteredUsers}
                progressPending={loading}
                pagination
                highlightOnHover
              />
            </CardBody>
          </Card>
        </Container>

        {/* User Appointments Modal */}
        {selectedUserAppointments && (
          <Modal isOpen={userModalOpen} toggle={() => setUserModalOpen(!userModalOpen)}>
            <ModalHeader toggle={() => setUserModalOpen(!userModalOpen)}>
              {t("User Appointments")}
            </ModalHeader>
            <ModalBody>
              <Table>
                <thead>
                  <tr>
                    <th>{t("Service(s)")}</th>
                    <th>{t("Date")}</th>
                    <th>{t("Status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedUserAppointments.map((appointment, index) => (
                    <tr key={index}>
                      <td>{appointment.services}</td>
                      <td>{appointment.date}</td>
                      <td>{appointment.status}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setUserModalOpen(false)}>
                {t("Close")}
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};

export default UserAppointments;