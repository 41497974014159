import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationENG from "./locales/en.json";
import translationGr from "./locales/gr.json";
import translationDe from "./locales/de.json";
import translationIT from "./locales/it.json";
import translationAL from "./locales/al.json";
import translationBA from "./locales/ba.json";
import translationRS from "./locales/ru.json";
import translationSP from "./locales/sp.json";
import translationTR from "./locales/tr.json";
// import translationCN from "./locales/ch.json";
// import translationFR from "./locales/fr.json";
// import translationAR from "./locales/ar.json";


// the translations
const resources = {
  en: {
    translation: translationENG,
  },
  gr: {
    translation: translationGr,
  },
  de: {
    translation: translationDe,
  },
  it: {
    translation: translationIT,
  },
  al: {
    translation: translationAL,
  },
  ba: {
    translation: translationBA,
  },
  rs: {
    translation: translationRS,
  },
  sp: {
    translation: translationSP,
  },
  tr: {
    translation: translationTR,
  },
  // cn: {
  //   translation: translationCN,
  // },
  // fr: {
  //   translation: translationFR,
  // },
  // ar: {
  //   translation: translationAR,
  // },
};

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "en");
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
