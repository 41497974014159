import React from 'react';

import { request } from 'graphql-request';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// import {  gql } from "@apollo/client";
import { useQuery,useMutation, gql } from '@apollo/client';


const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // Replace with your actual token
  const token = localStorage.getItem("token");

  // Add custom headers
  return {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem("token"),
    },
  };
});
// Initialize Apollo Client with custom headers
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // cache: 'no-cache'
  cache: new InMemoryCache(),
});

async function getParentOperator(id) {
  const query =  gql`
  query GetParentOperator($id: Int!) {
    operators_by_pk(id: $id) {
      parent_operator {
        id
        username
      }
    }
  }
  `;
  const operatorsResponse = await client.query({
    query: query,
    variables: { "id": id },
  }); 
  // const variables = { id };
  // const response = await request(endpoint, query, variables);

  return operatorsResponse.data.operators_by_pk ? operatorsResponse.data.operators_by_pk.parent_operator : null;
}

export async function findAncestorOperators(id, forefatherId) {
  const ancestors = [];
  let current = await getParentOperator(id);

  while (current && current.id !== forefatherId) {
    ancestors.push(current);
    current = await getParentOperator(current.id);
  }

  if (current) {
    ancestors.push(current);
  }

  return await ancestors.reverse();
}

  // const getCurrentToken() = getCurrentToken()
  export async function getAllDescendants(currentUsername){
    let tmp=[]
    var query = `query MyQuery($_eq: String!) {
        operators(where: {username: {_eq: $_eq}, is_deleted: {_eq: false}}) {
          descendants
        }
      }`;

    const token = localStorage.getItem("token")
    // const currentUsername=localStorage.getItem("username")
    const GraphQLrequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'authorization': localStorage.getItem("token") },
      body: JSON.stringify({
        query,
        variables:{_eq:currentUsername}

      })
    };
    const GraphQLresponse = await fetch(process.env.REACT_APP_API_URL, GraphQLrequestOptions)
    if(GraphQLresponse.status!=200){
        alert("Could not fetch data", GraphQLresponse.statusText)
        return
      }
    
      const GraphQ_json = await GraphQLresponse.json();

      if(GraphQ_json.data!=undefined){
        const GraphQl_data = GraphQ_json.data.operators[0].descendants
        return await GraphQl_data
      }else{
        return []
      }
       
    }

    export async function getOperatorID(currentUsername){
        console.log("empikaa")
        let tmp=[]
        var query = `query MyQuery($_eq: String!) {
          operators(
            where: {username: {_eq: $_eq}}
          ) {
            id
          }
        }`;
        // export async function getOperatorID(currentUsername){
        //   console.log("empikaa")
        //   let tmp=[]
        //   var query = `query MyQuery($_eq: String!) {
        //     operators(
        //       where: {username: {_eq: $_eq}, is_deleted: {_eq: false}, is_enabled: {_eq: true}}
        //     ) {
        //       id
              
  
        //     }
        //   }`;
    
        const token = localStorage.getItem("token")
        // const currentUsername=localStorage.getItem("username")
        const GraphQLrequestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'authorization': localStorage.getItem("token") },
          body: JSON.stringify({
            query,
            variables:{_eq:currentUsername}
    
          })
        };
        
        const GraphQLresponse = await fetch(process.env.REACT_APP_API_URL, GraphQLrequestOptions)
        if(GraphQLresponse.status!=200){
            alert("Could not fetch data", GraphQLresponse.statusText)
            return
          }
        
          const GraphQ_json = await GraphQLresponse.json();
          console.log("GraphQl_dataGetID",GraphQ_json)
    
          if(GraphQ_json.data!=undefined){
            const GraphQl_data = GraphQ_json.data.operators[0]
            // console.log("GraphQl_data",GraphQ_json)
            return await GraphQl_data
          }else{
            return []
          }
           
        }

        export async function getProviderID(currentUsername){
          console.log("empikaa")
          let tmp=[]
          var query = `query MyQuery($_eq: String!) {
            service_providers(
              where: {username: {_eq: $_eq}}
            ) {
              id
            }
          }`;
          // export async function getOperatorID(currentUsername){
          //   console.log("empikaa")
          //   let tmp=[]
          //   var query = `query MyQuery($_eq: String!) {
          //     operators(
          //       where: {username: {_eq: $_eq}, is_deleted: {_eq: false}, is_enabled: {_eq: true}}
          //     ) {
          //       id
                
    
          //     }
          //   }`;
      
          const token = localStorage.getItem("token")
          // const currentUsername=localStorage.getItem("username")
          const GraphQLrequestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authorization': localStorage.getItem("token") },
            body: JSON.stringify({
              query,
              variables:{_eq:currentUsername}
      
            })
          };
          
          const GraphQLresponse = await fetch(process.env.REACT_APP_API_URL, GraphQLrequestOptions)
          if(GraphQLresponse.status!=200){
              alert("Could not fetch data", GraphQLresponse.statusText)
              return
            }
          
            const GraphQ_json = await GraphQLresponse.json();
            console.log("GraphQl_dataGetID",GraphQ_json)
      
            if(GraphQ_json.data!=undefined){
              const GraphQl_data = GraphQ_json.data.service_providers[0]
              // console.log("GraphQl_data",GraphQ_json)
              return await GraphQl_data
            }else{
              return []
            }
             
          }
// Import the required packages and GraphQL client
// Import the required packages and GraphQL client
const token=localStorage.getItem("token")
export const getCredits = async (username, type) => {
  const query = `
    query MyQuery($_eq: String = "") {
      ${type}s(where: {username: {_eq: $_eq}, is_deleted: {_eq: false}}) {
        credits
      }
    }
  `;

  const variables = {
    _eq: username,
  };

  try {
    const GraphQLresponse = await fetch(
      process.env.REACT_APP_API_URL,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: localStorage.getItem("token") },
        body: JSON.stringify({
          query,
          variables,
        }),
      }
    );
    if(GraphQLresponse.status!=200){
      alert("Could not fetch data", GraphQLresponse.statusText)
      return
    }
  
    const GraphQ_json = await GraphQLresponse.json();

    if(GraphQ_json.data!=undefined&&GraphQ_json.data[`${type}s`][0]!=undefined){
      const GraphQl_data = GraphQ_json.data[`${type}s`][0].credits;
      return await GraphQl_data
    }else{
      return null
    }
    // return response[`${type}s`][0].credits;
  } catch (error) {
    console.error('Error getting credits:', error);
    return null;
  }
};

export const getUsernameByID = async (id, type) => {
  const query = gql`
query MyQuery($id: Int !) {
  ${type}s_by_pk(id: $id) {
    username
  }
}
`;
const operatorsResponse = await client.query({
  query: query,
  variables: { "id": id },
}); 
// const variables = { id };
// const response = await request(endpoint, query, variables);

return operatorsResponse.data[type+"s_by_pk"] ? operatorsResponse.data[type+"s_by_pk"].username : null;

};

export const getLimitsByID = async (id) => {
  const query = gql`
  query MyQuery($id: Int!) {
    shops_by_pk(id: $id) {
      gp_cur
    }
  }
`;
const operatorsResponse = await client.query({
  query: query,
  variables: { "id": id },
}); 
// const variables = { id };
// const response = await request(endpoint, query, variables);
const limitResponse = await client.query({
  query:  gql`
  query MyQuery($_eq: String!) {
    credit_limit_alerts(where: {currency: {_eq: $_eq}}) {
      operator_credit_limit
      shop_credit_limit
    }
  }  
`,
  variables: { "_eq":  operatorsResponse.data["shops_by_pk"].gp_cur },
}); 

return limitResponse.data["credit_limit_alerts"][0]!=undefined ? limitResponse.data["credit_limit_alerts"][0]  : null;

};

export const getLimitsByIDop = async () => {

const limitResponse = await client.query({
  query:  gql`
  query MyQuery($_eq: String!) {
    credit_limit_alerts(where: {currency: {_eq: $_eq}}) {
      operator_credit_limit
    }
  }  
`,
  variables: { "_eq":  "ZAR" },
}); 

return limitResponse.data["credit_limit_alerts"][0]!=undefined ? limitResponse.data["credit_limit_alerts"][0]  : null;

};


export const getUserLimits = async (username, type) => {
  const query = `
    query MyQuery($_eq: String = "") {
      ${type}s(where: {username: {_eq: $_eq}, is_deleted: {_eq: false}}) {
        new_user_limit
      }
    }
  `;

  const variables = {
    _eq: username,
  };

  try {
    const GraphQLresponse = await fetch(
      process.env.REACT_APP_API_URL,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: localStorage.getItem("token") },
        body: JSON.stringify({
          query,
          variables,
        }),
      }
    );
    if(GraphQLresponse.status!=200){
      alert("Could not fetch data", GraphQLresponse.statusText)
      return
    }
  
    const GraphQ_json = await GraphQLresponse.json();

    if(GraphQ_json.data!=undefined&&GraphQ_json.data[`${type}s`][0]!=undefined){
      const GraphQl_data = GraphQ_json.data[`${type}s`][0].new_user_limit;
      return await GraphQl_data
    }else{
      return null
    }
  } catch (error) {
    console.error('Error getting credits:', error);
    return null;
  }
};

export const getId = async (username, type) => {
  const query = `
    query MyQuery($_eq: String = "") {
      ${type}s(where: {username: {_eq: $_eq}, is_deleted: {_eq: false}}) {
        id
      }
    }
  `;

  const variables = {
    _eq: await username.toLowerCase(),
  };

  try {
    const GraphQLresponse = await fetch(
      process.env.REACT_APP_API_URL,
      {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: localStorage.getItem("token") },
        body: JSON.stringify({
          query,
          variables,
        }),
      }
    );
    if(GraphQLresponse.status!=200){
      alert("Could not fetch data", GraphQLresponse.statusText)
      return
    }
  
    const GraphQ_json = await GraphQLresponse.json();
    if(GraphQ_json.data!=undefined&&GraphQ_json.data[`${type}s`][0]!=undefined){
      const GraphQl_data = GraphQ_json.data[`${type}s`][0].id;
      
      return await GraphQl_data
    }else{
      return null
    }
    // return response[`${type}s`][0].credits;
  } catch (error) {
    console.error('Error getting credits:', error);
    return null;
  }
};

// const mutation = `
// mutation MyMutation($from_username: String = "", $is_out: Boolean = false, $to_type: String = "", $from_type: String = "",$to_username: String = "", $amount: Int = 10,  $updatedFromCredits: Int = 0, $updatedToCredits: Int = 0) {
//   update_${fromType}s(
//     where: {username: {_eq: $from_username}}
//     _set: {credits: $updatedFromCredits}
//   ) {
//     affected_rows
//   }
//   insert_transactions(
//     objects: {amount: $amount, from_username: $from_username, is_out: $is_out, to_type: $to_type,  from_type: $from_type, to_username: $to_username}
//   ) {
//     affected_rows
//   }
//   update_${toType}s(
//     where: {username: {_eq: $to_username}}
//     _set: {credits: $updatedToCredits}
//   ) {
//     affected_rows
//   }
// }
// `;


export async function createTransaction (fromUsername, fromType, toUsername, toType, amount,description) {
  // Get the current credits of the "from" and "to" entities


  const fromId=await getId(fromUsername, fromType);
  const toId=await getId(toUsername, toType)
  const fromCredits = await getCredits(fromUsername, fromType);
  const toCredits = await getCredits(toUsername, toType);
  if (fromCredits === null || toCredits === null ) {
    console.error('Error: Invalid username or type provided.');
    return;
  }
  const shopId=fromType==="shop"?fromId:toId
  const limitResult = await getLimitsByID(shopId)
  // console.log("limitResult",limitResult)



  // Update the credits
  const updatedFromCredits = parseInt(fromCredits) - parseInt(amount)*100;
  const updatedToCredits = parseInt(toCredits) + parseInt(amount)*(100);


  
  if(updatedFromCredits<0){
    return await "ERROR: Not enough credits"
  }

  if(limitResult!==null&&fromType ==='shop'&&updatedFromCredits<parseInt(limitResult.shop_credit_limit)&&parseInt(fromCredits)>=parseInt(limitResult.shop_credit_limit)){
    console.log("EPESAME PU TA CREDITS")
  }
  if(limitResult!==null&&fromType ==='operator'&&updatedFromCredits<parseInt(limitResult.operator_credit_limit)&&parseInt(fromCredits)>=parseInt(limitResult.operator_credit_limit)){
    console.log("EPESAME PU TA CREDITS")
  }
console.log("the transaction:",
{
  token: localStorage.getItem("token"),
  from_type: fromType,
  from_id: parseInt(fromId),
  from_username: fromUsername,
  to_type: toType,
  to_id: parseInt(toId),
  to_username: toUsername,
  amount: parseInt(amount)*(100),
  description: "",
  is_out: (fromType ==='user'  && toType ==='shop' ) || (fromType ==='shop'  && toType === 'operator'),
})
const token = localStorage.getItem("token")

  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/api/frontend/create-transaction/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          from_type: fromType,
          from_id: parseInt(fromId),
          from_username: fromUsername,
          to_type: toType,
          to_id: parseInt(toId),
          to_username: toUsername,
          amount: parseInt(amount)*(100),
          description: ".",
          is_out: (fromType ==='user'  && toType ==='shop' ) || (fromType ==='shop'  && toType === 'operator'),
        }),
      }
    );
  
    if (!response.ok) {
      console.log("response", response);
  
      throw new Error("Failed to make transaction");
    }

  } catch (error) {
    console.error(error);
    alert(error);
  }
};

export async function createTransactionOld (fromUsername, fromType, toUsername, toType, amount,description) {
  // Get the current credits of the "from" and "to" entities


  const fromId=await getId(fromUsername, fromType);
  const toId=await getId(toUsername, toType)
  const fromCredits = await getCredits(fromUsername, fromType);
  const toCredits = await getCredits(toUsername, toType);
  if (fromCredits === null || toCredits === null ) {
    console.error('Error: Invalid username or type provided.');
    return;
  }
  const shopId=fromType==="shop"?fromId:toId
  const limitResult = await getLimitsByID(shopId)
  console.log("limitResult",limitResult)
  // const fromLimit = await getLimitsByID(fromId,fromType)
  // const toLimit = await getLimitsByID(toId,toType)


  // Update the credits
  const updatedFromCredits = parseInt(fromCredits) - parseInt(amount)*100;
  const updatedToCredits = parseInt(toCredits) + parseInt(amount)*(100);


  
  if(updatedFromCredits<0){
    return await "ERROR: Not enough credits"
  }

  if(limitResult!==null&&fromType ==='shop'&&updatedFromCredits<parseInt(limitResult.shop_credit_limit)&&parseInt(fromCredits)>=parseInt(limitResult.shop_credit_limit)){
    console.log("EPESAME PU TA CREDITS")
  }
  if(limitResult!==null&&fromType ==='operator'&&updatedFromCredits<parseInt(limitResult.operator_credit_limit)&&parseInt(fromCredits)>=parseInt(limitResult.operator_credit_limit)){
    console.log("EPESAME PU TA CREDITS")
  }
  const variables = {
    from_id:fromId,
    from_username: fromUsername,
    from_type: fromType,
    to_username: toUsername,
    to_id:toId,
    to_type: toType,
    
    
    amount: parseInt(amount)*(100),
    description:description,
    // fromType: fromType,
    // toType: toType,
    updatedFromCredits: updatedFromCredits,
    updatedToCredits: updatedToCredits,
    is_out: (fromType ==='user'  && toType ==='shop' ) || (fromType ==='shop'  && toType === 'operator'),
  };
  // Define the GraphQL mutation
  const mutation = `
  mutation MyMutation($from_username: String = "", $is_out: Boolean = false, $to_type: String = "", $to_username: String = "", $amount: Int = 10, $updatedFromCredits: Int = 0, $updatedToCredits: Int = 0, $from_type: String = "", $description: String = "", $to_id: Int = 10, $from_id: Int = 10) {
    update_${fromType}s(
        where: {username: {_eq: $from_username}, is_deleted: {_eq: false}}
        _set: {credits: $updatedFromCredits}
        ${variables.is_out?" _inc: {start_credit: "+(-variables.amount)+"}":""}

      ) {
        affected_rows
      }
      insert_transactions(
        objects: {amount: $amount, from_username: $from_username, is_out: $is_out, to_type: $to_type, to_username: $to_username, description: $description, to_id: $to_id, from_type: $from_type, from_id: $from_id}
      ) {
        affected_rows
      }
      update_${toType}s(
        where: {username: {_eq: $to_username}, is_deleted: {_eq: false}}
        _set: {credits: $updatedToCredits}
        ${!variables.is_out?" _inc: {start_credit: "+(variables.amount)+"}":""}
      ) {
        affected_rows
      }
    }
  `;

  // Define the variables for the mutation



  try {
    const GraphQLresponse = await fetch(process.env.REACT_APP_API_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("token") },
      body: JSON.stringify({
        query: mutation,
        variables
      })
    });    
    if(GraphQLresponse.status!=200){
      alert("Could not fetch data", GraphQLresponse.statusText)
      return
    }
  
    const GraphQ_json = await GraphQLresponse.json();
  } catch (error) {
  }
};




export async function createTransactionOpOpOld (fromUsername, fromType, toUsername, toType, amount,description,isOut) {
  // Get the current credits of the "from" and "to" entities
  const fromId=await getId(fromUsername, fromType);
  const toId=await getId(toUsername, toType)
  const fromCredits = await getCredits(fromUsername, fromType);
  const toCredits = await getCredits(toUsername, toType);
  if (fromCredits === null || toCredits === null ) {
    // alert('Error: Invalid username or type provided.');
    return 'ERROR: Invalid username or type provided.';
  }
  const limitResult = await getLimitsByIDop()
  // Update the credits
  const updatedFromCredits = parseInt(fromCredits) - parseInt(amount)*100;
  const updatedToCredits = parseInt(toCredits) + parseInt(amount)*(100);
  if(updatedFromCredits<0){
    return await "ERROR: Not enough credits"
  }
  if(limitResult!==null&&updatedFromCredits<parseInt(limitResult.operator_credit_limit)&&parseInt(fromCredits)>=parseInt(limitResult.operator_credit_limit)){
    console.log("EPESAME PU TA CREDITS")
  }
  // Define the GraphQL mutation
  const mutation = `
  mutation MyMutation($from_username: String = "", $is_out: Boolean = false, $to_type: String = "", $to_username: String = "", $amount: Int = 10, $updatedFromCredits: Int = 0, $updatedToCredits: Int = 0, $from_type: String = "", $description: String = "", $to_id: Int = 10, $from_id: Int = 10) {
    update_sender: update_operators(
      where: {username: {_eq: $from_username}, is_deleted: {_eq: false}}
      _set: {credits: $updatedFromCredits}
      ${isOut?" _inc: {start_credit: "+(-parseInt(amount)*(100))+"}":""}
    ) {
      affected_rows
    }
    insert_transactions(
      objects: {amount: $amount, from_username: $from_username, is_out: $is_out, to_type: $to_type, to_username: $to_username, description: $description, to_id: $to_id, from_type: $from_type, from_id: $from_id}
    ) {
      affected_rows
    }
    update_recipient: update_operators(
      where: {username: {_eq: $to_username}, is_deleted: {_eq: false}}
      _set: {credits: $updatedToCredits}
      ${!isOut?" _inc: {start_credit: "+(parseInt(amount)*(100))+"}":""}
    ) {
      affected_rows
    }
  }
  
  `;

  // Define the variables for the mutation
  const variables = {
    from_id:fromId,
    from_username: fromUsername,
    from_type: fromType,
    to_username: toUsername,
    to_id:toId,
    to_type: toType,
    
    
    amount: parseInt(amount)*(100),
    description:description,
    // fromType: fromType,
    // toType: toType,
    updatedFromCredits: updatedFromCredits,
    updatedToCredits: updatedToCredits,
    is_out: isOut,
  };


  try {
    const GraphQLresponse = await fetch(process.env.REACT_APP_API_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("token") },
      body: JSON.stringify({
        query: mutation,
        variables
      })
    });    
    if(GraphQLresponse.status!=200){
      alert("Could not fetch data", GraphQLresponse.statusText)
      return
    }
  
    const GraphQ_json = await GraphQLresponse.json();
  } catch (error) {
    console.error('Error creating transaction:', error);
  }
};

export async function createTransactionOpOp (fromUsername, fromType, toUsername, toType, amount,description,isOut) {
  // Get the current credits of the "from" and "to" entities
  const fromId=await getId(fromUsername, fromType);
  const toId=await getId(toUsername, toType)
  const fromCredits = await getCredits(fromUsername, fromType);
  const toCredits = await getCredits(toUsername, toType);
  if (fromCredits === null || toCredits === null ) {
    // alert('Error: Invalid username or type provided.');
    return 'ERROR: Invalid username or type provided.';
  }
  const limitResult = await getLimitsByIDop()
  // Update the credits
  const updatedFromCredits = parseInt(fromCredits) - parseInt(amount)*100;
  const updatedToCredits = parseInt(toCredits) + parseInt(amount)*(100);
  if(updatedFromCredits<0){
    return await "ERROR: Not enough credits"
  }
  if(limitResult!==null&&updatedFromCredits<parseInt(limitResult.operator_credit_limit)&&parseInt(fromCredits)>=parseInt(limitResult.operator_credit_limit)){
    console.log("EPESAME PU TA CREDITS")
  }
  // Define the GraphQL mutation

const token = localStorage.getItem("token")

  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/api/frontend/create-transaction/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          from_type: fromType,
          from_id: parseInt(fromId),
          from_username: fromUsername,
          to_type: toType,
          to_id: parseInt(toId),
          to_username: toUsername,
          amount: parseInt(amount)*(100),
          description: ".",
          is_out: isOut,
        }),
      }
    );
  
    if (!response.ok) {
      console.log("response", response);
  
      throw new Error("Failed to make transaction");
    }

  } catch (error) {
    console.error(error);
    alert(error);
  }
};

    export async function getDescendantsUsernames(descendants){
        let tmp=[]
        var query = `query MyQuery($_in: [Int!]!) {
          operators(where: {id: {_in: $_in}, is_deleted: {_eq: false}}) {
            id
            username
          }
        }
        
          `;
    
        const token = localStorage.getItem("token")
        // const currentUsername=localStorage.getItem("username")
        const GraphQLrequestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'authorization': localStorage.getItem("token") },
          body: JSON.stringify({
            query,
            variables:{_in:descendants}
    
          })
        };
        
        const GraphQLresponse = await fetch(process.env.REACT_APP_API_URL, GraphQLrequestOptions)
        if(GraphQLresponse.status!=200){
            alert("Could not fetch data", GraphQLresponse.statusText)
            return
          }
        
          const GraphQ_json = await GraphQLresponse.json();
    
          if(GraphQ_json.data!=undefined){
            const GraphQl_data = GraphQ_json.data.operators
            
            return await GraphQl_data
          }else{
            return []
          }
          
    
    
    
    
            
        }
        const GET_DESCENDANTS_QUERY = gql`
        query GetDescendants($id: Int!) {
          operators_by_pk(id: $id) {
            descendants
          }
        }
      `;
        // export async function checkRender(operatorID){

        // }

        export const isDescendant = async (operatorId, descendantId) => {
          if (operatorId === descendantId) {
            return true;
          }
        
          try {
            const { data } = await client.query({
              query: GET_DESCENDANTS_QUERY,
              variables: { id: operatorId },
            });
        
            return data?.operators_by_pk?.descendants?.includes(descendantId) || false;
          } catch (error) {
            return false;
          }
        };

        export function getTransactionSummaries(transactions, timestamp) {
            const filteredTransactions = transactions.filter(
              (transaction) => transaction.created_at >= timestamp
            );
          
            const summary = {
              IN: 0,
              OUT: 0,
            };
            filteredTransactions.forEach((transaction) => {
              if (
                (transaction.is_out && transaction.to_type === 'user') ||
                (transaction.is_out && transaction.from_type === 'user')
              ) {
                summary.OUT += transaction.amount;
              } else if (
                (!transaction.is_out && transaction.to_type === 'user') ||
                (!transaction.is_out && transaction.from_type === 'user')
              ) {
                summary.IN += transaction.amount;
              }
            });
          
            return summary;
          }
          export function getStartOfMonth() {
            const today = new Date();
            const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const isoString = startOfMonth.toISOString();
            const startOfMonthIsoString = isoString.slice(0, 11) + "00:00:00.000000+00:00";
            return startOfMonthIsoString;
          }

          const GET_ALLOW_CREATEOP_QUERY = gql`
          query MyQuery($id: Int!) {
            operators_by_pk(id: $id) {
              permissions
            }
          }
        `;
          export async function isAllowedToCreateOperator(operatorId) {
            try {
              const { data } = await client.query({
                query: GET_ALLOW_CREATEOP_QUERY,
                variables: { id: operatorId },
              });
              const parsedObjectOP = JSON.parse(data.operators_by_pk.permissions);
              const arrayOfObjectsOP = Object.values(parsedObjectOP);
              return (arrayOfObjectsOP[arrayOfObjectsOP.findIndex((item) => item.key === "Allow Create Operators")].value)

              // return data?.operators_by_pk?.descendants?.includes(descendantId) || false;
            } catch (error) {
              return false;
            }
          }
          const MUTATION_LOG = gql`
          mutation MyMutation($affected_id: Int = 10, $affected_type: String = "", $description: String = "", $is_success: Boolean = false) {
            insert_logs(
              objects: {affected_id: $affected_id, affected_type: $affected_type, description: $description, is_success: $is_success}
            ) {
              affected_rows
            }
          }
          `;


          export const useCreateLog = () => {
            const [enableUserMutation, { loading, error }] = useMutation(MUTATION_LOG);
          
            const createLog = async (affected_id, affected_type, description, is_success) => {
              try {
                const result = await enableUserMutation({ variables: { affected_id, affected_type, description, is_success }});
              } catch (error) {
                console.error(error);
              }
            }
          
            return { createLog, loading, error };
          };

          export async function prepareLogArray(logs) {
            const promises = logs.map(async log => {
              const username = await getUsernameByID(log.belongs_to_id, log.belongs_to_type);
              return {
                fromEntity: `${log.belongs_to_type} ${username}`,
                time: log.created_at,
                description: log.description,
                success: log.is_success ? "successfully" : "failed",
              };
            });
          
            const result = await Promise.all(promises);
            return result;
          }
          
          

          export async function createLimitTransaction (fromUsername, fromType, toUsername, toType, amount,description) {
            // Get the current credits of the "from" and "to" entities

            const fromId=await getId(fromUsername, fromType);
            const toId=await getId(toUsername, toType)
            const fromCredits = await getUserLimits(fromUsername, fromType);
            const toCredits = await getUserLimits(toUsername, toType);
            if (fromCredits === null || toCredits === null ) {
              console.error('Error: Invalid username or type provided.');
              return;
            }
          
            // Update the credits
            const updatedFromCredits = parseInt(fromCredits) - parseInt(amount);
            const updatedToCredits = parseInt(toCredits) + parseInt(amount);
            if(updatedFromCredits<0){
              return await "ERROR: Not enough credits"
            }
            const variables = {
              from_id:fromId,
              // from_username: fromUsername,
              // from_type: fromType,
              // to_username: toUsername,
              to_id:toId,
              // to_type: toType,
              
              
              // amount: parseInt(amount),
              // description:description,
              // fromType: fromType,
              // toType: toType,
              updatedFromCredits: updatedFromCredits,
              updatedToCredits: updatedToCredits,
              // is_out: (fromType ==='user'  && toType ==='shop' ) || (fromType ==='shop'  && toType === 'operator'),
            };
            // Define the GraphQL mutation
            const mutation = `
            mutation MyMutation(     $updatedFromCredits: Int = 0, $updatedToCredits: Int = 0,  $to_id: Int = 10, $from_id: Int = 10) {
              update_${fromType}s(
                  where: {id: {_eq: $from_id}}
                  _set: {new_user_limit: $updatedFromCredits}
                  
          
                ) {
                  affected_rows
                }
                update_${toType}s(
                  where: {id: {_eq: $to_id}}
                  _set: {new_user_limit: $updatedToCredits}
                 
                ) {
                  affected_rows
                }
              }
            `;

          
            try {
              const GraphQLresponse = await fetch(process.env.REACT_APP_API_URL, {
                method: 'POST',
                headers: { "Content-Type": "application/json", authorization: localStorage.getItem("token") },
                body: JSON.stringify({
                  query: mutation,
                  variables
                })
              });    
              if(GraphQLresponse.status!=200){
                alert("Could not fetch data", GraphQLresponse.statusText)
                return
              }
            
              const GraphQ_json = await GraphQLresponse.json();
            } catch (error) {
            }
          };

          export async function createLimitTransactionOPOP (fromUsername, fromType, toUsername, toType, amount,description) {

          
            const fromId=await getId(fromUsername, fromType);
            const toId=await getId(toUsername, toType)
            const fromCredits = await getUserLimits(fromUsername, fromType);
            const toCredits = await getUserLimits(toUsername, toType);
            if (fromCredits === null || toCredits === null ) {
              console.error('Error: Invalid username or type provided.');
              return;
            }
          
            // Update the credits
            const updatedFromCredits = parseInt(fromCredits) - parseInt(amount);
            const updatedToCredits = parseInt(toCredits) + parseInt(amount);
            if(updatedFromCredits<0){
              return await "ERROR: Not enough credits"
            }
            const variables = {
              from_id:fromId,
              // from_username: fromUsername,
              // from_type: fromType,
              // to_username: toUsername,
              to_id:toId,
              // to_type: toType,
              
              
              // amount: parseInt(amount),
              // description:description,
              // fromType: fromType,
              // toType: toType,
              updatedFromCredits: updatedFromCredits,
              updatedToCredits: updatedToCredits,
              // is_out: (fromType ==='user'  && toType ==='shop' ) || (fromType ==='shop'  && toType === 'operator'),
            };
            // Define the GraphQL mutation
            const mutation = `
            mutation MyMutation(     $updatedFromCredits: Int = 0, $updatedToCredits: Int = 0,  $to_id: Int = 10, $from_id: Int = 10) {
              update_sender: update_operators(
                where: {id: {_eq: $from_id}}
                  _set: {new_user_limit: $updatedFromCredits}
                  
          
                ) {
                  affected_rows
                }
                update_recipient: update_operators(
                  where: {id: {_eq: $to_id}}
                  _set: {new_user_limit: $updatedToCredits}
                 
                ) {
                  affected_rows
                }
              }
            `;
          
            // Define the variables for the mutation
          
          
          
            try {
              const GraphQLresponse = await fetch(process.env.REACT_APP_API_URL, {
                method: 'POST',
                headers: { "Content-Type": "application/json", authorization: localStorage.getItem("token") },
                body: JSON.stringify({
                  query: mutation,
                  variables
                })
              });    
              if(GraphQLresponse.status!=200){
                alert("Could not fetch data", GraphQLresponse.statusText)
                return
              }
            
              const GraphQ_json = await GraphQLresponse.json();
            } catch (error) {
            }
          };
          
          export function formatWithCommas(floatStr) {
            // Split the float string into integer and decimal parts
            let [integerPart, decimalPart] = floatStr.split('.');
          
            // Use a regular expression to format the integer part with commas
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          
            // Return the formatted number
            return `${integerPart}.${decimalPart}`;
          }
          export const lobbiesName={
            "go_to_spin":"Go To Spin",
            "go_hr_to_spin":"Spin City",
            "aviator_a":"Aviator A",
            "aviator_b":"Aviator B",
            "aviator_c":"Aviator C",
            "euroslot":"Euroslot",
            "casino_land":"Casino Land",
            "online":"online",
            "palm":"Palm Treasures",
            "palm_new":"Palm Treasures New",
            "island":"Island Treasures",
            "stargames":"Stargames"
          }

    export async function defaultFun(num){
    }

export default defaultFun