import React, { useEffect,useState } from 'react';
import { useTranslation } from "react-i18next";import { Card,CardHeader, CardBody, Col, Container, Input, Label, Row,Button,Modal, ModalBody, ModalHeader,ModalFooter, Spinner,ButtonGroup} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
// import { Modal, Spinner } from 'react-bootstrap';
import Select from "react-select";

import { Link } from 'react-router-dom';
import { useQuery,useMutation, gql } from '@apollo/client';
// import checkTokenValidity from '../../Layouts/Footer'
// import DataTable from 'react-data-table-component';
// import { DateRangePicker,createStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Switch, FormControlLabel } from '@mui/material';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel'
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import "rsuite/dist/rsuite.css";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
// Import required libraries
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
// password
// id
// is_enabled
// is_panic
// current_game
// gp_user_id
// credits
// bonus
// username

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {getDescendantsUsernames,getAllDescendants,formatWithCommas} from "../../Components/Common/functions"

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // Replace with your actual token
  const token = localStorage.getItem("token");

  // Add custom headers
  return {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem("token"),
    },
  };
});
// Initialize Apollo Client with custom headers
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
// id
// username
// parent_operator_id
const GET_OPERATORS = gql`
query MyQuery($id: Int!) {
  operators_by_pk(id: $id) {
    descendants
  }
}
`;

const GET_RELATED_SHOPS = gql`
query GetRelatedShops($operatorIds: [Int!], $_ilike: String = "%%") {
  shops(where: {operator_id: {_in: $operatorIds}, gp_cur: {_ilike: $_ilike}}) {
    id
    username
    operator_id
    operator {
      username
    }
    last_reset_date
    gp_cur
  }
}
`;

function exportData(data, format) {
  const fileName = 'exported_data';

  // Helper function to convert JSON to CSV
  function jsonToCsv(jsonData) {
    const header = Object.keys(jsonData[0]).join(',') + '\n';
    const rows = jsonData.map(row => Object.values(row).join(',')).join('\n');
    return header + rows;
  }

  // Export to clipboard
  function exportToClipboard(content) {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  // Export to CSV
  function exportToCsv(content) {
    const csvData = new Blob([content], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(csvData, fileName + '.csv');
  }

  // Export to Excel
  function exportToExcel(jsonData) {
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName + '.xlsx');
  }

  // Export to PDF
  function exportToPdf(jsonData) {
    const doc = new jsPDF('p', 'pt');
    doc.autoTable({
      head: [Object.keys(jsonData[0])],
      body: jsonData.map(obj => Object.values(obj)),
    });
    doc.save(fileName + '.pdf');
  }
  const csvContent = jsonToCsv(data);

  switch (format) {
    case 'clipboard':
      exportToClipboard(csvContent);
      break;
    case 'csv':
      exportToCsv(csvContent);
      break;
    case 'excel':
      exportToExcel(data);
      break;
    case 'pdf':
      // You can use libraries like jsPDF to generate PDF content from the CSV content.
      // For simplicity, I'm using the CSV content as is, but you may want to format it for PDF.
      exportToPdf(data);
      break;
    default:
      console.error('Unsupported format');
  }
}

// const GET_TRANSACTIONS_FOR_SHOP = gql`
//   query GetTransactionsForShop($shopId: Int!, $startDate: timestamptz!, $endDate: timestamptz!) {
//     transactions(where: { to_id: { _eq: $shopId }, created_at: { _gte: $startDate, _lte: $endDate } }) {
//       amount
//       created_at
//       from_id
//       from_type
//       from_username
//       is_out
//       to_id
//       to_type
//       to_username
//     }
//   }
// `;

// where: {
//   _or: [
//     { from_id: { _eq: $id }, from_type: { _eq: "shop" } }
//     { to_id: { _eq: $id }, to_type: { _eq: "shop" } }
//   ]
// }
// where: { to_id: { _eq: $shopId }}

// const GET_TRANSACTIONS_FOR_SHOP = gql`
//   query GetTransactionsForShop($shopId: Int!) {
//     transactions(where: {
//       _or: [
//         { from_id: { _eq: $shopId }, from_type: { _eq: "shop" }, to_type: { _eq: "user" } }
//         { to_id: { _eq: $shopId }, to_type: { _eq: "shop" }, from_type: { _eq: "user" } }
//       ]
//     }) {
//       amount
//       created_at
//       from_id
//       from_type
//       from_username
//       is_out
//       to_id
//       to_type
//       to_username
//     }
//   }
// `;
const GET_TRANSACTIONS_FOR_SHOP = gql`
query GetTransactionsForMultipleShops($shopIds: [Int!]!, $startDate: timestamptz!, $endDate: timestamptz!) {
  transactions(where: {
    _or: [
      { from_id: { _in: $shopIds }, from_type: { _eq: "shop" }, to_type: { _eq: "user" }, created_at: {_gte: $startDate, _lte: $endDate} }
      { to_id: { _in: $shopIds }, to_type: { _eq: "shop" }, from_type: { _eq: "user" }, created_at: {_gte: $startDate, _lte: $endDate} }
    ]
  }) {
    amount
    created_at
    from_id
    from_type
    from_username
    is_out
    to_id
    to_type
    to_username
    bonus
  }
}`;



function findDescendantOperators(operatorId, operators) {
  const descendants = operators.find(op => op.id === operatorId)?.descendants || [];
  return operators.filter(op => descendants.includes(op.id));
}

function findShopsForOperators(operatorIds, shops) {
  return shops.filter(shop => operatorIds.includes(shop.operator_id));
}

function filterTransactionsByDate(transactions, startDate, endDate) {
  return transactions.filter(transaction =>
    new Date(transaction.created_at) >= startDate &&
    new Date(transaction.created_at) <= endDate
  );
}


function calculateSummary(transactions, shops ,startDate,endDate) {
  const summary = {};

  for (const shop of shops) {
    summary[shop.id] = {
      shopId: shop.id,
      shopLastReset:shop.last_reset_date,
      operator:shop.operator.username,
      currency:shop.gp_cur,
      shops: shop.username,
      in: 0,
      out: 0,
      bonusin:0,
      bonusout:0,
      resetin:0,
      resetout:0
    };
  }

  for (const transaction of transactions) {
    //if transaction.created_at mes to range prosthese to sto in ou to sketto 
    //if transaction.created_at megalitero tou {t("Last Reset")} prosthese to sto lastreset
// if(transaction.created_at)
    if (transaction.from_type=="shop"&&summary[transaction.from_id]) {
      if(transaction.created_at>= startDate && transaction.created_at<= endDate){
        summary[transaction.from_id].in += transaction.amount;
      }
      if(transaction.created_at>= startDate && transaction.created_at<= endDate){
        summary[transaction.from_id].bonusin += transaction.bonus;
      }
      if(transaction.created_at>= summary[transaction.from_id].shopLastReset ){
        summary[transaction.from_id].resetin += transaction.amount;
      }    
    }
    if (transaction.to_type=="shop"&&summary[transaction.to_id]) {
      if(transaction.created_at>= startDate && transaction.created_at<= endDate){
        summary[transaction.to_id].out += transaction.amount;
      }
      if(transaction.created_at>= startDate && transaction.created_at<= endDate){
        summary[transaction.to_id].bonusout += transaction.bonus;
      }
      if(transaction.created_at>= summary[transaction.to_id].shopLastReset ){
        summary[transaction.to_id].resetout += transaction.amount;
      }    
    }
  }

  return Object.values(summary);
}



function formatDateToUTCISOString(dateInput) {
  const date = new Date(dateInput);
  const formattedDate = date.toISOString().replace('Z', '+00:00');;
  return formattedDate;
}

function processArray(array) {
  return array
    .filter(item => item.in > 0 || item.out > 0)
    .map(item => {
      const sum = item.in - item.out;
      const resetsum = item.resetin - item.resetout;
      const payout = item.in === 0 ? 0 : (item.out / item.in) * 100;

      return {
        ...item,
        sum,
        resetsum,
        payout,
      };
    });
}


function createSummary(array) {
  const summary = array.reduce(
    (acc, item) => {
      acc.in += item.in;
      acc.out += item.out;
      acc.sum += item.sum;
      acc.resetin += item.resetin;
      acc.resetout += item.resetout;
      acc.resetsum += item.resetsum;
      return acc;
    },
    {
      in: 0,
      out: 0,
      sum: 0,
      resetin: 0,
      resetout: 0,
      resetsum: 0,
    }
  );

  const payout = summary.in === 0 ? 0 : (summary.out / summary.in) * 100;

  return {
    shops: '',
    operator: '',
    currency: 'Total:',
    payout,
    ...summary,
  };
}

// async function getSummary(operatorId, startDate, endDate,currency) {
//   const operatorsResponse = await client.query({ query: GET_OPERATORS ,variables: { "id":operatorId }});
//   const descendantOperators = operatorsResponse.data.operators_by_pk.descendants;

//   // const descendantOperators = findDescendantOperators(operatorId, operators);
//   const operatorIds = [operatorId, ...descendantOperators];

//   const relatedShopsResponse = await client.query({
//     query: GET_RELATED_SHOPS,
//     variables: currency=="ALL"?{ operatorIds }:{ operatorIds ,_ilike:"%"+currency+"%"}
//   });
//   const relatedShops = relatedShopsResponse.data.shops;
  
//   const transactionsPromises = relatedShops.map(shop =>
//     client.query({
//       query: GET_TRANSACTIONS_FOR_SHOP,
//       // variables: { shopId: shop.id, startDate, endDate }
//        variables: { shopId: shop.id }

//     })
//   );

//   const transactionsResponses = await Promise.all(transactionsPromises);

//   const transactions = transactionsResponses.flatMap(response => response.data.transactions);
//   // const userInputRangeTransactions=filterTransactionsByDate(transactions,startDate,endDate)
//   console.log("transactions",transactions)

//   const summary = calculateSummary(transactions, relatedShops ,startDate,endDate);


  
//   return await processArray(summary);
// }

async function getSummary(operatorId, startDate, endDate, currency) {
  const operatorsResponse = await client.query({ query: GET_OPERATORS ,variables: { "id":operatorId }});
  const descendantOperators = operatorsResponse.data.operators_by_pk.descendants;

  // const descendantOperators = findDescendantOperators(operatorId, operators);
  const operatorIds = [operatorId, ...descendantOperators];

  const relatedShopsResponse = await client.query({
    query: GET_RELATED_SHOPS,
    variables: currency=="ALL"?{ operatorIds }:{ operatorIds ,_ilike:"%"+currency+"%"}
  });
  const relatedShops = relatedShopsResponse.data.shops;
  console.log("relatedShops",relatedShops)

  const shopIds = relatedShops.map(shop => shop.id);
  console.log("shopIds",shopIds)
  console.log("startDate",startDate)
  console.log("endDate",endDate)


  // Fetch transactions for all shops in a single query
  const transactionsResponse = await client.query({
    query: GET_TRANSACTIONS_FOR_SHOP,
    variables: {
      shopIds: shopIds,
      startDate: startDate,
      endDate: endDate
    }
  });

  const transactions = transactionsResponse.data.transactions;
  console.log("transactions",transactions)

  const summary = calculateSummary(transactions, relatedShops ,startDate,endDate);
  console.log("summurryyy",summary)

  
  return await processArray(summary);

  return await processArray(summary);
}


const currencies = [
  { id: 0, name: "ALL" },
  { id: 1, name: "BLR" },
  { id: 2, name: "CHF" },
  { id: 3, name: "EUR" },
  { id: 4, name: "FC" },
  { id: 5, name: "KES" },
  { id: 6, name: "LEU" },
  { id: 7, name: "NGN" },
  { id: 8, name: "PEN" },
  { id: 9, name: "PHP" },
  { id: 10, name: "RMB" },
  { id: 11, name: "TND" },
  { id: 12, name: "TRY" },
  { id: 13, name: "USD" },
  { id: 14, name: "XAF" },
  { id: 15, name: "ZAR" },
  { id: 16, name: "ZMW" },
  { id: 17, name: "ALL" },
];

const GroupOptions = [

  {
      label: "UK",
      options: [
          { label: "London", value: "London" },
          { label: "Manchester", value: "Manchester" },
          { label: "Liverpool", value: "Liverpool" }
      ]
  },
  {
      label: "FR",
      options: [
          { label: "Paris", value: "Paris" },
          { label: "Lyon", value: "Lyon" },
          { label: "Marseille", value: "Marseille" }
      ]
  },
  {
      label: "DE",
      options: [
          { label: "Hamburg", value: "Hamburg" },
          { label: "Munich", value: "Munich" },
          { label: "Berlin", value: "Berlin" }
      ]
  },
  {
      label: "US",
      options: [
          { label: "New York", value: "New York" },
          { label: "Washington", value: "Washington" },
          { label: "Michigan", value: "Michigan" }
      ]
  },
  {
      label: "SP",
      options: [
          { label: "Madrid", value: "Madrid" },
          { label: "Barcelona", value: "Barcelona" },
          { label: "Malaga", value: "Malaga" }
      ]
  },
  {
      label: "CA",
      options: [
          { label: "Montreal", value: "Montreal" },
          { label: "Toronto", value: "Toronto" },
          { label: "Vancouver", value: "Vancouver" }
      ]
  }

];

const predefinedRanges = [
  {
    label: 'Today',
    value: [new Date(new Date().setHours(0, 0, 0, 0)), new Date(new Date().setHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(new Date().setHours(0, 0, 0, 0)), -1), addDays(new Date(new Date().setHours(23, 59, 59, 999)), -1)],
    placement: 'left'
  },
  {
    label: 'This week',
    value: [startOfWeek(new Date(new Date().setHours(0, 0, 0, 0)),{ weekStartsOn: 1 }), endOfWeek(new Date(new Date().setHours(23, 59, 59, 999)),{ weekStartsOn: 1 })],
    placement: 'left'
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(new Date().setHours(0, 0, 0, 0)), 6), new Date(new Date().setHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(new Date().setHours(0, 0, 0, 0)), 29), new Date(new Date().setHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date(new Date().setHours(0, 0, 0, 0))), new Date(new Date().setHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(new Date().setHours(0, 0, 0, 0)), -1)), endOfMonth(addMonths(new Date(new Date().setHours(23, 59, 59, 999)), -1))],
    placement: 'left'
  },
  {
    label: 'This year',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().setHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Last year',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    placement: 'left'
  },
  {
    label: 'All time',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().setHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Last week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
      ];
    },
    appearance: 'default'
  },
  {
    label: 'Next week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
      ];
    },
    appearance: 'default'
  }
];

const styles = {
  coloredCell: {
    backgroundColor: '#e6e6e6',
  },
};

// const cashFooter=[
//   {shops:"",
//   operator:"",
//   currency:"Total:",
//   payout:"",
//   in:460.00,
//   out:430.49,
//   sum:29,
//   resetin:141234.00,
//   resetout:122343.00,
//   resetsum:234234.00},
// ]

const cashSampleRows=[
  {shops:"Royal43",
  operator:"Theodoros",
  currency:"EUR",
  payout:93.3322,
  in:460.00,
  out:430.49,
  sum:29,
  resetin:141234.00,
  resetout:122343.00,
  resetsum:234234.00},
  {shops:"Royal44",
  operator:"Theodoros",
  currency:"EUR",
  payout:93.3322,
  in:460.00,
  out:430.49,
  sum:29,
  resetin:141234.00,
  resetout:122343.00,
  resetsum:234234.00},
  {shops:"Royal49",
  operator:"Theodoros",
  currency:"EUR",
  payout:93.3322,
  in:460.00,
  out:430.49,
  sum:29,
  resetin:141234.00,
  resetout:122343.00,
  resetsum:234234.00},
  {shops:"Royal45",
  operator:"Theodoros",
  currency:"EUR",
  payout:93.3322,
  in:460.00,
  out:430.49,
  sum:29,
  resetin:141234.00,
  resetout:122343.00,
  resetsum:234234.00},
  {shops:"Royal46",
  operator:"Theodoros",
  currency:"EUR",
  payout:93.3322,
  in:460.00,
  out:430.49,
  sum:29,
  resetin:141234.00,
  resetout:122343.00,
  resetsum:234234.00},
  {shops:"Royal47",
  operator:"Theodoros",
  currency:"EUR",
  payout:93.3322,
  in:460.00,
  out:430.49,
  sum:29,
  resetin:141234.00,
  resetout:122343.00,
  resetsum:234234.00},
]

const columns = [
  { id: 'shops', label: 'Shops', minWidth: 100 },
  { id: 'operator', label: 'Operator', minWidth: 100 },
  { id: 'currency', label: 'Currency', minWidth: 100 },
  { id: 'payout', label: 'Payout', minWidth: 100 },
  { id: 'in', label: 'In', minWidth: 100 },
  { id: 'out', label: 'Out', minWidth: 100 },
  { id: 'sum', label: 'Sum', minWidth: 100 },
  { id: 'resetin', label: 'InR', minWidth: 100,format: (value) => value.toFixed(2) },
  { id: 'resetout', label: 'OutR', minWidth: 100,format: (value) => value.toFixed(2) },
  { id: 'resetsum', label: 'SumR', minWidth: 100,format: (value) => value.toFixed(2) },
]
function transformCreditsToCurrency(credits) {
  const currency = (credits / 100).toFixed(2);
  return currency;
}
const Starter = () => {
const { t } = useTranslation();
document.title ="Users";
// const { dataUSERS,loadingUSERS,errorUSERS } = useQuery(USER_QUERY);
const [rows, setRows] = useState([])
const [isLoading, setIsLoading] = useState(true)
const [selectedOption, setSelectedOption] = useState('');
const [sortState, setSortState] = useState({});
const [isLoadingModal, setIsLoadingModal] = useState(false);
const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedGroup, setSelectedGroup] = useState(null);
const [cashRows, setCashRows] = useState([])
 const [cashFooter,setCashFooter]=useState([])
 const [selectedCurrency, setSelectedCurrency] = useState('ALL');
const [startDate,setStartDate] = useState(formatDateToUTCISOString(new Date(new Date().setHours(0, 0, 0, 0))));
const [endDate,setEndDate] = useState(formatDateToUTCISOString(new Date(new Date().setHours(23, 59, 59, 999))));
const [operatorsDropList, setOperatorsDropList] = useState([]);
const [currentOp, setCurrentOp] = useState(0);

const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
const [filter, setFilter] = useState('');
const [showLastThreeColumns, setShowLastThreeColumns] = useState(true);
const displayedColumns = showLastThreeColumns ? columns : columns.slice(0, -3);

const handleSort = (columnId) => {
  const direction = sortConfig.key === columnId && sortConfig.direction === 'asc' ? 'desc' : 'asc';
  setSortConfig({ key: columnId, direction });
};

const getSortedAndFilteredRows = () => {
  let sortedRows = [...cashRows];

  if (sortConfig.key) {
    sortedRows.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  return sortedRows.filter((row) =>
    Object.keys(row)
      .some((key) =>
        ['shops', 'operator', 'currency', 'payout'].includes(key) &&
        String(row[key]).toLowerCase().includes(filter.toLowerCase())
      )
  );
};

const sortedAndFilteredRows = getSortedAndFilteredRows();



function handleSelectGroups(selectedGroup) {
  //console.log()
  setCurrentOp(selectedGroup.value);
  getSummary(parseInt(selectedGroup.value), startDate, endDate,selectedCurrency)
  .then(summary => {setCashRows(summary);setCashFooter([createSummary(summary)]);console.log(summary)})
  .catch(error => console.error('Error fetching data:', error));

}


const [dateRange, setDateRange] = useState({
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection'
});

const [page, setPage] = React.useState(0);
const [rowsPerPage, setRowsPerPage] = React.useState(25);

const handleCurrencyChange = (e) => {

  setSelectedCurrency(e.target.value)
  getSummary(currentOp, startDate, endDate,e.target.value)
  .then(summary => {setCashRows(summary);setCashFooter([createSummary(summary)]);console.log(summary)})
  .catch(error => console.error('Error fetching data:', error));
};

  const handleDateChange = (dates) => {
    if(dates===null){
      return
    }
  // setSelectedDates(dates);
  console.log('Selected Dates1:', (dates[0]));
  console.log('Selected Dates1:', (dates[1]));


  setStartDate(formatDateToUTCISOString(dates[0]))
  setEndDate(formatDateToUTCISOString(dates[1]))
  console.log('Selected Dates2:', formatDateToUTCISOString(dates[0]));
  console.log('Selected Dates2:', formatDateToUTCISOString(dates[1]));

  getSummary(currentOp, formatDateToUTCISOString(dates[0]), formatDateToUTCISOString(dates[1]),selectedCurrency)
  .then(summary => {setCashRows(summary);setCashFooter([createSummary(summary)]);console.log(summary)})
  .catch(error => console.error('Error fetching data:', error));
};

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(+event.target.value);
  setPage(0);
};

const handleSelect = (date) => {
  setDateRange(date);
};




function transformCreditsToCurrency(credits) {
  const currency = (credits / 100).toFixed(2);
  return currency;
}








useEffect(() => {
  // async function fetchData() {

  //   const options=await getDescendantsUsernames(localStorage.getItem("descendants"))
  //   console.log("OPERATOR OPTIONS",options)
  //   options.unshift({id:parseInt(localStorage.getItem("opID")),username:localStorage.getItem("username")})
  //   await setOperatorsDropList(options.map((operator) => ({
  //     value: operator.id,
  //     label: operator.username,
  //   })))
  // }
  async function fetchData() {
    const operatorsResponse = await client.query({
    query: GET_OPERATORS,
    variables:  {"id": localStorage.getItem("opID")},
    // fetchPolicy: 'network-only', // or 'cache-and-network'
  });        console.log("operatorsResponse",operatorsResponse)
await setCurrentOp(parseInt(localStorage.getItem("opID")))
const options=await getDescendantsUsernames(operatorsResponse.data.operators_by_pk.descendants)
options.unshift({id:parseInt(localStorage.getItem("opID")),username:localStorage.getItem("username")})
await setOperatorsDropList(options.map((operator) => ({
  value: operator.id,
  label: operator.username,
})))
}
  fetchData();

  // const operatorId = 1;
// const startDate = new Date('2023-04-01T00:00:00.000Z');
// const endDate = new Date('2023-04-30T23:59:59.999Z');
// const currency="ZAR"
setCurrentOp(parseInt(localStorage.getItem("opID")))
getSummary(parseInt(localStorage.getItem("opID")), startDate, endDate,selectedCurrency)
  .then(summary => {setCashRows(summary);setCashFooter([createSummary(summary)]);console.log(summary)})
  .catch(error => console.error('Error fetching data:', error));
}, []);
const [toggledClearRows, setToggledClearRows] = useState(false);

const [selectedRows, setSelectedRows] = React.useState([]);
// let isAllSelected=false
const dataTableRef = React.createRef();


  // onClick={handleButtonClick}
  function sortData(data, sortState) {
    const { column, sortDirection } = sortState;
    if (!column || !sortDirection) return data;
  console.log("current column",column)
  console.log("current sortDirection",sortDirection)
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return sortDirection === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  
    return sortedData;
  }

  const customStyles = {
    // header: {
    //   style: {
    //     border: '1px solid #dee2e6'
    //   }
    // },
    cells: {
      style: {
        border: '0.5px solid #dee2e6',
      },
    },
  };

  function formatDate(dateString, timezone) {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: localStorage.getItem("opTimezone"),
    };
    return date.toLocaleString('en-GB', options).replace(',', '');
  }
                        // <TableCell key={column.id} align={column.align}>
                        //   {column.format && typeof value === 'number'
                        //     ? column.format(value)
                        //     : value}
                        // </TableCell>
  

const rowsPerPageSample={ rowsPerPageText: t('Rows per page:'), rangeSeparatorText: t('of'), noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }

  return (
    <React.Fragment>      
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Cash" pageTitle={("Home")} />
          <Row style={{ position: 'relative', zIndex: 1 }}>
            <Col xs={12}>
            <Card>

                                <CardBody>

                                <Row className="g-4 mb-3">

                                <Col md="6">
                                <DateRangePicker
      ranges={predefinedRanges}
      format="yyyy-MM-dd HH:mm:ss"
      placeholder="Choose Range"
      style={{ width: 600 }}
      onChange={handleDateChange}


    />
    
                                            </Col>
                                            <Col lg={6} md={6}>
<Select
    style={{ backgroundColor: "lightBlue" }} // Set the background color inline
    value={selectedGroup}
    onChange={(e) => {
        handleSelectGroups(e);
    }}
    defaultValue={({value:localStorage.getItem("opID"),label:localStorage.getItem("username")})}

    options={operatorsDropList}
    isSearchable
    value={operatorsDropList.find((option) => option.value === currentOp)}
    styles={{ 
        container: (provided) => ({ ...provided, zIndex: 9999 }), 
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }), 
        menu: (provided) => ({ ...provided, zIndex: 9999 }), 
        control: (provided) => ({ ...provided, zIndex: 9999,backgroundColor: "lightBlue" }),
        // backgroundColor: "lightBlue"
    }} />
                                                </Col>
                                        </Row>
                                        <div style={{paddingBottom: "20px"}}>
      {currencies.map((currency) => (
        <div key={currency.id} className="form-check form-check-inline">
          {/* <Input className="form-check-input" type="radio" name="currency" id={`currency-${currency.id}`} onChange={(e)=>{setSelectedCurrency(e.target.value)}}  value={currency.name} /> */}
          <Input className="form-check-input" type="radio" name="currency" id={`currency-${currency.id}`} onChange={handleCurrencyChange} checked={selectedCurrency===currency.name} value={currency.name} />

          <Label className="form-check-label" htmlFor={`currency-${currency.id}`}>{currency.name}</Label>
        </div>
      ))}
    </div>
    <Row className="g-4 mb-3">


                                            <Col md="12">
                                                <div className="d-flex justify-content-md-end">
                                                    <div className="search-box sm-12" style={{"width":"300px"}} >
                                                   
                                                        <input type="text" className="form-control search" placeholder={t("Search...")} value={filter} onChange={(e) => setFilter(e.target.value)} />
                                                        <i className="ri-search-line search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                            </Row>
                                            <FormControlLabel
  control={
    <Switch
      checked={showLastThreeColumns}
      onChange={() => setShowLastThreeColumns(prev => !prev)}
      color="primary"
    />
  }
  label="Last Reset"
/>

                                        <Paper sx={{ width: '100%' }}>
                                        {/* stickyHeader
                                        sx={{ maxHeight: 600 }} */}
                                        <TableContainer >
        <Table sx={{ minWidth: 650 }} size="small"  aria-label="sticky table"> 
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={7}>
              <ButtonGroup>
                                                    <Button color="light" onClick={()=>{exportData(cashRows,"clipboard")}}> Copy </Button>
                                                    <Button color="light" onClick={()=>{exportData(cashRows,"csv")}} > CSV </Button>
                                                    <Button color="light" onClick={()=>{exportData(cashRows,"excel")}}> Excel </Button>
                                                    <Button color="light" onClick={()=>{exportData(cashRows,"pdf")}}> PDF </Button>
                                                    {/* <Button color="light" onClick={()=>{exportData(rows,"pdf")}}> PRINT </Button> */}


                                                </ButtonGroup>
              </TableCell>
              {showLastThreeColumns?<TableCell align="center"  colSpan={3}                         style={{

backgroundColor:  "orange" ,
color: "white"
}}>
                {t("Last Reset")}
              </TableCell>:""}
            </TableRow>
            <TableRow>
            {displayedColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  border={1}
                  borderColor="#1E5423"
                  sortDirection={sortConfig.key === column.id ? sortConfig.direction : false}
                  onClick={['shops', 'operator', 'currency', 'payout'].includes(column.id) ? () => handleSort(column.id) : undefined}
                  style={{
                    top: 57,
                    minWidth: column.minWidth,
                    backgroundColor: column.id === "resetin" ||column.id === "resetsum" || column.id === "resetout" ? "orange" : undefined,
                    color: column.id === "resetin" ||column.id === "resetsum" || column.id === "resetout" ? "black" : column.id === "in" ?"green":column.id === "out"?"red":undefined,
                    fontWeight: "bold"
                  }}                >
                {['shops', 'operator', 'currency', 'payout'].includes(column.id) ? (
                  <TableSortLabel
                    active={sortConfig.key === column.id}
                    direction={sortConfig.key === column.id ? sortConfig.direction : 'asc'}
                  >
                    {t(column.label)}
                  </TableSortLabel>
                ) : (
                  t(column.label)
                )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {cashRows */}
            {sortedAndFilteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
            {displayedColumns.map((column) => {
                      const value = row[column.id];
                      return (

                        <TableCell
                         key={column.id} align={column.align}
                        style={{
                          border: '0.5px solid grey',
                          backgroundColor: column.id === "resetin" ||column.id === "resetsum" || column.id === "resetout" ? "orange" : undefined,
                          color: column.id === "resetin" ||column.id === "resetsum" || column.id === "resetout" ? "black" : column.id === "in" ?"green":column.id === "out"?"red":undefined,
                        }}>
                       { typeof value === 'number' && column.id!="payout"
                           ? formatWithCommas(transformCreditsToCurrency(value))
                           : column.id=="payout"?value.toFixed(2):column.id=="shops"?<Link to={`/shopDetails/${row.shopId}`} onClick={(e) => {
                            e.preventDefault();
                            window.location.href = `/#/shopDetails/${row.shopId}`;
                          }}
                          style={{ color:  'green' }}
                      
                          >{value}</Link>:column.id=="operator"?value:value}
                        </TableCell> 
                      );
                    })}
                  </TableRow>
                );
              })}

          </TableBody>
          <TableFooter>
            {cashFooter.length>0?
          <TableRow hover role="checkbox" tabIndex={-1} key={cashFooter[0].code}>
            {displayedColumns.map((column) => {
                      const value = cashFooter[0][column.id];
                      return (

                        <TableCell key={column.id} align={column.align}
                        style={{

                          backgroundColor: column.id === "resetin" ||column.id === "resetsum" || column.id === "resetout" ? "orange" : undefined,
                          color: column.id === "resetin" ||column.id === "resetsum" || column.id === "resetout" ? "black" : column.id === "in" ?"green":column.id === "out"?"red":undefined,
                          fontWeight: "bold"

                        }}>
                       { typeof value === 'number'
                           ? formatWithCommas(transformCreditsToCurrency(value))
                           : value}
                        </TableCell> 
                      );
                    })}
                  </TableRow>:""}
          </TableFooter>
        </Table>

      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={cashRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
          </Paper>

                                </CardBody>
                            </Card>
            </Col>
          </Row>
        </Container>

    <Modal
                isOpen={isLoadingModal}
                toggle={() => {
                  setIsLoadingModal();
                }}
                backdrop={'static'}
                id="staticBackdrop"
                centered
                size='xs'
            >

                <ModalBody className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
        <span className="ml-2">Loading...</span>
                </ModalBody>
            </Modal>

      </div>
    </React.Fragment>
  );
};

export default Starter;