import React, { useEffect,useState } from 'react';
import { useTranslation } from "react-i18next";import { Card,CardHeader, CardBody, Col, Container, Input, Label, Row, Table,Button,Modal, ModalBody, ModalHeader,ModalFooter, Spinner} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
// import { Modal, Spinner } from 'react-bootstrap';

import { Link } from 'react-router-dom';
//import { BasicTable, ScrollVertical, ScrollHorizontal, AlternativePagination, FixedHeaderDatatables, ModalDataDatatables, AjaxDatatables } from '../Tables/DataTables/datatableCom';
import { useQuery,useMutation, gql } from '@apollo/client';
// import checkTokenValidity from '../../Layouts/Footer'
import DataTable from 'react-data-table-component';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useCreateLog } from  "../../Components/Common/functions";  // Adjust the path as necessary


const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // Replace with your actual token
  const token = localStorage.getItem("token");

  // Add custom headers
  return {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem("token"),
    },
  };
});
// Initialize Apollo Client with custom headers
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // cache: 'no-cache'
  cache: new InMemoryCache(),
});
// password
// id
// is_enabled
// is_panic
// current_game
// gp_user_id
// credits
// bonus
// username
const DESCENDANTS_QUERY = gql`
query MyQuery($id: Int!) {
  operators_by_pk(id: $id) {
    descendants
  }
}

`;

// password
// id
// is_enabled
// is_panic
// current_game
// gp_user_id
// credits
// bonus
// username
const EDIT_IMAGE_GAME_MUTATION = gql`
mutation MyMutation2($id: Int!, $image: String!) {
  update_banners_by_pk(pk_columns: {id: $id}, _set: {image: $image}) {
    id
  }
}`;


const GV_GAME_LIST_MUTATION = gql`
mutation MyMutation2($is_active: Boolean!, $id: Int!) {
  update_banners_by_pk(pk_columns: {id: $id}, _set: {is_active: $is_active}) {
    id
  }
}`;

const EDIT_LINK_BANNER_MUTATION = gql`
mutation MyMutation2($id: Int!, $link: String!) {
  update_banners_by_pk(pk_columns: {id: $id}, _set: {link: $link}) {
    id
  }
}`;


const USER_QUERY = gql`
query MyQuery {
  banners(order_by: {position: asc, lobby_name: asc}) {
    id
    image
    is_active
    link
    lobby_name
    position
  }
}
`;
const ENABLE_USER_MUTATION = gql`
mutation MyMutation($is_enabled: Boolean, $_in: [Int!] ) {
  update_users(where: {id: {_in: $_in}}, _set: {is_enabled: $is_enabled}) {
    affected_rows
  }
}
`;

const ENABLE_USER_NOTIN = gql`
mutation MyMutation($is_enabled: Boolean = false, $_nin: [Int!] = 10) {
  update_users(where: {id: {_nin: $_nin}}, _set: {is_enabled: $is_enabled}) {
    affected_rows
  }
}
`;

// const ENABLE_PANIC_USER = gql`
// mutation MyMutation($is_panic: Boolean, $_in: [Int!]) {
//   update_users(where: {id: {_in: $_in}}, _set: {is_panic: $is_panic}) {
//     affected_rows
//   }
// }
// `;



let theNotSelected=[]
let theFilteredData=[]
let initialRows=[]
// const generateTestData = () => {
//   const testData = []
//   for (let i = 0; i <= 2000; i++) {
//     testData.push({
//       bonus: Math.floor(Math.random() * 100),
//       credits: Math.floor(Math.random() * 1000),
//       current_game: `Game ${i}`,
//       gp_user_id: `user${i}`,
//       id: i,
//       is_enabled: Math.random() < 0.5,
//       password: `password${i}`,
//       shop_id: Math.floor(Math.random() * 10),
//       username: `user${i}`,
//     })
//   }
//   return testData
// }

// const shops = [];

// for (let i = 0; i < 2000; i++) {
//   const shop = {
//     bonus_percentage: Math.floor(Math.random() * 100),
//     credits: Math.floor(Math.random() * 1000),
//     currency: Math.random() < 0.5 ? "USD" : "EUR",
//     gp_cur: Math.random() < 0.5 ? "BTC" : "ETH",
//     gp_maxbet: Math.floor(Math.random() * 10000),
//     gp_maxbet_roulette: Math.floor(Math.random() * 10000),
//     gp_maxbet_tablegames: Math.floor(Math.random() * 10000),
//     gp_minbet: Math.floor(Math.random() * 100),
//     gp_minbet_roulette: Math.floor(Math.random() * 100),
//     gp_minbet_tablegames: Math.floor(Math.random() * 100),
//     gp_rtplevel: Math.floor(Math.random() * 10),
//     gp_shop_id: Math.random().toString(36).substring(2, 10),
//     id: i,
//     is_enabled: Math.random() < 0.5,
//     is_panic: Math.random() < 0.5,
//     last_login: new Date().toISOString(),
//     name: Math.random().toString(36).substring(2, 10),
//     operator_id: Math.floor(Math.random() * 1000),
//     password: Math.random().toString(36).substring(2, 10),
//     new_user_limit: Math.floor(Math.random() * 1000),
//     username: Math.random().toString(36).substring(2, 10)
//   };
//   shops.push(shop);
// }

// console.log(shops);




// const transactions = [];

// for (let i = 0; i < 2000; i++) {
//   const transaction = {
//     amount: parseFloat((Math.random() * 1000).toFixed(2)),
//     created_at: new Date().toISOString(),
//     description: Math.random().toString(36).substring(2, 10),
//     from_id: Math.floor(Math.random() * 1000),
//     from_type: Math.floor(Math.random() * 10),
//     from_username: Math.random().toString(36).substring(2, 10),
//     id: i,
//     to_id: Math.floor(Math.random() * 1000),
//     to_type: Math.random() < 0.5 ? "user" : "shop",
//     to_username: Math.random().toString(36).substring(2, 10)
//   };
//   transactions.push(transaction);
// }

// console.log(transactions);

const EditLinkModal = ({ isOpen, onClose, onSave, initialValue }) => {
  const [linkValue, setLinkValue] = useState(initialValue);

  useEffect(() => {
    if (isOpen) {
      setLinkValue(initialValue); // Reset link value when modal opens
    }
  }, [isOpen, initialValue]);

  const handleSave = () => {
    onSave(linkValue);
    onClose(); // Close modal after save
    
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <h2>Edit Link</h2>
      <Input value={linkValue} onChange={(e) => setLinkValue(e.target.value)} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <Button color="secondary" onClick={onClose} style={{ marginRight: '10px' }}>Cancel</Button>
        <Button color="primary" onClick={handleSave}>Save</Button>
      </div>
    </Modal>
  );
};




// const testData = generateTestData()
// function compareArrays(arrayA, arrayB) {
//   console.log("resultA",arrayA)
//   const idsB = arrayB.map((item) => item.id);
//   const result = arrayA.filter((item) => !idsB.includes(item.id));
//   console.log("result",result)
//   return result;
// }
const banners = [
  { id: 1, name: "Main Banner", ratio: "500x900", position: "Top Left", url: "banner1.jpg" },
  { id: 2, name: "Side Banner", ratio: "300x600", position: "Mid Right", url: "banner2.jpg" },
  { id: 3, name: "Bottom Banner", ratio: "500x600", position: "Bottom", url: "banner2.jpg" },

  // ... more banners
];

const actionObj={
3:"Enable Banner",
4:"Disable Banner"}

const Starter = () => {
const { t } = useTranslation();
document.title ="Banners";
const { createLog } = useCreateLog();

// const { dataUSERS,loadingUSERS,errorUSERS } = useQuery(USER_QUERY);
const [rows, setRows] = useState([])
const [isLoading, setIsLoading] = useState(true)
const [selectedOption, setSelectedOption] = useState('');
const [filterValue, setFilterValue] = useState('');
const [sortState, setSortState] = useState({});
const [isLoadingModal, setIsLoadingModal] = useState(false);
const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedFile, setSelectedFile] = useState(null);
const [selectedGame, setSelectedGame] = useState(null);
const [uploadModalOpenEdit, setUploadModalOpenEdit] = useState(false);
const [viewModal, setViewModal] = useState(false);
const [imageUrl, setImageUrl] = useState('');
const [isEditModalOpen, setIsEditModalOpen] = useState(false);
const [currentRow, setCurrentRow] = useState(null);

const [dateRange, setDateRange] = useState({
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection'
});

const handleSelect = (date) => {
  setDateRange(date);
};
const openEditLinkModal = (row) => {
  setCurrentRow(row);
  setIsEditModalOpen(true);
};

const handleSaveLink = async (newLink) => {
  await updateLink({
    variables: {
      id: parseInt(currentRow.id),
      link: newLink,
    },
  });
  // Optionally, refetch or update state here
  setIsEditModalOpen(false);
};

const handleFileChange = (event) => {
  console.log("event.target",event.target.files)
  setSelectedFile(event.target.files[0]);
};
const openUploadModalEdit = (game) => {
  setSelectedFile(null)
  console.log("the game upload",game)
  setSelectedGame(game);
  setUploadModalOpenEdit(true);
};

const openViewModal = (row) => {
  setImageUrl(row);
  setViewModal(true);
};
const handleConfirm = () => {
  // Perform the action you want to execute here
  handleButtonClick()
  setIsModalOpen(false); // Close the modal
};
const closeUploadModalEdit = () => {
  setUploadModalOpenEdit(false);
};
const handleCancel = () => {
  setIsModalOpen(false); // Close the modal
};

const handleClick = () => {
  if(selectedOption!=0&&selectedRows.length>0){
    setIsModalOpen(true); // Open the modal
  }
};

function transformCreditsToCurrency(credits) {
  const currency = (credits / 100).toFixed(2);
  return currency;
}

const [enableUserMutation, { loading, error }] = useMutation(ENABLE_USER_MUTATION, {
  onCompleted: (data) => {
    console.log(data);
    const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]

    for(let i=0;i<idArraysel.length;i++){
      createLog(idArraysel[i],"user",selectedOption==3?"User Enabled":"User Disabled",true)
    }
    // Handle the result of the mutation here
  },
  onError: (error) => {
    const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]

    for(let i=0;i<idArraysel.length;i++){
      createLog(idArraysel[i],"user",selectedOption==3?"User Enabled":"User Disabled",false)
    }
    console.log(error);
    // Handle the error here
  }
});

const [uploadImage_GameMutation, { loadingIMAGE, errorIMAGE }] = useMutation(EDIT_IMAGE_GAME_MUTATION, {
  onCompleted: (data) => {
    console.log(data);
    // Handle the result of the mutation here
  },
  onError: (error) => {
    console.log(error);
    // Handle the error here
  }
});

const [updategvGameList, { loadingPORdisgv, errorPORdisgv }] = useMutation(GV_GAME_LIST_MUTATION, {
  onCompleted: (data) => {
    console.log(data);
    createLog(parseInt(localStorage.getItem("opID")),"operator","Banner enabled/disabled Modified",true)

    // Handle the result of the mutation here
  },
  onError: (error) => {
    console.log(error);
    createLog(parseInt(localStorage.getItem("opID")),"operator","Banner enabled/disabled Modified",false)

    // Handle the error here
  }
});

const [updateLink, { loadingLink, errorLink }] = useMutation(EDIT_LINK_BANNER_MUTATION, {
  onCompleted: (data) => {
    console.log(data);
    createLog(parseInt(localStorage.getItem("opID")),"operator","Banner link Modified",true)
    refetch({});
    // Handle the result of the mutation here
  },
  onError: (error) => {
    console.log(error);
    createLog(parseInt(localStorage.getItem("opID")),"operator","Banner link Modified",false)

    // Handle the error here
  }
});


// const [enablePanicUserMutation, { dataAccept1, loadingAccept1, errorAccept1 }] = useMutation(ENABLE_PANIC_USER, {
//   onCompleted: (data) => {
//     console.log(data);
//     const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]

//     for(let i=0;i<idArraysel.length;i++){
//       createLog(idArraysel[i],"user",selectedOption==1?"Panic Enabled":"Panic Disabled",true)
//     }
//     // Handle the result of the mutation here
//   },
//   onError: (error) => {
//     const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]

//     for(let i=0;i<idArraysel.length;i++){
//       createLog(idArraysel[i],"user",selectedOption==1?"Panic Enabled":"Panic Disabled",false)
//     }
//     console.log(error);
//     // Handle the error here
//   }
// });
const handleFileUpload_Image = async (gameId, file) => {
  const YOUR_API_URL=process.env.REACT_APP_BACKEND_URL+"/api/upload/"
  const formData = new FormData();
  formData.append('myfile', file);
  formData.append('token', localStorage.getItem("token"));

  // formData.append('gameId', gameId);

  try {


    // 'Content-Type': 'application/json', 
    const GraphQLrequestOptions = {
      method: 'POST',
      headers: { 'authorization': localStorage.getItem("token") },
      // body: JSON.stringify({
      //   'myfile':file,
      //   'token': localStorage.getItem("token")
      // })
      body: formData
    };
    
    const GraphQLresponse = await fetch(YOUR_API_URL, GraphQLrequestOptions)
    if(GraphQLresponse.status!=200){
      alert("Could not fetch data", GraphQLresponse.statusText)
      return
    }
    
    const GraphQ_json = await GraphQLresponse.json();
    console.log("theurl",GraphQ_json)

    if (GraphQLresponse.ok) {
      console.log('File uploaded successfully');
      if(GraphQ_json.url!=undefined&&GraphQ_json.url!=null){
        // console.log("variables:",{
        //   is_custom_portrait_enabled: true, 
        //   portrait_image_url: GraphQ_json.url ,
        //   _eq:gameId
        // })
          await uploadImage_GameMutation({
            variables: {
              image: GraphQ_json.url ,
              id:gameId
            }
          });
        await refetch({})
      }
      
    } else {
      console.error('Error uploading file:', GraphQLresponse.statusText);
    }
  } catch (error) {
    console.error('Error uploading file:', error.message);
  }
};


function handleSelectChange(event) {
  const value = event.target.selectedOptions[0].value;
  setSelectedOption(value);
}


const redirectWithId = (id) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set('id', id);
  const params = urlSearchParams.toString();
  // console.log("paransss",params)
  window.location.href = `/#/userDetails?${params}`;
}

const filterTable = (event, originalData, setData) => {
  setFilterValue(event.target.value);
  const searchTerm = event.target.value.toLowerCase()
  // const filteredData = originalData.filter((row) => {
  //   return Object.keys(row).some((key) => {
  //     const cellValue = String(row[key]).toLowerCase()
  //     return cellValue.includes(searchTerm)
  //   })
  // })
  // console.log("filteredSData",originalData)
//   const filteredData = originalData.filter((row) => {
//     const username = String(row.username).toLowerCase();
//     return username.includes(searchTerm);
// });
const filteredData = originalData.filter((row) => {
  const username = String(row.name).toLowerCase();
  return username.includes(searchTerm);
});
  theFilteredData=filteredData
  setRows(filteredData)
}



const { refetch } = 
useQuery(
  DESCENDANTS_QUERY,

  {
    onError:(err) =>{
      console.log(err)
      // window.location.href = "/#/login"

    },
    variables:  {"id": localStorage.getItem("opID")},

    // variables: {domain_id: {"_eq": locStorageItem}},
    onCompleted: async(data) => {
      console.log("TA DATA:",data)
      if(data.operators_by_pk!=null){
        // console.log("thedescentants",data.operators_by_pk.descendants.push(localStorage.getItem("opID")))
        const theDescendants=data.operators_by_pk.descendants
        await theDescendants.push(localStorage.getItem("opID"))
        const usersResponse = await client.query({
          query: USER_QUERY,
          // variables: { "_in": theDescendants },
          fetchPolicy: 'network-only', // or 'cache-and-network'
        });        console.log("operatorsResponse",usersResponse)
        if(usersResponse.data.banners!=undefined){
          const banners = usersResponse.data.banners
          // const banners = [
          //   { id: 1, name: "Main Banner", ratio: "500x900", position: "Top Left", url: "banner1.jpg" },
          //   { id: 2, name: "Side Banner", ratio: "300x600", position: "Mid Right", url: "banner2.jpg" },
          //   { id: 3, name: "Bottom Banner", ratio: "500x600", position: "Bottom", url: "banner2.jpg" },
          
          //   // ... more banners
          // ];
        
          const searchTerm = filterValue.toLowerCase()
          // const filteredData = (usersResponse.data.users).filter((row) => {
          //   return Object.keys(row).some((key) => {
          //     const cellValue = String(row[key]).toLowerCase()
          //     return cellValue.includes(searchTerm)
          //   })
          // })
        //   const filteredData = usersResponse.data.users.filter((row) => {
        //     const username = String(row.username).toLowerCase();
        //     return username.includes(searchTerm);
        // });
        const filteredData = banners.filter((row) => {
          const username = String(row.lobby_name).toLowerCase();
          return username.includes(searchTerm);
      });
        
          // console.log("filteredSData",originalData)
          theFilteredData=await sortData(filteredData, sortState);
          
  
          // setRows(data.users)
          // initialRows=data.users
          // theFilteredData=data.users
          await setRows([])

          await setRows(theFilteredData)
          // initialRows=usersResponse.data.users
          initialRows=banners
          theFilteredData=theFilteredData
        }

      }


      setIsLoading(false)
      setIsLoadingModal(false);

    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only', // or 'cache-and-network'

    // pollInterval:5000
  }
);

// const userId = '123'; // This is the ID you want to pass to the next page

//       {/* Link with parameters */}
//       <Link to={`/userDetails/${userId}`}>User Details</Link>

//       {/* Button with parameters */}
//       <button onClick={() => window.location.href = `/#/userDetails/${userId}`}>
//         User Details
//       </button>




//       import React from 'react';
// import { useParams } from 'react-router-dom';

// const UserDetails = () => {
//   const { userId } = useParams();

//   return (
//     <div>
//       <h1>User Details</h1>
//       <p>User ID: {userId}</p>
//       {/* Other user details */}
//     </div>
//   );
// };

// export default UserDetails;

useEffect(() => {
}, []);
const [toggledClearRows, setToggledClearRows] = useState(false);

const [selectedRows, setSelectedRows] = React.useState([]);
// let isAllSelected=false
const dataTableRef = React.createRef();

// const handleClearSelection = () => {
//   setSelectedRows([]); // Clear the selected rows state
//   dataTableRef.current.clearSelectedRows(); // Clear the selected rows in the DataTable component
// };
const handleClearSelection = () => {
  setSelectedRows([]);
  setToggledClearRows(!toggledClearRows);
};


	const handleRowSelected = React.useCallback(state => {
    //mporw apla panta na exw ta selected je ta not selected je na stellw jina pu en liotera xwris na kamnw ulla ta ifs
    // if(state.allSelected){
    //   isAllSelected=true
    //   theNotSelected=[]
    // }else{
    //   if(state.selectedCount==0){
    //     isAllSelected=false
    //   }else{
    //     theNotSelected=compareArrays(theFilteredData,state.selectedRows)
    //   }
    // }
    // setSelectedRows([]);

		setSelectedRows(state.selectedRows);
        console.log("eta rows",state.selectedRows)
        console.log("et state",state)
        console.log("eta not selected",theNotSelected)

	}, []);


  
  const handleEnablePanic = async () => {
    // setIsLoadingModal(true);
    //   const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]
    //   console.log("the in idArray",idArraysel)
    //   console.log("selection",selectedOption==1)

    //   await enablePanicUserMutation({
    //     variables: {
    //       is_panic: selectedOption==1, // Set to true to enable the user
    //       _in: idArraysel // Set to the ID of the user to enable
    //     }
    //   });
    // // }
    // await refetch({})

  };


  const handleEnableUser = async () => {
    setIsLoadingModal(true);

    // if(false&&isAllSelected && theNotSelected.length<selectedRows.length){
    //   const idArray = theNotSelected.map(obj => obj.id); // [1, 2, 3]
    //   console.log("the not in idArray",idArray)
    //   console.log("selection",selectedOption==3)

    //   // selectedOption===3
    //   await enableNOTINUserMutation({
    //     variables: {
    //       is_enabled: selectedOption==3, // Set to true to enable the user
    //       _nin: idArray // Set to the ID of the user to enable
    //     }
    //   });
    // }else{
      const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]
      console.log("the in idArray",idArraysel)
      console.log("selection",selectedOption==3)

      await enableUserMutation({
        variables: {
          is_enabled: selectedOption==3, // Set to true to enable the user
          _in: idArraysel // Set to the ID of the user to enable
        }
      });
    // }
    await refetch({})
  };

    const handleButtonClick = async () => {

    switch(selectedOption) {
      case '1':
        await handleEnablePanic()
        await handleClearSelection()
        console.log('Option 1 selected');
        break;
      case '2':
        await handleEnablePanic()
        await handleClearSelection()
        // perform action for option 2
        console.log('Option 2 selected');
        break;
      case '3':
        await handleEnableUser()
        await handleClearSelection()
        // perform action for option 3
        console.log('Option 3 selected');
        break;
      case '4':
        await handleEnableUser()
        await handleClearSelection()
        // perform action for option 4
        console.log('Option 4 selected');
        break;
      default:
        // do nothing
        break;
    }
    
  }
  const handleSortChange = (column, sortDirection) => {
    setSortState({ column, sortDirection });
  };
  // onClick={handleButtonClick}
  function sortData(data, sortState) {
    const { column, sortDirection } = sortState;
    if (!column || !sortDirection) return data;
  console.log("current column",column)
  console.log("current sortDirection",sortDirection)
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return sortDirection === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  
    return sortedData;
  }

  const customStyles = {
    // header: {
    //   style: {
    //     border: '1px solid #dee2e6'
    //   }
    // },
    cells: {
      style: {
        border: '0.5px solid #dee2e6',
      },
    },
  };

  function formatDate(dateString, timezone) {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: localStorage.getItem("opTimezone"),
    };
    return date.toLocaleString('en-GB', options).replace(',', '');
  }

  const columns = [
    { name: "Lobby", selector: row => row.lobby_name },
    { name: "Position", selector: row => row.position },
    {
      name: "Link",
      selector: row => row.link,
      cell: (row) => (
        <Button color="link" onClick={() => openEditLinkModal(row)} className="button-icon">
          <i className="fas fa-edit"></i> {/* Using Font Awesome edit icon */}
          {row.link}
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    }
    
    
,    
    // { name: "Ratio", selector: row => "x" },
    {
      name: t('Enabled'),
      selector: 'is_active',   // Important! It tells the table which field to sort by
    
      cell: (row) => (
        <div className="form-check form-switch">
          <Input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`flexSwitchCheckCheckedDisabled${row.id}`}
            checked={row.is_active}
            onChange={async() => {
                await updategvGameList({
                  variables: {
                    id:parseInt(row.id),
                    is_active:!row.is_active
                  }
                });
             
              await refetch({})
            }}
          />
        </div>
      ),
      sortable: true,
    },
    // Add a column for actions
    {
        name: "Actions",
        cell: (row) => (
            <div>
                <Button color="primary" 
                onClick={() => openViewModal(row.image)}

                // onClick={() => viewBanner(row.url)}
                >
                    <i className="fas fa-eye"></i>
                </Button>
                <Button color="secondary" 
                // onClick={() => uploadBanner(row.id)}
                onClick={() => openUploadModalEdit(row)}

                >
                    <i className="fas fa-upload"></i>
                </Button>
            </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
];





const rowsPerPageSample={ rowsPerPageText: t('Rows per page:'), rangeSeparatorText: t('of'), noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }

  return (
    <React.Fragment>      
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Banners")} pageTitle={t("Home")} />
          <Row>
            <Col xs={12}>
            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">{t("Banners")}</h5>
                                </CardHeader>
                                <CardBody>


            <DataTable

            onSort={handleSortChange}
            striped
            progressPending={isLoading}
            dense
            highlightOnHover
            columns={columns}
            data={rows}
            selectableRows
            onSelectedRowsChange={handleRowSelected}
            pagination
            paginationRowsPerPageOptions={[10, 15, 20,100]}
paginationComponentOptions={rowsPerPageSample}
            clearSelectedRows={toggledClearRows}
            // style={{ borderCollapse: "collapse" }}
            // className="react-data-table"
            customStyles={customStyles}
            // customHeader={<CustomHeader />}

            // npm i react-data-table-component-footer
        />
                                </CardBody>
                            </Card>
            </Col>
          </Row>
        </Container>
        {/* <Modal show={isLoadingModal} backdrop="static" keyboard={false}>
      <Modal.Body className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="primary" />
        <span className="ml-2">Loading...</span>
      </Modal.Body>
    </Modal> */}
    <Modal
                isOpen={isLoadingModal}
                toggle={() => {
                  setIsLoadingModal();
                }}
                backdrop={'static'}
                id="staticBackdrop"
                centered
                size='xs'
            >

                <ModalBody className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
        <span className="ml-2">Loading...</span>
                </ModalBody>
            </Modal>

            <Modal isOpen={isModalOpen} toggle={handleCancel}>
        <ModalHeader toggle={handleCancel}>Confirm</ModalHeader>
        <ModalBody>
          Are you sure you want {actionObj[selectedOption]} for the selected items?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirm}>
            Yes
          </Button>
          <Button color="secondary" onClick={handleCancel}>
            No
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={uploadModalOpenEdit} toggle={closeUploadModalEdit}>
  <ModalHeader toggle={closeUploadModalEdit}>Upload Custom Image</ModalHeader>
  <ModalBody>
  <Input type="file" name="myfile" accept="image/x-png,image/jpeg,image/png" onChange={handleFileChange} />
  </ModalBody>
  <ModalFooter>
    <Button
      color="primary"
      onClick={async () => {
        await handleFileUpload_Image(selectedGame.id, selectedFile,selectedGame);
        // Replace with the actual GraphQL mutation to update the custom portrait
        // await client.mutate({ mutation: YOUR_GRAPHQL_MUTATION });
        closeUploadModalEdit();
      }}
    >
      Save
    </Button>{' '}
    <Button color="secondary" onClick={closeUploadModalEdit}>
      Cancel
    </Button>
  </ModalFooter>
</Modal>

<Modal isOpen={viewModal} toggle={() => setViewModal(false)}>
        <img src={imageUrl} alt="NO IMAGE" className="img-fluid" />
        <Button color="secondary" onClick={() => setViewModal(false)}>Close</Button>
      </Modal>
      <EditLinkModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSave={handleSaveLink}
        initialValue={currentRow ? currentRow.link : ''}
      />
      </div>

    </React.Fragment>
  );
};

export default Starter; 