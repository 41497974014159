import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import {
  Card, Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, 
    Input, Label, Alert 
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useTranslation } from "react-i18next";
import { format } from 'date-fns';

const localizer = momentLocalizer(moment);

const CustomEvent = ({ event, title }) => {
  return (
    <span>
      <strong>{title}</strong>
    </span>
  );
};

// Define GraphQL Queries and Mutations
const APPOINTMENTS_QUERY = gql`
query MyQuery($id: Int = 1) {
  appointments(
    where: { appointment_to_staff_to_service_relationship: {staff_to_service_relationship: {service: {service_provider_id: {_eq: $id}}}}}
  ) {
    customer_user_id
    end_time
    id
    start_time
    status
    user {
      first_name
      last_name
      gender
      email
      phone_number
      address_city
    }
    appointment_to_staff_to_service_relationship {
      staff_to_service_relationship {
        service {
          name
          color
        }
        staff {
          name
        }
      }
    }
  }
  service_providers_by_pk(id: $id) {
    company_name
    opening_hours
  }

  staff(where: { service_provider_id: { _eq: $id } }) {
    id
    name
  }

  services(where: { service_provider_id: { _eq: $id } }) {
    id
    name
    color
    price
    duration
    description
    is_enabled
    staff_to_service_relationship {
    id
      staff {
        id
        name
      }
    }
  }
}

`;


const UPDATE_APPOINTMENT_STATUS = gql`
  mutation MyMutation($id: Int!, $status: String!) {
    update_appointments_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
      id
    }
  }
`;

const CREATE_WALK_IN_APPOINTMENT_MUTATION = gql`
  mutation CreateWalkInAppointment(
    $start_time: timestamptz!
    $end_time: timestamptz!
    $status: String!
    $customer_user_id: Int!
    $relationships: [appointment_to_staff_to_service_relationship_insert_input!]!
  ) {
    insert_appointments_one(object: {
      start_time: $start_time,
      end_time: $end_time,
      status: $status,
      customer_user_id: $customer_user_id,
      appointment_to_staff_to_service_relationship: {
        data: $relationships
      }
    }) {
      id
    }
  }
`;

const formats = {
  dayHeaderFormat: ({ start }) => moment(start).format('MMMM DD, YYYY'),
  eventTimeRangeFormat: () => '', // Suppress time range in day/week views
  agendaTimeRangeFormat: ({ start, end }) => `${moment(start).format('LT')} - ${moment(end).format('LT')}`, // Proper time format for agenda view
};

const ProviderCalendar = () => {
  const serviceProviderId = parseInt(localStorage.getItem('opID'), 10);
  const { t } = useTranslation();

  // State for modal and view
  const [modal, setModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [view, setView] = useState('day'); 
  const [date, setDate] = useState(new Date()); // Store the current date (default today)

  const [appointments, setAppointments] = useState([]); // Store appointments

  // Dropdown states
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [staffDropdownOpen, setStaffDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('ALL STATUS');
  const [selectedStaff, setSelectedStaff] = useState('ALL STAFF');


  const [walkInModalOpen, setWalkInModalOpen] = useState(false);
  const [walkInServices, setWalkInServices] = useState([]);
  const [walkInStaff, setWalkInStaff] = useState([]);

const [selectedTime, setSelectedTime] = useState(null); // Stores the double-clicked time slot
const [selectedServices, setSelectedServices] = useState([{ serviceId: null, staffId: null }]); // Array to hold each service-staff pair

  const [confirmModal, setConfirmModal] = useState(false);
const [actionToConfirm, setActionToConfirm] = useState(null);
const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
const calendarRef = useRef(null);

useEffect(() => {
  // Check if there's a selected appointment in localStorage
  const selectedAppointmentLocal = localStorage.getItem('selectedAppointment');
  console.log("appointment",selectedAppointmentLocal)

  if (selectedAppointmentLocal) {
    const appointment = JSON.parse(selectedAppointmentLocal);
    // Navigate to the day of the appointment
    navigateToAppointment(appointment);
    // Remove the appointment from localStorage after using it
    localStorage.removeItem('selectedAppointment');
  }
}, []);

const navigateToAppointment = (appointment) => {
  // Set the view to 'day' and navigate to the appointment's start date
  setView('day');
  setDate(new Date(appointment.start_time)); // Set the calendar's current date to the appointment's start date
  // Optional: Scroll to the specific time of the appointment
  setTimeout(() => {
    if (calendarRef.current) {
      calendarRef.current.scrollToTime(appointment.start); // Scroll to the specific time of the appointment
    }
  }, 0);
};


const [createWalkInAppointment] = useMutation(CREATE_WALK_IN_APPOINTMENT_MUTATION, {
  onCompleted: () => {
    refetch(); // You may need to refetch appointments or update the calendar
    setWalkInModalOpen(false); // Close the modal after creation
  },
  onError: (error) => {
    console.error("Error creating walk-in appointment:", error);
    alert("Failed to create walk-in appointment. Please try again.");
  },
});

const handleDoubleClickSlot = (slotInfo) => {
  setSelectedTime(slotInfo.start);
  setWalkInModalOpen(true);
};

const handleWalkInServiceSelection = (serviceId) => {
  setWalkInServices((prevSelected) => (
    prevSelected.includes(serviceId) ? prevSelected.filter(id => id !== serviceId) : [...prevSelected, serviceId]
  ));
};

const handleWalkInStaffSelection = (staffId) => {
  setWalkInStaff((prevSelected) => (
    prevSelected.includes(staffId) ? prevSelected.filter(id => id !== staffId) : [...prevSelected, staffId]
  ));
};

const handleCreateWalkInAppointment = () => {
  if (!selectedTime || selectedServices.length === 0 || selectedServices.some(entry => !entry.serviceId || !entry.staffId)) {
    alert("Please select a time, at least one service, and staff member.");
    return;
  }

  // Calculate the maximum duration among the selected services
  const maxDuration = Math.max(
    ...selectedServices.map(entry => {
      const selectedService = data.services.find(service => service.id === entry.serviceId);
      return selectedService ? selectedService.duration : 0;
    })
  );

  const endTime = new Date(selectedTime);
  endTime.setMinutes(endTime.getMinutes() + maxDuration);
console.log("selectedServices",selectedServices)
  const relationships = selectedServices.map(entry => {
    const relationship = data.services
      .find(service => service.id === entry.serviceId)
      ?.staff_to_service_relationship
      .find(rel => rel.staff.id === entry.staffId);
    console.log("relationship",relationship)
    if (!relationship) {
      console.error("No existing relationship found for service and staff pair:", entry.serviceId, entry.staffId);
      return null;
    }

    return {
      staff_to_service_relationship_id: relationship.id,
    };
  }).filter(Boolean);

  if (relationships.length === 0) {
    alert("No valid relationships found for selected service-staff pairs.");
    return;
  }

const defaultCustomerId = 9;

console.log("hello",
  {
    start_time: selectedTime.toISOString(),
    end_time: endTime.toISOString(),
    status: "CONFIRMED",
    relationships: relationships,
  },
)
createWalkInAppointment({
  variables: {
    start_time: selectedTime.toISOString(),
    end_time: endTime.toISOString(),
    status: "CONFIRMED",
    relationships: relationships,
    customer_user_id: defaultCustomerId, // Assign default customer ID here

  },
}).then(() => {
  setWalkInModalOpen(false);
  setSelectedTime(null);
  setSelectedServices([{ serviceId: null, staffId: null }]);
  alert("Appointment created successfully!");
}).catch(error => {
  console.error("Error creating appointment:", error);
  alert("Failed to create appointment.");
});
};

  const toggleStatusDropdown = () => setStatusDropdownOpen(prevState => !prevState);
  const toggleStaffDropdown = () => setStaffDropdownOpen(prevState => !prevState);

  // Toggle modal
  const toggle = () => setModal(!modal);

  // Fetch appointments and opening hours
  const { data, loading, error,refetch } = useQuery(APPOINTMENTS_QUERY, {
    variables: { id: serviceProviderId },
    pollInterval: 10000, // Polling every 10 seconds
    onError: (error) => {
      console.log("Detailed Error:", error);
    },
    onCompleted: (data) => {
      // Map through appointments and format as required
      const formattedAppointments = data.appointments.map((appointment) => {
        const services = appointment.appointment_to_staff_to_service_relationship;
        
        // Combine service names and check for multiple services
        const serviceNames = services.map((relationship) => relationship.staff_to_service_relationship.service.name);
        const titlePrefix = serviceNames.length > 1 ? "COMB: " : "";
        const title = `${titlePrefix}${serviceNames.join(" + ")}`;
  
        // Use the color of the first service in the list
        const color = services[0].staff_to_service_relationship.service.color;
  
        return {
          id: appointment.id,
          start: new Date(appointment.start_time),
          end: new Date(appointment.end_time),
          title: title, // Title with conditional "COMB: " prefix
          staffName: services[0].staff_to_service_relationship.staff.name,
          status: appointment.status,
          color: color,
        };
      });
  
      setAppointments(formattedAppointments);
    },
  });

  // Define mutation for updating the appointment status
  const [updateAppointmentStatus] = useMutation(UPDATE_APPOINTMENT_STATUS, {
    onCompleted: (data) => {
      refetch(); 
      console.log("Appointment status updated successfully:", data);
      // alert("Appointment status updated successfully!");
    },
    onError: (error) => {
      console.error("Error updating appointment status:", error);
      alert("Error updating appointment status: " + error.message);
    }
  });
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading appointments.{error.message}</p>;

  // Handle appointment click to open modal
  const handleSelectEvent = (appointment) => {
    const selected = {
      ...appointment,
      user: data.appointments.find((a) => a.id === appointment.id)?.user || {},
    };
    setSelectedAppointment(selected);
    toggle();
  };

  // Filter appointments by status and staff
  const filterAppointments = (appointments) => {
    return appointments.filter((appointment) => {
      const matchesStatus = selectedStatus === 'ALL STATUS' || appointment.status === selectedStatus;
      const matchesStaff = selectedStaff === 'ALL STAFF' || appointment.staffName === selectedStaff;

      return matchesStatus && matchesStaff;
    });
  };

  // Custom event display logic depending on view type
  const eventRenderer = (event, view) => {
    if (view === 'agenda') {
      return `${event.title} (${event.staffName})`;
    }
    const startTime = moment(event.start).format('LT'); // Get only start time
    return `${event.title} (${event.staffName}) - ${startTime}`;
  };

  const eventPropGetter = (event) => {
    let backgroundColor;
    if (event.status === "CANCELLED") {
      backgroundColor = "gray";
    } else if (event.status === "PENDING") {
      backgroundColor = event.color || "#3174ad";
    } else {
      backgroundColor = event.color || "#3174ad";
    }
    const isPast = new Date(event.end) < new Date();
    
    // Apply pulsating effect for pending status
    const className = event.status === "PENDING" && !isPast? "pending-event" : "";
    
    return {
      className, // Add the pulsating class
      style: {
        backgroundColor: isPast ? "lightgray" : backgroundColor,
        opacity: isPast ? 0.5 : 1,
      },
    };
  };
  

  const earliestOpeningTime = Object.values(data.service_providers_by_pk.opening_hours || {}).flat()
    .map(period => moment(period.start, "HH:mm"))
    .sort((a, b) => a.diff(b))[0];
  const minTime = earliestOpeningTime ? earliestOpeningTime.toDate() : new Date(1970, 1, 1, 9, 0, 0);

  const openConfirmModal = (appointmentId, action) => {
    console.log("appointmentId",appointmentId)
    console.log("action",action)

    setSelectedAppointmentId(appointmentId);
    setActionToConfirm(action);
    setConfirmModal(true); // Open the modal
  };
  
  const handleConfirmAction = () => {
    if (actionToConfirm === "COMPLETED") {
      updateAppointmentStatus({
        variables: { id: selectedAppointmentId, status: "COMPLETED" },
      });
    } else if (actionToConfirm === "CANCELLED") {
      updateAppointmentStatus({
        variables: { id: selectedAppointmentId, status: "CANCELLED" },
      });
    } else if (actionToConfirm === "CONFIRMED") {
      console.log("sdsdsdf")
      updateAppointmentStatus({
        variables: { id: selectedAppointmentId, status: "CONFIRMED" },
      });
    }
  console.log("actionToConfirm",actionToConfirm)
    setConfirmModal(false); // Close the modal after action
    toggle()
  };
  

  // Function to mark an appointment as completed
  const handleMarkCompleted = async () => {
    if (selectedAppointment) {
      try {
        await updateAppointmentStatus({ variables: { id: selectedAppointment.id, status: 'COMPLETED' } });
        const updatedAppointments = appointments.map(app =>
          app.id === selectedAppointment.id ? { ...app, status: 'COMPLETED' } : app
        );
        setAppointments(updatedAppointments);
        toggle();
      } catch (error) {
        console.error("Error marking as completed:", error);
      }
    }
  };

  // Function to cancel an appointment
  const handleCancelAppointment = async () => {
    if (selectedAppointment) {
      try {
        await updateAppointmentStatus({ variables: { id: selectedAppointment.id, status: 'CANCELLED' } });
        const updatedAppointments = appointments.map(app =>
          app.id === selectedAppointment.id ? { ...app, status: 'CANCELLED' } : app
        );
        setAppointments(updatedAppointments);
        toggle();
      } catch (error) {
        console.error("Error cancelling appointment:", error);
      }
    }
  };

  // Function to confirm a pending appointment
  const handleConfirmAppointment = async () => {
    if (selectedAppointment) {
      try {
        await updateAppointmentStatus({ variables: { id: selectedAppointment.id, status: 'CONFIRMED' } });
        const updatedAppointments = appointments.map(app =>
          app.id === selectedAppointment.id ? { ...app, status: 'CONFIRMED' } : app
        );
        setAppointments(updatedAppointments);
        toggle();
      } catch (error) {
        console.error("Error confirming appointment:", error);
      }
    }
  };

  // Function to revert status to confirmed
  const handleRevertToConfirmed = async () => {
    if (selectedAppointment) {
      try {
        await updateAppointmentStatus({ variables: { id: selectedAppointment.id, status: 'CONFIRMED' } });
        const updatedAppointments = appointments.map(app =>
          app.id === selectedAppointment.id ? { ...app, status: 'CONFIRMED' } : app
        );
        setAppointments(updatedAppointments);
        toggle();
      } catch (error) {
        console.error("Error reverting appointment to confirmed:", error);
      }
    }
  };

  const filteredAppointments = filterAppointments(appointments);

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.onNavigate('PREV');
    };
  
    const goToNext = () => {
      toolbar.onNavigate('NEXT');
    };
  
    const goToToday = () => {
      toolbar.onNavigate('TODAY');
    };
  
    const handleViewChange = (view) => {
      toolbar.onView(view);
    };
  
    // Custom formatting only for the "day" view, otherwise use default toolbar.label
    const formattedDate =
      toolbar.view === 'day'
        ? format(toolbar.date, 'MMMM dd, yyyy')  // Customize this format as needed
        : toolbar.label;  // For other views, use the default label
  
    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button type="button" onClick={goToBack}>Back</button>
          <button type="button" onClick={goToNext}>Next</button>
          <button type="button" onClick={goToToday}>Today</button>

        </span>
        <span className="rbc-toolbar-label">{formattedDate}</span>
        <span className="rbc-btn-group">
          <button type="button" onClick={() => handleViewChange('month')}>Month</button>
          <button type="button" onClick={() => handleViewChange('week')}>Week</button>
          <button type="button" onClick={() => handleViewChange('day')}>Day</button>
          <button type="button" onClick={() => handleViewChange('agenda')}>Agenda</button>
        </span>
      </div>
    );
  };


  
  // Use a reference to the calendar component

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Calendar")} pageTitle={t("Home")} />

          {/* Align dropdowns next to each other */}
          <Row className="mb-3 d-flex align-items-center">
            <Col md="auto">
              <Dropdown isOpen={statusDropdownOpen} toggle={toggleStatusDropdown}>
                <DropdownToggle color="primary" caret>
                  {selectedStatus}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setSelectedStatus('ALL STATUS')}>ALL STATUS</DropdownItem>
                  <DropdownItem onClick={() => setSelectedStatus('PENDING')}>PENDING</DropdownItem>
                  <DropdownItem onClick={() => setSelectedStatus('CONFIRMED')}>CONFIRMED</DropdownItem>
                  <DropdownItem onClick={() => setSelectedStatus('COMPLETED')}>COMPLETED</DropdownItem>
                  <DropdownItem onClick={() => setSelectedStatus('CANCELLED')}>CANCELLED</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>

            <Col md="auto">
              <Dropdown isOpen={staffDropdownOpen} toggle={toggleStaffDropdown}>
                <DropdownToggle color="secondary" caret>
                  {selectedStaff}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setSelectedStaff('ALL STAFF')}>ALL STAFF</DropdownItem>
                  {data.staff.map(staff => (
                    <DropdownItem key={staff.name} onClick={() => setSelectedStaff(staff.name)}>
                      {staff.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>

          <Card>
            <Calendar
              ref={calendarRef}  // Add this to get a reference to the calendar
              localizer={localizer}
              events={filteredAppointments}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              eventPropGetter={eventPropGetter}
              onSelectEvent={handleSelectEvent}
              formats={formats}
              components={{
                event: ({ event, title }) => CustomEvent({ event, title: eventRenderer(event, view) }),
                toolbar: CustomToolbar,

              }}
              view={view}
              onView={(newView) => {
                setView(newView);
                if (newView === 'day') {
                  setDate(date); // Ensure date is set correctly for day view
                }
              }}
              date={date}  // Ensure that 'date' is passed correctly here
              onNavigate={(newDate) => {
                console.log("date",newDate)
                console.log("date2",date)
                setDate(newDate); // Correctly update the state
              }}
              min={minTime}
              selectable
              onSelectSlot={handleDoubleClickSlot}

            />
          </Card>

          {selectedAppointment && (
  <Modal isOpen={modal} toggle={toggle}>
    <ModalHeader toggle={toggle}>Appointment Details</ModalHeader>
    <ModalBody>
      <p><strong>Service:</strong> {selectedAppointment.title}</p>
      <p><strong>Time:</strong> <strong>{moment(selectedAppointment.start).format('LT')}</strong> - <strong>{moment(selectedAppointment.end).format('LT')}</strong></p>
      <p><strong>Status:</strong> {selectedAppointment.status}</p>
      <p><strong>Staff:</strong> {selectedAppointment.staffName}</p>

  {/* Add user details here */}
  {selectedAppointment.user && (
    <>
      <p><strong>Customer Name:</strong> {selectedAppointment.user.first_name} {selectedAppointment.user.last_name}</p>
      <p><strong>Email:</strong> {selectedAppointment.user.email}</p>
      <p><strong>Phone:</strong> {selectedAppointment.user.phone_number}</p>
      <p><strong>City:</strong> {selectedAppointment.user.address_city}</p>
    </>
  )}
      {/* Action buttons moved inside ModalBody */}
      <div className="action-buttons" style={{ marginTop: '20px' }}>
        {selectedAppointment.status === 'PENDING' && (
          <>
            <Button
              color="success"
              onClick={() => openConfirmModal(selectedAppointment.id,'CONFIRMED')}
              className="mb-2"
              block
            >
              Confirm
            </Button>
            <Button
              color="danger"
              onClick={() => openConfirmModal(selectedAppointment.id,'CANCELLED')}
              className="mb-2"
              block
            >
              Cancel
            </Button>
          </>
        )}
        {selectedAppointment.status === 'CONFIRMED' && (
          <>
            <Button
              color="success"
              onClick={() => openConfirmModal(selectedAppointment.id,'COMPLETED')}
              className="mb-2"
              block
            >
              Mark Completed
            </Button>
            <Button
              color="danger"
              onClick={() => openConfirmModal(selectedAppointment.id,'CANCELLED')}
              className="mb-2"
              block
            >
              Cancel
            </Button>
          </>
        )}
        {selectedAppointment.status === 'COMPLETED' && (
          <Button
            color="primary"
            onClick={() => openConfirmModal(selectedAppointment.id,'CONFIRMED')}
            className="mb-2"
            block
          >
            Revert to Confirmed
          </Button>
        )}
        {selectedAppointment.status === 'CANCELLED' && (
          <Button
            color="primary"
            onClick={() => openConfirmModal(selectedAppointment.id,'CONFIRMED')}
            className="mb-2"
            block
          >
            Revert to Confirmed
          </Button>
        )}
      </div>
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle}>Close</Button>
    </ModalFooter>
  </Modal>
)}


          <Modal isOpen={confirmModal} toggle={() => setConfirmModal(false)}>
  <ModalHeader toggle={() => setConfirmModal(false)}>Confirm Action</ModalHeader>
  <ModalBody>
    Are you sure you want to {actionToConfirm === "COMPLETED" ? "mark as completed" : actionToConfirm === "CANCELLED" ? "cancel" :selectedAppointment?.status==="PENDING"?"confirm": "revert to confirmed"} this appointment?
  </ModalBody>
  <ModalFooter>
    <Button color="primary" onClick={handleConfirmAction}>Yes</Button>{' '}
    <Button color="secondary" onClick={() => setConfirmModal(false)}>No</Button>
  </ModalFooter>
</Modal>

<Modal isOpen={walkInModalOpen} toggle={() => setWalkInModalOpen(false)}>
  <ModalHeader toggle={() => setWalkInModalOpen(false)}>New Walk-In Appointment</ModalHeader>
  <ModalBody>
    {/* Display the selected time slot */}
    {selectedTime && (
      <Alert color="info">
        Selected Time: {selectedTime.toLocaleTimeString()}
      </Alert>
    )}

    {/* Service and Staff Selection */}
    {selectedServices.map((entry, index) => (
      <Row key={index} className="align-items-center mb-3">
        <Col md={5}>
          <Label for={`serviceSelect-${index}`}>Service</Label>
          <Input
            type="select"
            id={`serviceSelect-${index}`}
            value={entry.serviceId || ""}
            onChange={(e) => {
              const newServiceId = parseInt(e.target.value, 10);
              const updatedServices = [...selectedServices];
              updatedServices[index] = { ...updatedServices[index], serviceId: newServiceId, staffId: null };
              setSelectedServices(updatedServices);
            }}
          >
            <option value="" disabled>Select a Service</option>
            {data?.services.map((service) => (
              <option key={service.id} value={service.id}>{service.name}</option>
            ))}
          </Input>
        </Col>

        <Col md={5}>
          <Label for={`staffSelect-${index}`}>Staff</Label>
          <Input
            type="select"
            id={`staffSelect-${index}`}
            value={entry.staffId || ""}
            onChange={(e) => {
              const newStaffId = parseInt(e.target.value, 10);
              const updatedServices = [...selectedServices];
              updatedServices[index] = { ...updatedServices[index], staffId: newStaffId };
              setSelectedServices(updatedServices);
            }}
            disabled={!entry.serviceId}
          >
            <option value="" disabled>Select Staff</option>
            {data?.services.find(service => service.id === entry.serviceId)?.staff_to_service_relationship.map(relation => (
              <option key={relation.staff.id} value={relation.staff.id}>{relation.staff.name}</option>
            ))}
          </Input>
        </Col>

        <Col md={2} className="d-flex justify-content-end">
          <Button color="link" onClick={() => setSelectedServices(selectedServices.filter((_, i) => i !== index))}>
            x
          </Button>
        </Col>
      </Row>
    ))}

    {/* Add Another Service Button */}
    <Button color="success" className="mt-3" onClick={() => setSelectedServices([...selectedServices, { serviceId: null, staffId: null }])}>
      + Add Another Service
    </Button>
  </ModalBody>

  <ModalFooter>
    <Button color="primary" onClick={handleCreateWalkInAppointment}>Confirm</Button>
    <Button color="secondary" onClick={() => setWalkInModalOpen(false)}>Cancel</Button>
  </ModalFooter>
</Modal>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProviderCalendar;
