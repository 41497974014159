import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import go2spin_screenshot from '../../assets/images/Screenshot_Lobby_go2spin.png';
import casinoland_screenshot from '../../assets/images/Screenshot_Lobby_dog.png';
import euroslot_screenshot from '../../assets/images/euroslotlobby.png';
import galaxySlots_screenshot from '../../assets/images/galaxySlots.png';
import palmTreasures_screenshot from '../../assets/images/palmTreasures.png';
import pandaGold_screenshot from '../../assets/images/pandaGold.png';
import gvLine_screenshot from '../../assets/images/gvlive.png';
import gaminator_screenshot from '../../assets/images/gaminator.png';
import islandTreasures_screenshot from '../../assets/images/islandTreasures.png';
import stargames_screenshot from '../../assets/images/stargames.png';
import slomatic_screenshot from '../../assets/images/slomatic.png';
import penalty_screenshot from '../../assets/images/penalty.png';
import aviatorA_screenshot from '../../assets/images/aviator-spribe-game.png';
import aviatorB_screenshot from '../../assets/images/zeppelinScreenshot.png';

import aviator_vertical from '../../assets/images/aviator_vertical.png';
import go2spin_vertical from '../../assets/images/go2spin_vertical.png';
import casinoland_vertical from '../../assets/images/casinoland_vertical.png';
import euroslot_vertical from '../../assets/images/euroslot_vertical.png';



const ImageGallery = () => {
    const [showVertical, setShowVertical] = useState(false);

    const allImages = [
        { src: go2spin_screenshot, name: 'go2spin' },
        { src: casinoland_screenshot, name: 'casinoland' },
        { src: euroslot_screenshot, name: 'euroslot' },
        { src: galaxySlots_screenshot, name: 'galaxySlots' },
        { src: palmTreasures_screenshot, name: 'palmTreasures' },
        { src: pandaGold_screenshot, name: 'Megajack' },
        { src: gvLine_screenshot, name: 'gvonLine' },
        { src: gaminator_screenshot, name: 'gaminator' },
        { src: islandTreasures_screenshot, name: 'islandTreasures' },
        { src: stargames_screenshot, name: 'stargames' },
        { src: slomatic_screenshot, name: 'slomatic' },
        { src: penalty_screenshot, name: 'penalty' },
        { src: aviatorA_screenshot, name: 'aviator' },
        { src: aviatorB_screenshot, name: 'zeppelin' },
        { src: go2spin_vertical, name: 'go2spin' },
        { src: casinoland_vertical, name: 'casinoland' },
        { src: euroslot_vertical, name: 'euroslot' },
        { src: aviator_vertical, name: 'aviator' }
    ];

    const imagesToShow = showVertical
        ? allImages.filter(img => img.src.includes('_vertical'))
        : allImages.filter(img => !img.src.includes('_vertical'));

    return (
        <div className="page-content">
            <FormControlLabel
                control={
                    <Switch
                        checked={showVertical}
                        onChange={() => setShowVertical(!showVertical)}
                        color="primary"
                    />
                }
                label="Vertical"
            />
            {imagesToShow.map((image, index) => (
                <div key={index} style={{ margin: '10px' }}>
                    <h3>{image.name.toUpperCase()}</h3>
                    <img src={image.src} alt={image.name} style={{ maxWidth: '700px', height: 'auto' }} />
                </div>
            ))}
        </div>
    );
};

export default ImageGallery;