import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useQuery, useMutation, gql } from "@apollo/client";
import moment from "moment";

// Define GraphQL Queries and Mutations
const GET_SERVICES_AND_STAFF = gql`
  query GetServicesAndStaff($id: Int!) {
    services(where: { service_provider_id: { _eq: $id } }) {
      id
      name
    }
    staff(where: { service_provider_id: { _eq: $id } }) {
      id
      name
    }
    appointments(where: { staff_to_service_relationship: { service: { service_provider_id: { _eq: $id } } } }) {
      start_time
      end_time
      staff_to_service_relationship {
        service {
          id
          name
        }
        staff {
          id
          name
        }
      }
    }
  }
`;

const CREATE_APPOINTMENT = gql`
  mutation CreateAppointment(
    $start_time: timestamptz!
    $end_time: timestamptz!
    $service_id: Int!
    $staff_id: Int!
    $status: String!
  ) {
    insert_appointments_one(
      object: {
        start_time: $start_time
        end_time: $end_time
        service_id: $service_id
        staff_id: $staff_id
        status: $status
      }
    ) {
      id
    }
  }
`;

const NewWalkInAppointment = () => {
  const serviceProviderId = parseInt(localStorage.getItem("opID"), 10);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedService, setSelectedService] = useState("");
  const [selectedStaff, setSelectedStaff] = useState("");
  const [overlappingAppointment, setOverlappingAppointment] = useState(false);
  const [endTime, setEndTime] = useState(null);

  // Fetch services, staff, and appointments
  const { data, loading, error } = useQuery(GET_SERVICES_AND_STAFF, {
    variables: { id: serviceProviderId },
  });

  const [createAppointment] = useMutation(CREATE_APPOINTMENT);

  useEffect(() => {
    if (!loading && data) {
      checkOverlappingAppointments();
    }
  }, [selectedDate, selectedService, selectedStaff]);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (overlappingAppointment) {
      alert("Time slot is overlapping with another appointment.");
      return;
    }

    try {
      const appointmentEndTime = moment(selectedDate).add(1, "hour").toISOString();
      await createAppointment({
        variables: {
          start_time: selectedDate.toISOString(),
          end_time: appointmentEndTime,
          service_id: parseInt(selectedService, 10),
          staff_id: parseInt(selectedStaff, 10),
          status: "CONFIRMED",
        },
      });

      alert("Appointment created successfully!");
    } catch (error) {
      console.error("Error creating appointment:", error);
      alert("Failed to create appointment.");
    }
  };

  // Function to check for overlapping appointments
  const checkOverlappingAppointments = () => {
    if (!data || !selectedStaff || !selectedDate) return;

    const appointmentEndTime = moment(selectedDate).add(1, "hour").toISOString();
    setEndTime(appointmentEndTime);

    const overlap = data.appointments.some((appointment) => {
      const existingStart = new Date(appointment.start_time);
      const existingEnd = new Date(appointment.end_time);

      return (
        appointment.staff_to_service_relationship.staff.id === parseInt(selectedStaff, 10) &&
        ((selectedDate >= existingStart && selectedDate <= existingEnd) || (appointmentEndTime >= existingStart && appointmentEndTime <= existingEnd))
      );
    });

    setOverlappingAppointment(overlap);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data.</p>;

  return (
    <div className="container">
      <h3>Create New Walk-in Appointment</h3>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="appointmentDate">Appointment Date & Time</Label>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            className="form-control"
          />
        </FormGroup>

        <FormGroup>
          <Label for="service">Service</Label>
          <Input
            type="select"
            name="service"
            id="service"
            value={selectedService}
            onChange={(e) => setSelectedService(e.target.value)}
          >
            <option value="">Select a Service</option>
            {data.services.map((service) => (
              <option key={service.id} value={service.id}>
                {service.name}
              </option>
            ))}
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="staff">Staff</Label>
          <Input
            type="select"
            name="staff"
            id="staff"
            value={selectedStaff}
            onChange={(e) => setSelectedStaff(e.target.value)}
          >
            <option value="">Select a Staff</option>
            {data.staff.map((staffMember) => (
              <option key={staffMember.id} value={staffMember.id}>
                {staffMember.name}
              </option>
            ))}
          </Input>
        </FormGroup>

        {overlappingAppointment && (
          <Alert color="danger">
            Warning: There is already an appointment booked for this staff at the selected time.
          </Alert>
        )}

        <Button color="primary" type="submit">
          Create Appointment
        </Button>
      </Form>
    </div>
  );
};

export default NewWalkInAppointment;