import React, { useEffect,useState } from 'react';
import { useTranslation } from "react-i18next";import { Card,CardHeader, CardBody, Col, Container, Input, Label, Row, Table,Button,Modal, ModalBody, ModalHeader,ModalFooter, Spinner,ButtonGroup} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
// import { Modal, Spinner } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { useQuery,useMutation, gql } from '@apollo/client';
// import checkTokenValidity from '../../Layouts/Footer'
import DataTable from 'react-data-table-component';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, PieController, Tooltip, Legend } from 'chart.js';
import Select from 'react-select';
import {getDescendantsUsernames,formatWithCommas} from "../../Components/Common/functions"

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

function exportData(data, format) {
  const fileName = 'exported_data';

  // Helper function to convert JSON to CSV
  function jsonToCsv(jsonData) {
    const header = Object.keys(jsonData[0]).join(',') + '\n';
    const rows = jsonData.map(row => Object.values(row).join(',')).join('\n');
    return header + rows;
  }

  // Export to clipboard
  function exportToClipboard(content) {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  // Export to CSV
  function exportToCsv(content) {
    const csvData = new Blob([content], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(csvData, fileName + '.csv');
  }

  // Export to Excel
  function exportToExcel(jsonData) {
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName + '.xlsx');
  }

  // Export to PDF
  function exportToPdf(jsonData) {
    const doc = new jsPDF('p', 'pt');
    doc.autoTable({
      head: [Object.keys(jsonData[0])],
      body: jsonData.map(obj => Object.values(obj)),
    });
    doc.save(fileName + '.pdf');
  }
  const csvContent = jsonToCsv(data);

  switch (format) {
    case 'clipboard':
      exportToClipboard(csvContent);
      break;
    case 'csv':
      exportToCsv(csvContent);
      break;
    case 'excel':
      exportToExcel(data);
      break;
    case 'pdf':
      // You can use libraries like jsPDF to generate PDF content from the CSV content.
      // For simplicity, I'm using the CSV content as is, but you may want to format it for PDF.
      exportToPdf(data);
      break;
    default:
      console.error('Unsupported format');
  }
}

Chart.register(ArcElement, PieController, Tooltip, Legend);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // Replace with your actual token
  const token = localStorage.getItem("token");

  // Add custom headers
  return {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem("token"),
    },
  };
});
// Initialize Apollo Client with custom headers
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // cache: 'no-cache'
  cache: new InMemoryCache(),
});
// password
// id
// is_enabled
// is_panic
// current_game
// gp_user_id
// credits
// bonus
// username
const DESCENDANTS_QUERY = gql`
query MyQuery($id: Int!) {
  operators_by_pk(id: $id) {
    descendants
  }
}

`;

// password
// id
// is_enabled
// is_panic
// current_game
// gp_user_id
// credits
// bonus
// username
const SHOPIDS_QUERY = gql`

query MyQuery($_in: [Int!], $_ilike: String = "%%") {
  shops(
    order_by: {id: asc}
    where: {operator_id: {_in: $_in}, gp_cur: {_ilike: $_ilike}}
  ) {
    gp_shop_id
    id
  }
}
`;

const BETS_AND_GAMES_QUERY = gql`
query MyQuery($_in: [Int!]!, $_gte: timestamptz = "", $_lte: timestamptz = "") {
  daily_summary_shops(
    order_by: {date: desc}
    where: {shop_id: {_in: $_in}, date: {_gte: $_gte, _lte: $_lte}}
  ) {
    date
    net_win_lose
    provider
    shop {
      username
      operator {
        id
        username
      }
    }
    shop_id
    total_bet
    total_win_lose
  }
}

`;

const BETS_AND_GAMES_QUERY_TODAY = gql`
query BetsAndGamesQuery($_gte: timestamptz = "", $_lte: timestamptz = "") {
    games {
      gp_game_id
      gp_game_name
      gp_game_category
    }
    bets(where: {gp_date: {_gte: $_gte, _lte: $_lte}}) {
      gp_bet
      gp_win_lose
      gp_game_id
      shop {
        operator {
          id
          username
        }
        id
        username
      }
    }
    aviator_bets(where: {user_id: {_gt: 0}, created_at: {_gte: $_gte, _lte: $_lte}}) {
      id
      stake
      win_lose
      user {
        shop {
          operator {
            id
            username
          }
          id
          username
        }
      }
    }
    gv_bets(where: {date: {_gte: $_gte, _lte: $_lte}}) {
      bet
      win_lose
      shop {
        id
        username
        operator {
          id
          username
        }
      }
    }
  }
`;

const BETS_AND_GAMES_QUERY_OLD = gql`
query BetsAndGamesQuery($_in: [String!] = "", $_gte: timestamptz = "", $_lte: timestamptz = "") {
  games {
    gp_game_id
    gp_game_name
    gp_game_category
  }
  bets(
    where: {gp_shop_id: {_in: $_in}, gp_date: {_gte: $_gte, _lte: $_lte}, shop: {}}
  ) {
    gp_bet
    gp_win_lose
    gp_game_id
    shop {
      operator {
        id
        username
      }
    }
  }
  aviator_bets(where: {user_id: {_gt: 0}, created_at: {_gte: $_gte, _lte: $_lte}}) {
    id
    stake
    win_lose
    user {
      shop {
        operator {
          id
          username
        }
      }
    }
  }
}
`;
const BET_QUERY = gql`
query MyQuery($_eq: Int!, $_in: [String!] = "") {
  bets(where: {gp_shop_id: {_in: $_in}, gp_game_id: {_eq: $_eq}}) {
    gp_bet
    gp_win_lose
  }
}

`;
const GAME_QUERY = gql`
query MyQuery {
  games {
    gp_game_id
    gp_game_name
  }
}
`;
const USER_QUERY = gql`
query MyQuery($_in: [Int!] ) {
  users(order_by: {id: asc}, where: {shop: {operator_id: {_in: $_in}}}) {
    id
    is_enabled
    is_panic
    credits
    username
    name
    last_login
  }
}
`;
const ENABLE_USER_MUTATION = gql`
mutation MyMutation($is_enabled: Boolean, $_in: [Int!] ) {
  update_users(where: {id: {_in: $_in}}, _set: {is_enabled: $is_enabled}) {
    affected_rows
  }
}
`;

const ENABLE_USER_NOTIN = gql`
mutation MyMutation($is_enabled: Boolean = false, $_nin: [Int!] = 10) {
  update_users(where: {id: {_nin: $_nin}}, _set: {is_enabled: $is_enabled}) {
    affected_rows
  }
}
`;

const ENABLE_PANIC_USER = gql`
mutation MyMutation($is_panic: Boolean, $_in: [Int!]) {
  update_users(where: {id: {_in: $_in}}, _set: {is_panic: $is_panic}) {
    affected_rows
  }
}
`;

let theNotSelected=[]
let theFilteredData=[]
let initialRows=[]
const predefinedRanges = [
  {
    label: 'Today',
    value: [new Date(new Date().setUTCHours(0, 0, 0, 0)), new Date(new Date().setUTCHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(new Date().setUTCHours(0, 0, 0, 0)), -1), addDays(new Date(new Date().setUTCHours(23, 59, 59, 999)), -1)],
    placement: 'left'
  },
  {
    label: 'This week',
    value: [startOfWeek(new Date(new Date().setUTCHours(0, 0, 0, 0)),{ weekStartsOn: 1 }), endOfWeek(new Date(new Date().setUTCHours(23, 59, 59, 999)),{ weekStartsOn: 1 })],
    placement: 'left'
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(new Date().setUTCHours(0, 0, 0, 0)), 6), new Date(new Date().setUTCHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(new Date().setUTCHours(0, 0, 0, 0)), 29), new Date(new Date().setUTCHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date(new Date().setUTCHours(0, 0, 0, 0))), new Date(new Date().setUTCHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(new Date().setUTCHours(0, 0, 0, 0)), -1)), endOfMonth(addMonths(new Date(new Date().setUTCHours(23, 59, 59, 999)), -1))],
    placement: 'left'
  },
  {
    label: 'This year',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().setUTCHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Last year',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    placement: 'left'
  },
  {
    label: 'All time',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().setUTCHours(23, 59, 59, 999))],
    placement: 'left'
  },
  {
    label: 'Last week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
      ];
    },
    appearance: 'default'
  },
  {
    label: 'Next week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
      ];
    },
    appearance: 'default'
  }
];
const currencies = [
  { id: 0, name: "ALL" },
  { id: 1, name: "BLR" },
  { id: 2, name: "CHF" },
  { id: 3, name: "EUR" },
  { id: 4, name: "FC" },
  { id: 5, name: "KES" },
  { id: 6, name: "LEU" },
  { id: 7, name: "NGN" },
  { id: 8, name: "PEN" },
  { id: 9, name: "PHP" },
  { id: 10, name: "RMB" },
  { id: 11, name: "TND" },
  { id: 12, name: "TRY" },
  { id: 13, name: "USD" },
  { id: 14, name: "XAF" },
  { id: 15, name: "ZAR" },
  { id: 16, name: "ZMW" },
  { id: 17, name: "ALL" },
];
// const generateTestData = () => {
//   const testData = []
//   for (let i = 0; i <= 2000; i++) {
//     testData.push({
//       bonus: Math.floor(Math.random() * 100),
//       credits: Math.floor(Math.random() * 1000),
//       current_game: `Game ${i}`,
//       gp_user_id: `user${i}`,
//       id: i,
//       is_enabled: Math.random() < 0.5,
//       password: `password${i}`,
//       shop_id: Math.floor(Math.random() * 10),
//       username: `user${i}`,
//     })
//   }
//   return testData
// }

// const shops = [];

// for (let i = 0; i < 2000; i++) {
//   const shop = {
//     bonus_percentage: Math.floor(Math.random() * 100),
//     credits: Math.floor(Math.random() * 1000),
//     currency: Math.random() < 0.5 ? "USD" : "EUR",
//     gp_cur: Math.random() < 0.5 ? "BTC" : "ETH",
//     gp_maxbet: Math.floor(Math.random() * 10000),
//     gp_maxbet_roulette: Math.floor(Math.random() * 10000),
//     gp_maxbet_tablegames: Math.floor(Math.random() * 10000),
//     gp_minbet: Math.floor(Math.random() * 100),
//     gp_minbet_roulette: Math.floor(Math.random() * 100),
//     gp_minbet_tablegames: Math.floor(Math.random() * 100),
//     gp_rtplevel: Math.floor(Math.random() * 10),
//     gp_shop_id: Math.random().toString(36).substring(2, 10),
//     id: i,
//     is_enabled: Math.random() < 0.5,
//     is_panic: Math.random() < 0.5,
//     last_login: new Date().toISOString(),
//     name: Math.random().toString(36).substring(2, 10),
//     operator_id: Math.floor(Math.random() * 1000),
//     password: Math.random().toString(36).substring(2, 10),
//     new_user_limit: Math.floor(Math.random() * 1000),
//     username: Math.random().toString(36).substring(2, 10)
//   };
//   shops.push(shop);
// }

// console.log(shops);




// const transactions = [];

// for (let i = 0; i < 2000; i++) {
//   const transaction = {
//     amount: parseFloat((Math.random() * 1000).toFixed(2)),
//     created_at: new Date().toISOString(),
//     description: Math.random().toString(36).substring(2, 10),
//     from_id: Math.floor(Math.random() * 1000),
//     from_type: Math.floor(Math.random() * 10),
//     from_username: Math.random().toString(36).substring(2, 10),
//     id: i,
//     to_id: Math.floor(Math.random() * 1000),
//     to_type: Math.random() < 0.5 ? "user" : "shop",
//     to_username: Math.random().toString(36).substring(2, 10)
//   };
//   transactions.push(transaction);
// }

// console.log(transactions);




// const testData = generateTestData()
// function compareArrays(arrayA, arrayB) {
//   console.log("resultA",arrayA)
//   const idsB = arrayB.map((item) => item.id);
//   const result = arrayA.filter((item) => !idsB.includes(item.id));
//   console.log("result",result)
//   return result;
// }

const actionObj={1:"Enable Panic ",
2:"Disable Panic",
3:"Enable User",
4:"Disable User"}
            let top10TotalBet = [];
            let top10TotalWinLose = [];
            let top10NetWinLose = [];
let theBetSummary=[]
const RTP = () => {
const { t } = useTranslation();
document.title ="Operators - Providers";
// const { dataUSERS,loadingUSERS,errorUSERS } = useQuery(USER_QUERY);
const [rows, setRows] = useState([])
const [isLoading, setIsLoading] = useState(true)
const [selectedOption, setSelectedOption] = useState('');
const [filterValue, setFilterValue] = useState('');
const [sortState, setSortState] = useState({});
const [isLoadingModal, setIsLoadingModal] = useState(true);
const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedCurrency, setSelectedCurrency] = useState('ALL');
const [startDate,setStartDate] = useState(formatDateToUTCISOString(new Date(new Date().setHours(0, 0, 0, 0))));
const [endDate,setEndDate] = useState(formatDateToUTCISOString(new Date(new Date().setHours(23, 59, 59, 999))));
const [currentOp, setCurrentOp] = useState(null);
const [operatorsDropList, setOperatorsDropList] = useState([]);

function filterResultsByOperatorId(betsSummary, operatorId) {
  return betsSummary.filter(record => record.operatorId === operatorId);
}


const handleChange = (selectedOption) => {
  if (selectedOption) { 
    // If an option is selected
    setCurrentOp(selectedOption.value);
    
    // Filtering the results
    const filteredResults = filterResultsByOperatorId(theBetSummary, selectedOption.value);
    
    // Update rows with the filtered results
    setRows(filteredResults);

    console.log("currentOp", selectedOption.value);
  } else {
    // If the selection is cleared
    setCurrentOp(null);
    
    // Reset rows to the original data
    setRows(theBetSummary);
  }
};


// const [top10TotalBet, settop10TotalBet] = useState([]);
// const [top10TotalWinLose, settop10TotalWinLose] = useState([]);
// const [top10NetWinLose, settop10NetWinLose] = useState([]);

const [dateRange, setDateRange] = useState({
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection'
});


const handleSelect = (date) => {
  setDateRange(date);
};

const handleConfirm = () => {
  // Perform the action you want to execute here
  handleButtonClick()
  setIsModalOpen(false); // Close the modal
};

const handleCancel = () => {
  setIsModalOpen(false); // Close the modal
};

const handleClick = () => {
  if(selectedOption!=0&&selectedRows.length>0){
    setIsModalOpen(true); // Open the modal
  }
};

function transformCreditsToCurrency(credits) {
  const currency = (credits).toFixed(2);
  return currency;
}

const [enableUserMutation, { loading, error }] = useMutation(ENABLE_USER_MUTATION, {
  onCompleted: (data) => {
    console.log(data);
    // Handle the result of the mutation here
  },
  onError: (error) => {
    console.log(error);
    // Handle the error here
  }
});

const [enablePanicUserMutation, { dataAccept1, loadingAccept1, errorAccept1 }] = useMutation(ENABLE_PANIC_USER);



function handleSelectChange(event) {
  const value = event.target.selectedOptions[0].value;
  setSelectedOption(value);
}


const redirectWithId = (id) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set('id', id);
  const params = urlSearchParams.toString();
  // console.log("paransss",params)
  window.location.href = `/#/userDetails?${params}`;
}

const filterTable = (event, originalData, setData) => {
  setFilterValue(event.target.value);
  const searchTerm = event.target.value.toLowerCase()
  const filteredData = originalData.filter((row) => {
    return Object.keys(row).some((key) => {
      const cellValue = String(row[key]).toLowerCase()
      return cellValue.includes(searchTerm)
    })
  })
  // console.log("filteredSData",originalData)
  theFilteredData=filteredData
  setRows(filteredData)
}

function createOperatorSummaryToday(games, bets, aviatorbets,gvbets) {

  // Group bets by operator and aggregate gp_bet and gp_win_lose values
  let operatorSummary = bets.reduce((acc, bet) => {
    if(bet.shop === undefined) return acc;
    // let operatorId = bet.shop.operator.id;
    let shopId = bet.shop.operator.id+"ggslot";

    
    if (!acc[shopId]) {
      acc[shopId] = {
        operatorId:bet.shop.operator.id,
        operatorUsername: bet.shop.operator.username,
        shopUsername:bet.shop.username,
        shopRealId:bet.shop.id,
        provider: "ggslot",
        gp_bet: 0,
        gp_win_lose: 0,
        netWinLose: 0,
      };
    }

    acc[shopId].gp_bet += bet.gp_bet;
    acc[shopId].gp_win_lose += bet.gp_bet - (bet.gp_win_lose + bet.gp_bet);
    acc[shopId].netWinLose += (bet.gp_win_lose + bet.gp_bet);

    return acc;
  }, {});

  // Process aviatorbets
  aviatorbets.forEach(bet => {
    let operatorId = bet.user.shop.operator.id;
    let shopId = bet.user.shop.operator.id+"aviator";

    if (!operatorSummary[shopId]) {
      operatorSummary[shopId] = {
        operatorId:bet.user.shop.operator.id,
        operatorUsername: bet.user.shop.operator.username,
        shopUsername:bet.user.shop.username,
        shopRealId:bet.user.shop.id,
        provider: "aviator",
        gp_bet: 0,
        gp_win_lose: 0,
        netWinLose: 0,
      };
    }

    operatorSummary[shopId].gp_bet += bet.stake  ;
    operatorSummary[shopId].gp_win_lose += bet.stake - (bet.win_lose + bet.stake);
    operatorSummary[shopId].netWinLose += bet.win_lose + bet.stake;
  });

  gvbets.forEach(bet => {
      // let operatorId = bet.user.shop.operator.id;
      let shopId = bet.shop.operator.id+"gvgames";
  
      if (!operatorSummary[shopId]) {
        operatorSummary[shopId] = {
          operatorId:bet.shop.operator.id,
          operatorUsername: bet.shop.operator.username,
          shopUsername:bet.shop.username,
          shopRealId:bet.shop.id,
          provider: "gvgames",
          gp_bet: 0,
          gp_win_lose: 0,
          netWinLose: 0,
        };
      }
  
      operatorSummary[shopId].gp_bet += bet.bet ;
      operatorSummary[shopId].gp_win_lose += bet.bet - (bet.win_lose + bet.bet);
      operatorSummary[shopId].netWinLose += bet.win_lose + bet.bet;
    });

  return Object.entries(operatorSummary).map(([operator, summary]) => ({
    operator,
    operatorId:summary.operatorId,
    operatorUsername: summary.operatorUsername,
    shopUsername:summary.shopUsername,
    shopRealId:summary.shopRealId,

    provider: summary.provider,
    gp_bet: summary.gp_bet,
    gp_win_lose: summary.gp_win_lose,
    netWinLose: summary.netWinLose,
  }));
}

function createOperatorSummary(daily_summaries) {



  let operatorSummary = daily_summaries.reduce((acc, daily) => {
    if(daily.shop === undefined) return acc;
    // let operatorId = bet.shop.operator.id;
    let operatorId = daily.shop.operator.id+daily.provider;

    
    if (!acc[operatorId]) {
      acc[operatorId] = {
        operatorId:daily.shop.operator.id,
        operatorUsername: daily.shop.operator.username,
        provider: daily.provider,
        gp_bet: 0,
        gp_win_lose: 0,
        netWinLose: 0,
      };
    }

    acc[operatorId].gp_bet += daily.total_bet;
    acc[operatorId].gp_win_lose += daily.total_win_lose;
    acc[operatorId].netWinLose += daily.net_win_lose;

    return acc;
  }, {});


  return Object.entries(operatorSummary).map(([operator, summary]) => ({
    operator,
    operatorId:summary.operatorId,
    operatorUsername: summary.operatorUsername,
    provider: summary.provider,
    gp_bet: (summary.gp_bet),
    gp_win_lose: (summary.gp_win_lose),
    netWinLose: (summary.netWinLose),
  }));
}



function mergeSummaries(summary1, summary2) {
  const merged = [...summary1];
console.log("summary1",summary1)
console.log("summary2",summary2)
  for (const item2 of summary2) {
    const index = merged.findIndex(item => item.operator === item2.operator);
    if (index !== -1) {
      // Sum the specified properties if a match is found
      merged[index].gp_bet += item2.gp_bet;
      merged[index].gp_win_lose += item2.gp_win_lose;
      merged[index].netWinLose += item2.netWinLose;
    } else {
      // If no match is found, add the item to the merged array
      merged.push(item2);
    }
  }
  return merged;
}

async function fetchBetsSummary(shopIds) {
  // Check if the dates are for today
  const today = new Date().toISOString().split('T')[0];

  // Convert startDate and endDate to 'YYYY-MM-DD' format
  const startDateStr = new Date(startDate).toISOString().split('T')[0];
  const endDateStr = new Date(endDate).toISOString().split('T')[0];

  // Check if the dates are for today
  const isToday = endDateStr >= today 
  console.log("isToday",isToday)
  let dataToday;
  let data;

  // if (isToday) {
  //   const responseToday = await client.query({
  //     query: BETS_AND_GAMES_QUERY_TODAY,
  //     variables: {
  //       _in: shopIds,
  //       _gte: today,
  //       _lte: endDate,
  //     },
  //     fetchPolicy: 'network-only',

  //   });
  //   dataToday = responseToday.data;
  // } 
    const response = await client.query({
      query: BETS_AND_GAMES_QUERY,
      variables: {
        _in: shopIds,
        _gte: startDate,
        _lte: endDate,
      },
      fetchPolicy: 'network-only',

    });
    data = response.data;
  

  console.log("ta batched data:", data);
  console.log("ta batched dataToday:", dataToday);

  let summaryToday;
  let summary;

  // if (isToday) {
  //   summaryToday = createOperatorSummaryToday(dataToday.games, dataToday.bets, dataToday.aviator_bets, dataToday.gv_bets);
  // } 
    summary = createOperatorSummary(data.daily_summary_shops);
    console.log(summaryToday);
    console.log(summary);


    let finalSummary;
    // if (isToday) {
    //   finalSummary = mergeSummaries(summary, summaryToday);
    // } else {
      finalSummary = summary;
    // }

  finalSummary.sort((a, b) => {
    if (a.operatorId < b.operatorId) return -1;
    if (a.operatorId > b.operatorId) return 1;

    if (a.provider < b.provider) return -1;
    if (a.provider > b.provider) return 1;

    // if (a.shopUsername < b.shopUsername) return -1;
    // if (a.shopUsername > b.shopUsername) return 1;

    return 0;
  });

  console.log(finalSummary);

  return finalSummary;
}

const getTop10 = (property,dataArray) => {
  const sortedArray = dataArray.slice().sort((a, b) => b[property] - a[property]);
  return sortedArray.slice(0, 10);
};
const { refetch } = 
useQuery(
  DESCENDANTS_QUERY,

  {
    onError:(err) =>{
      console.log("jjh"+err)
      // window.location.href = "/#/login"

    },
    variables:  {"id": localStorage.getItem("opID")},

    // variables: {domain_id: {"_eq": locStorageItem}},
    onCompleted: async(data) => {
      console.log("TA DATA:",data)
      if(data.operators_by_pk!=null){
        // console.log("thedescentants",data.operators_by_pk.descendants.push(localStorage.getItem("opID")))
        const theDescendants=data.operators_by_pk.descendants
        await theDescendants.push(localStorage.getItem("opID"))
        const shopsResponse = await client.query({
          query: SHOPIDS_QUERY,
          variables: selectedCurrency==="ALL"?{ "_in": theDescendants, }:{ "_in": theDescendants, _ilike:"%"+selectedCurrency+"%"},
          fetchPolicy: 'network-only', // or 'cache-and-network'
        });        
        console.log("operatorsResponse",shopsResponse)
        if(shopsResponse.data.shops!=undefined){

          const shopIds=shopsResponse.data.shops.map(shop => shop.id);
          console.log("shopIds",shopIds)

          fetchBetsSummary(shopIds).then((betsSummary) => {
            console.log("betsSummary",betsSummary);
            // const searchTerm = filterValue.toLowerCase()
             top10TotalBet = getTop10('gp_bet',betsSummary);
             top10TotalWinLose = getTop10('gp_win_lose',betsSummary);
             top10NetWinLose = getTop10('netWinLose',betsSummary);

            betsSummary.sort((a, b) => b.gp_bet - a.gp_bet);

             setRows([])
             theBetSummary=betsSummary
             setRows(betsSummary)
             setIsLoading(false)
             setIsLoadingModal(false);

          });
        }
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only', // or 'cache-and-network'
    // pollInterval:5000
  }
);
const createPieChartData = (top10Array, property) => {
  return {
    labels: top10Array.map(item => item.category),
    datasets: [
      {
        data: top10Array.map(item => item[property]),
        backgroundColor: [
          // Add colors for the pie chart slices
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#E7E9ED', '#A8B3C5', '#FF3333', '#BBBBBB'
        ]
      }
    ]
  };
};
// const userId = '123'; // This is the ID you want to pass to the next page

//       {/* Link with parameters */}
//       <Link to={`/userDetails/${userId}`}>User Details</Link>

//       {/* Button with parameters */}
//       <button onClick={() => window.location.href = `/#/userDetails/${userId}`}>
//         User Details
//       </button>




//       import React from 'react';
// import { useParams } from 'react-router-dom';

// const UserDetails = () => {
//   const { userId } = useParams();

//   return (
//     <div>
//       <h1>User Details</h1>
//       <p>User ID: {userId}</p>
//       {/* Other user details */}
//     </div>
//   );
// };

// export default UserDetails;

useEffect(() => {
    
  async function fetchData() {
        const operatorsResponse = await client.query({
        query: DESCENDANTS_QUERY,
        variables:  {"id": localStorage.getItem("opID")},
        fetchPolicy: 'network-only', // or 'cache-and-network'
      });        console.log("operatorsResponse",operatorsResponse)
    // await setCurrentOp(null)
    const options=await getDescendantsUsernames(operatorsResponse.data.operators_by_pk.descendants)




    options.unshift({id:parseInt(localStorage.getItem("opID")),username:localStorage.getItem("username")})
    await setOperatorsDropList(options.map((operator) => ({
      value: operator.id,
      label: operator.username,
    })))

  }
  // let theDescendants=localStorage.getItem("descendants")
  // let theUsername=localStorage.getItem("username")

  // console.log("descendants",theDescendants)
  // console.log("theUsername",theUsername)
  // 
  fetchData();

}, []);
const [toggledClearRows, setToggledClearRows] = useState(false);

const [selectedRows, setSelectedRows] = React.useState([]);
// let isAllSelected=false
const dataTableRef = React.createRef();

// const handleClearSelection = () => {
//   setSelectedRows([]); // Clear the selected rows state
//   dataTableRef.current.clearSelectedRows(); // Clear the selected rows in the DataTable component
// };
const handleClearSelection = () => {
  setSelectedRows([]);
  setToggledClearRows(!toggledClearRows);
};


	const handleRowSelected = React.useCallback(state => {
    //mporw apla panta na exw ta selected je ta not selected je na stellw jina pu en liotera xwris na kamnw ulla ta ifs
    // if(state.allSelected){
    //   isAllSelected=true
    //   theNotSelected=[]
    // }else{
    //   if(state.selectedCount==0){
    //     isAllSelected=false
    //   }else{
    //     theNotSelected=compareArrays(theFilteredData,state.selectedRows)
    //   }
    // }
    // setSelectedRows([]);

		setSelectedRows(state.selectedRows);
        console.log("eta rows",state.selectedRows)
        console.log("et state",state)
        console.log("eta not selected",theNotSelected)

	}, []);


  
  const handleEnablePanic = async () => {
    setIsLoadingModal(true);
      const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]
      console.log("the in idArray",idArraysel)
      console.log("selection",selectedOption==1)

      await enablePanicUserMutation({
        variables: {
          is_panic: selectedOption==1, // Set to true to enable the user
          _in: idArraysel // Set to the ID of the user to enable
        }
      });
    // }
    await refetch({})

  };


  const handleEnableUser = async () => {
    setIsLoadingModal(true);

    // if(false&&isAllSelected && theNotSelected.length<selectedRows.length){
    //   const idArray = theNotSelected.map(obj => obj.id); // [1, 2, 3]
    //   console.log("the not in idArray",idArray)
    //   console.log("selection",selectedOption==3)

    //   // selectedOption===3
    //   await enableNOTINUserMutation({
    //     variables: {
    //       is_enabled: selectedOption==3, // Set to true to enable the user
    //       _nin: idArray // Set to the ID of the user to enable
    //     }
    //   });
    // }else{
      const idArraysel = selectedRows.map(obj => obj.id); // [1, 2, 3]
      console.log("the in idArray",idArraysel)
      console.log("selection",selectedOption==3)

      await enableUserMutation({
        variables: {
          is_enabled: selectedOption==3, // Set to true to enable the user
          _in: idArraysel // Set to the ID of the user to enable
        }
      });
    // }
    await refetch({})
  };

    const handleButtonClick = async () => {

    switch(selectedOption) {
      case '1':
        await handleEnablePanic()
        await handleClearSelection()
        console.log('Option 1 selected');
        break;
      case '2':
        await handleEnablePanic()
        await handleClearSelection()
        // perform action for option 2
        console.log('Option 2 selected');
        break;
      case '3':
        await handleEnableUser()
        await handleClearSelection()
        // perform action for option 3
        console.log('Option 3 selected');
        break;
      case '4':
        await handleEnableUser()
        await handleClearSelection()
        // perform action for option 4
        console.log('Option 4 selected');
        break;
      default:
        // do nothing
        break;
    }
    
  }
  const handleSortChange = (column, sortDirection) => {
    setSortState({ column, sortDirection });
  };
  // onClick={handleButtonClick}
  function sortData(data, sortState) {
    const { column, sortDirection } = sortState;
    if (!column || !sortDirection) return data;
  console.log("current column",column)
  console.log("current sortDirection",sortDirection)
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return sortDirection === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  
    return sortedData;
  }

  function formatDateToUTCISOString(dateInput) {
    const date = new Date(dateInput);
    const formattedDate = date.toISOString().replace('Z', '+00:00');;
    return formattedDate;
  }
  function formatDateToUTCISOStringStart(date) {
    date.setUTCHours(0, 0, 0, 0);  // Set time to 00:00
    return date.toISOString();
}

function formatDateToUTCISOStringEnd(date) {
    date.setUTCHours(23, 59, 59, 999);  // Set time to 23:59:59.999
    return date.toISOString();
}
    const handleDateChange = (dates) => {
    if(dates===null){
      return
    }
    console.log("formatDateToUTCISOString(dates[0])",formatDateToUTCISOStringStart(dates[0]))
    console.log("formatDateToUTCISOStringEnd(dates[1])",formatDateToUTCISOStringEnd(dates[1]))
    setIsLoadingModal(true)
    // setSelectedDates(dates);
    setStartDate(formatDateToUTCISOStringStart(dates[0]))
    setEndDate(formatDateToUTCISOStringEnd(dates[1]))
    refetch({})
    // // console.log('Selected Dates:', formatDateToUTCISOString(dates[0]));
    // getSummary(currentOp, formatDateToUTCISOString(dates[0]), formatDateToUTCISOString(dates[1]),selectedCurrency)
    // .then(summary => {setCashRows(summary);setCashFooter([createSummary(summary)]);console.log(summary)})
    // .catch(error => console.error('Error fetching data:', error));
  };
  const customStyles = {
    // header: {
    //   style: {
    //     border: '1px solid #dee2e6'
    //   }
    // },
    cells: {
      style: {
        border: '0.5px solid #dee2e6',
      },
    },
  };

  function formatDate(dateString, timezone) {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: localStorage.getItem("opTimezone"),
    };
    return date.toLocaleString('en-GB', options).replace(',', '');
  }
  const donutOptions = {
    cutout: '50%',
    plugins: {
      legend: {
        position: 'left', // Move the legend to the left side
      },
    },
  };
  
  const columns = [
    // {
    //     name: t("Username"),
    //     sortable: true,
    //     // width: "150px",
    //     sortFunction: (a, b) => a.username.localeCompare(b.username),
    //     selector: (cell) => {return (<Link to={`/userDetails/${cell.id}`} onClick={(e) => {
    //       e.preventDefault();
          
    //       window.location.href = `/#/userDetails/${cell.id}`;
    //     }}
    //     >{cell.username}</Link>);},
        
    // },
    {
        name: t("Operator"),
        // width: "150px",
        selector: row => row.operatorUsername,
        sortable: true
    },
    {
      name: t("Provider"),
      // width: "150px",
      selector: row => row.provider,
      sortable: true
  },
    {
        name: t("Credits In"),
        // width: "150px",
        sortFunction:  (a, b) => (a.gp_bet >= b.gp_bet ? 1 : a.gp_bet == b.gp_bet ? 0 : -1),

        selector: row => formatWithCommas(transformCreditsToCurrency(row.gp_bet/100)),
        sortable: true
    },
    {
      name: t("Credits Out"),
      // width: "150px",
      sortFunction:  (a, b) => (a.netWinLose >= b.netWinLose ? 1 : a.netWinLose == b.netWinLose ? 0 : -1),

      selector: row => formatWithCommas(transformCreditsToCurrency(row.netWinLose/100)),
      sortable: true
  },
  {
    name: t("Credits Diff"),
    // width: "150px",
    sortFunction:  (a, b) => (a.gp_win_lose >= b.gp_win_lose ? 1 : a.gp_win_lose == b.gp_win_lose ? 0 : -1),

    selector: row => formatWithCommas(transformCreditsToCurrency(row.gp_win_lose/100)),
    sortable: true
},
{
  name: t("RTP %"),
  // width: "150px",
  sortFunction:  (a, b) => ((!a.gp_bet<0.00001?((a.netWinLose)/(a.gp_bet)*100):0) >= (!b.gp_bet<0.00001?((b.netWinLose)/(b.gp_bet)*100):0) ? 1 : (!a.gp_bet<0.00001?((a.netWinLose)/(a.gp_bet)*100):0) == (!b.gp_bet<0.00001?((b.netWinLose)/(b.gp_bet)*100):0) ? 0 : -1),
  selector: row => transformCreditsToCurrency(!row.gp_bet<0.00001?((row.netWinLose)/(row.gp_bet)*100):0),
  sortable: true
},
//   {
//     name: t("Spins"),
//     // width: "150px",
//     selector: row => (row.freespins),
//     sortable: true
// },
// {
//   name: t("RTP %"),
//   // width: "150px",
//   selector: row => transformCreditsToCurrency(!row.totalBet<0.00001?((row.netWinLose)/(row.totalBet)*100):0),
//   sortable: true
// },
    // {
    //     name: t("Last login"),
    //     width: "200px",
    //     selector: row => formatDate(row.last_login,'America/New_York'),
    //     sortable: true,

    //     //             style: {
    //     //       minWidth: '200px', // set minimum width
    //     //       // maxWidth: '350px' // set maximum width
              
    //     //     },


    // },
   


];
const handleCurrencyChange = (e) => {
  setIsLoadingModal(true)
  setSelectedCurrency(e.target.value)
  refetch({})
  // getSummary(currentOp, startDate, endDate,e.target.value)
  // .then(summary => {setCashRows(summary);setCashFooter([createSummary(summary)]);console.log(summary)})
  // .catch(error => console.error('Error fetching data:', error));
};
//     const columns = [
//         {
//             name: <span className='font-weight-bold fs-13'>Username</span>,
//             sortable: true,

//             sortFunction: (a, b) => a.username.localeCompare(b.username),
//             selector: (cell) => {return (<Link to={`/userDetails/${cell.id}`} onClick={(e) => {
//               e.preventDefault();
              
//               window.location.href = `/#/userDetails/${cell.id}`;
//             }}
//             >{cell.username}</Link>);},
            
//         },
//         {
//             name: <span className='font-weight-bold fs-13'>Name</span>,
//             selector: row => row.username,
//             sortable: true
//         },
//         {
//             name: <span className='font-weight-bold fs-13'>Credits</span>,
//             selector: row => row.credits,
//             sortable: true
//         },
//         {
//             name: <span  className='font-weight-bold fs-13'>Last login</span>,
//             selector: row => row.last_login,
//             sortable: true,
//             style: {
//               minWidth: '350px', // set minimum width
//               maxWidth: '350px' // set maximum width
              
//             },
//             headerWrapStyles: { 
//               textAlign: "center",
//               minWidth: '350px', // set minimum width
//               maxWidth: '350px' // set maximum width
              
//              }

//         },
//         {
//             name: <span style={{"minWidth":"50px"}} className='font-weight-bold fs-13'>Enabled</span>,
//             sortable: true,
//             sortFunction: (a, b) => (a.is_enabled === b.is_enabled ? 0 : a.is_enabled ? 1 : -1),
//             selector: (cell) => {
//                 return (
//                     <React.Fragment>

//                             {(cell.is_enabled)?<i id="0" className="ri-check-fill" style={{ color: 'green' }}></i>:<i id="1" className=" ri-close-fill" style={{ color: 'red' }}></i>}
                  
//                     </React.Fragment>
//                 );
//             },
//             style: {
//               minWidth: '50px', // set minimum width
//               maxWidth: '50px' // set maximum width
              
//             },
//             headerWrapStyles: { 
//               textAlign: "center",
//               minWidth: '50px', // set minimum width
//               maxWidth: '50px' // set maximum width
              
//              }
//         },
//         {
//             name: <span className='font-weight-bold fs-13'>Panic</span>,
//             sortable: true,
//             sortFunction: (a, b) => (a.is_panic === b.is_panic ? 0 : a.is_panic ? 1 : -1),
//             selector: (cell) => {
//               return (
//                   <React.Fragment>

// {(cell.is_panic)?<i id="0" className="ri-check-fill" style={{ color: 'red' }}></i>:<i id="1" className=" ri-close-fill" style={{ color: 'green' }}></i>}
                
//                   </React.Fragment>
//               );
//           },
//             sortable: true
//         },


//     ];

const rowsPerPageSample={ rowsPerPageText: t('Rows per page:'), rangeSeparatorText: t('of'), noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }

  return (
    <React.Fragment>      
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Operators - Providers")}pageTitle={t("Home")} />
          <Row>
            <Col xs={12}>
            <div style={{paddingBottom: "20px"}}>
      {currencies.map((currency) => (
        <div key={currency.id} className="form-check form-check-inline">
          {/* <Input className="form-check-input" type="radio" name="currency" id={`currency-${currency.id}`} onChange={(e)=>{setSelectedCurrency(e.target.value)}}  value={currency.name} /> */}
          <Input className="form-check-input" type="radio" name="currency" id={`currency-${currency.id}`} onChange={handleCurrencyChange} checked={selectedCurrency===currency.name} value={currency.name} />

          <Label className="form-check-label" htmlFor={`currency-${currency.id}`}>{currency.name}</Label>
        </div>
      ))}
    </div>
    <Row className="g-4 mb-3">

                                            {/* <Col className="col-sm-auto"> */}
                                            <Col md="6">
                                            <DateRangePicker
      ranges={predefinedRanges}
      format="yyyy-MM-dd HH:mm:ss"
      placeholder="Choose Range"
      style={{ width: 600 }}
      onChange={handleDateChange}


    />
                                            </Col>

                                            <Col md={6} lg={4}>
                <Select
                    style={{ backgroundColor: "lightBlue" }} // Set the background color inline
                    // className="form-select mb-4"
                    // aria-label="Default select example"
                    onChange={handleChange}
                    // defaultValue={({value:localStorage.getItem("opID"),label:localStorage.getItem("username")})}
                    options={operatorsDropList}
                    value={operatorsDropList.find((option) => option.value === currentOp)}
                    isSearchable
                    isClearable
                    placeholder="Select Operator..."
                    styles={{ control: (styles) => ({ ...styles, backgroundColor: 'lightBlue' }) }} // Set the background color

                  >
{/* {operatorsDropList.map((operator) => (
    <option key={operator.id} value={operator.id}>{operator.username}</option>
  ))} */}
                  </Select>
                </Col>
                                        </Row>
                                        <ButtonGroup>
                                                    <Button color="light" onClick={()=>{exportData(rows,"clipboard")}}> Copy </Button>
                                                    <Button color="light" onClick={()=>{exportData(rows,"csv")}} > CSV </Button>
                                                    <Button color="light" onClick={()=>{exportData(rows,"excel")}}> Excel </Button>
                                                    <Button color="light" onClick={()=>{exportData(rows,"pdf")}}> PDF </Button>
                                                    {/* <Button color="light" onClick={()=>{exportData(rows,"pdf")}}> PRINT </Button> */}


                                                </ButtonGroup>
            {/* <Row>
            <Col md="4">
            <h5>{t("Top 10 Providers by BETS")}</h5>
      <Pie data={createPieChartData(top10TotalBet, 'gp_bet')} options={donutOptions}/>

            </Col>
            <Col md="4">
            <h5>{t("Top 10 Providers by WINS")}</h5>
      <Pie data={createPieChartData(top10TotalWinLose, 'netWinLose')} options={donutOptions}/>

            </Col>
            <Col md="4">
            <h5>{t("Top 10 Providers by DIFF")}</h5>
      <Pie data={createPieChartData(top10NetWinLose, 'gp_win_lose')} options={donutOptions}/>

            </Col>
            </Row> */}

            {/* <div>



    </div> */}
            <Card>
                                {/* <CardHeader>
                                    <h5 className="card-title mb-0">RTP</h5>
                                </CardHeader> */}
                                <CardBody>


            <DataTable

            onSort={handleSortChange}
            striped
            progressPending={isLoading}
            dense
            highlightOnHover
            columns={columns}
            data={rows}
            // selectableRows
            // onSelectedRowsChange={handleRowSelected}
            pagination
            paginationRowsPerPageOptions={[10, 15, 20,100]}
paginationComponentOptions={rowsPerPageSample}
            clearSelectedRows={toggledClearRows}
            // style={{ borderCollapse: "collapse" }}
            // className="react-data-table"
            customStyles={customStyles}
            // customHeader={<CustomHeader />}

            // npm i react-data-table-component-footer
        />
                                </CardBody>
                            </Card>
            </Col>
          </Row>
        </Container>
        {/* <Modal show={isLoadingModal} backdrop="static" keyboard={false}>
      <Modal.Body className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="primary" />
        <span className="ml-2">Loading...</span>
      </Modal.Body>
    </Modal> */}
    <Modal
                isOpen={isLoadingModal}
                // toggle={() => {
                //   setIsLoadingModal();
                // }}
                backdrop={'static'}
                id="staticBackdrop"
                centered
                size='xs'
            >

                <ModalBody className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
        <span className="ml-2">Loading...</span>
                </ModalBody>
            </Modal>

            <Modal isOpen={isModalOpen} toggle={handleCancel}>
        <ModalHeader toggle={handleCancel}>Confirm</ModalHeader>
        <ModalBody>
          Are you sure you want {actionObj[selectedOption]} for the selected items?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirm}>
            Yes
          </Button>
          <Button color="secondary" onClick={handleCancel}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      </div>

    </React.Fragment>
  );
};

export default RTP; 