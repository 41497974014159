import React from 'react';
import { Col, Container, Row } from 'reactstrap';
// export function checkTokenValidity () {
//     console.log("check validity")
//     const token = localStorage.getItem("token")
//   console.log("the token",token)
//     fetch(process.env.REACT_APP_BACKEND_URL+"/api/frontend/whoami/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json"
//       },
//       body: JSON.stringify({ "token":token })
//     })
//       .then(response => response.json())
//       .then(data => {
//         if (!data || !data.success) {
//           window.location.href = "/login"
//         }
//       })
//       .catch(error => {
//         console.error("Error checking token validity:", error)
//       })
//   }
const Footer = () => {
    // const checkTokenValidity = () => {
    //     const token = localStorage.getItem("token")
      
    //     fetch(process.env.REACT_APP_BACKEND_URL+"/api/frontend/whoami/", {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json"
    //       },
    //       body: JSON.stringify({ token })
    //     })
    //       .then(response => response.json())
    //       .then(data => {
    //         if (!data || !data.success) {
    //         //   window.location.href = "/login"
    //           clearInterval(tokenValidityChecker)
    //         }
    //       })
    //       .catch(error => {
    //         console.error("Error checking token validity:", error)
    //       })
    //   }
// const tokenValidityChecker = setInterval(checkTokenValidity, 10000)


    return (
        <React.Fragment>
            {/* <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            {new Date().getFullYear()} © .
                        </Col>
                        <Col sm={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                Design & Develop by
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer> */}
        </React.Fragment>
    );
};

export default Footer;