import React, { useState, useEffect, useRef } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row, Button } from 'reactstrap';
import { useQuery, useMutation, gql } from '@apollo/client';
import moment from 'moment';
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { useNavigate } from 'react-router-dom';
import notificationSoundFile from '../../assets/sounds/notificationPositive.mp3';

// Sound file (Add a notification sound in your project, or use a URL)
// const notificationSoundFile = "../../assets/sounds/notificationPositive.mp3";

const PENDING_APPOINTMENTS_QUERY = gql`
  query MyQuery($service_provider_id: Int!) {
    appointments(
      where: { 
        status: { _eq: "PENDING" },
        appointment_to_staff_to_service_relationship: { 
          staff_to_service_relationship: { service: { service_provider_id: { _eq: $service_provider_id } } } 
        }
      },
      order_by: { start_time: desc }
    ) {
      id
      start_time
      appointment_to_staff_to_service_relationship {
        staff_to_service_relationship {
          service {
            name
          }
          staff {
            name
          }
        }
      }
    }
  }
`;

const UPDATE_APPOINTMENT_STATUS = gql`
  mutation UpdateAppointmentStatus($id: Int!, $status: String!) {
    update_appointments_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
      id
      status
    }
  }
`;

const NotificationDropdown = () => {
    const serviceProviderId = parseInt(localStorage.getItem("opID"), 10);
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const [previousCount, setPreviousCount] = useState(100000);
    const [isMuted, setIsMuted] = useState(localStorage.getItem('muteNotifications') === 'true');
    const audioRef = useRef(null);
    const navigate = useNavigate();
  
    const toggleNotificationDropdown = () => {
      setIsNotificationDropdown(!isNotificationDropdown);
    };
  
    const toggleMute = () => {
      const newMuteState = !isMuted;
      setIsMuted(newMuteState);
      localStorage.setItem('muteNotifications', newMuteState);
    };
  
    const { data, loading, error, refetch } = useQuery(PENDING_APPOINTMENTS_QUERY, {
      variables: { service_provider_id: serviceProviderId },
      pollInterval: 10000, // Polling every 10 seconds
    });
  
    const [updateAppointmentStatus] = useMutation(UPDATE_APPOINTMENT_STATUS, {
      onCompleted: () => {
        refetch(); // Refetch appointments after mutation completes
      }
    });
  
    const handleConfirmAppointment = async (appointmentId) => {
      try {
        await updateAppointmentStatus({ variables: { id: appointmentId, status: "CONFIRMED" } });
      } catch (error) {
        console.error("Error confirming appointment:", error);
      }
    };
  
    const handleCancelAppointment = async (appointmentId) => {
      try {
        await updateAppointmentStatus({ variables: { id: appointmentId, status: "CANCELLED" } });
      } catch (error) {
        console.error("Error cancelling appointment:", error);
      }
    };
  
    const handleViewDay = (appointment) => {
      localStorage.setItem('selectedAppointment', JSON.stringify(appointment));
      if (window.location.hash === '#/home') {
        window.location.reload();
      } else {
        navigate('/home');
      }
    };
  
    useEffect(() => {
      if (data) {
        const newPendingCount = data.appointments.length;
        if (newPendingCount > previousCount && !isMuted) {
          audioRef.current.play();
        }
        setPreviousCount(newPendingCount);
      }
    }, [data, previousCount, isMuted]);
  
    if (loading) return null;
    if (error) return <p>Error loading appointments</p>;
  
    const pendingAppointments = data.appointments || [];
    const pendingCount = pendingAppointments.length;
  
    return (
      <React.Fragment>
        <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
          <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
            <i className='bx bx-bell fs-22'></i>
            {pendingCount > 0 ? (
              <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                {pendingCount}
                <span className="visually-hidden">unread notifications</span>
              </span>
            ) : null}
          </DropdownToggle>
  
          <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
            <div className="dropdown-head bg-primary bg-pattern rounded-top">
              <div className="p-3">
                <Row className="align-items-center">
                  <Col>
                    <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                  </Col>
                  <Col>
                    <i
                      className={`fs-20 ms-2 ${isMuted ? "bx bx-volume-mute" : "bx bx-volume-full"}`}
                      onClick={toggleMute}
                      style={{ cursor: "pointer", color: "white", marginTop: "5px" }}
                      title={isMuted ? "Unmute" : "Mute"}
                    ></i>
                  </Col>
  
                  <div className="col-auto dropdown-tabs">
                    <span className="badge badge-soft-light fs-13">{pendingCount} New</span>
                  </div>
                </Row>
              </div>
            </div>
  
            <div className="py-2 ps-2">
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                {pendingAppointments.length > 0 ? pendingAppointments.map((appointment) => {
  const time = moment(appointment.start_time).format('MMMM Do YYYY, h:mm A');
  const services = appointment.appointment_to_staff_to_service_relationship.map(
    (rel) => `${rel.staff_to_service_relationship.service.name} (${rel.staff_to_service_relationship.staff.name})`
  ).join(' + '); // Join multiple services with a '+'

  return (
    <div key={appointment.id} className="text-reset notification-item d-block dropdown-item">
      <div className="d-flex">
        <div className="flex-1">
          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
            {appointment.appointment_to_staff_to_service_relationship.length > 1 ? 'COMB: ' : ''}{services}
          </h6>
          <p className="mb-1 fs-13 text-muted">
            Appointment scheduled at {time}
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-around mt-2">
        <Button color="success" onClick={() => handleConfirmAppointment(appointment.id)}>Confirm</Button>
        <Button color="danger" onClick={() => handleCancelAppointment(appointment.id)}>Cancel</Button>
        <Button color="primary" onClick={() => handleViewDay(appointment)}>View Day</Button>
      </div>
    </div>
  );
}) : (
                  <div className="text-center">
                    <h6 className="mt-3 mb-3 fs-13 fw-semibold text-muted">No new appointments</h6>
                  </div>
                )}
              </SimpleBar>
            </div>
          </DropdownMenu>
        </Dropdown>
  
        {/* Hidden audio element for playing the notification sound */}
        <audio ref={audioRef} src={notificationSoundFile} />
        </React.Fragment>
    );
  };
  
  export default NotificationDropdown;
  
