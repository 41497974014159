import flagus from "../assets/images/flags/us.svg";
import flaggreece from "../assets/images/flags/gr.svg";
import flaggermany from "../assets/images/flags/germany.svg";
import flagitaly from "../assets/images/flags/italy.svg";
import flagalbania from "../assets/images/flags/al.svg";
import flagbosnia from "../assets/images/flags/ba.svg";
import flagrussia from "../assets/images/flags/russia.svg";
import flagspain from "../assets/images/flags/spain.svg";
import flagturkish from "../assets/images/flags/tr.svg";

// import flagfrench from "../assets/images/flags/french.svg";
// import flagarabic from "../assets/images/flags/ar.svg";


const languages = {
  en: {
    label: "English",
    flag: flagus,
  },

  gr: {
    label: "Ελληνικά",
    flag: flaggreece,
  },
  de: {
    label: "Deutsche",
    flag: flaggermany,
  },
  it: {
    label: "Italiana",
    flag: flagitaly,
  },
  al: {
    label: "Shqiptare",
    flag: flagalbania,
  },
  ba: {
    label: "Bosanski",
    flag: flagbosnia,
  },
  rs: {
    label: "русский",
    flag: flagrussia,
  },
  sp: {
    label: "Española",
    flag: flagspain,
  },
  tr: {
    label: "Türkçe",
    flag: flagturkish,
  },
}

export default languages
