import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Input,
  Label,
  Alert,Modal, ModalBody, ModalFooter,
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { useQuery, useMutation, gql } from '@apollo/client';
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaYoutube, FaTiktok } from 'react-icons/fa';

// Query for fetching service provider profile data
const SERVICE_PROVIDER_QUERY = gql`
  query GetServiceProvider($id: Int!) {
    service_providers_by_pk(id: $id) {
      about_us
      email
      opening_hours
      password
      social_media_links
      photos
      phone
      description
    }
  }
`;

// Mutation for updating service provider details
const UPDATE_SERVICE_PROVIDER_MUTATION = gql`
  mutation UpdateServiceProvider($id: Int!, $data: service_providers_set_input!) {
    update_service_providers_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

const ProfilePage = () => {
  const serviceProviderId = parseInt(localStorage.getItem('opID'), 10);
  const [open, setOpen] = useState('1'); // Info section will be open by default
  const [info, setInfo] = useState({ email: '', phone: '', description: '', password: '' });
  const [openingHours, setOpeningHours] = useState({});
  const [socialLinks, setSocialLinks] = useState({ facebook: '', instagram: '' });
  const [photos, setPhotos] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  // Fetching service provider profile data
  const { data, loading, error, refetch } = useQuery(SERVICE_PROVIDER_QUERY, {
    variables: { id: serviceProviderId },
    onCompleted: (data) => {
      if (data) {
        const profile = data.service_providers_by_pk;
        console.log("profile",profile)

        setInfo({
          email: profile.email,
          phone: profile.phone,
          description: profile.description,
          password: profile.password
        });
        setOpeningHours(profile.opening_hours);
        const socialMediaLinks = (profile.social_media_links || []).reduce((acc, link) => {
          if (link) { // Ensure the link is not null or undefined
            if (link.includes('facebook')) {
              acc.facebook = link;
            } else if (link.includes('instagram')) {
              acc.instagram = link;
            } else if (link.includes('twitter') || link.includes('x.com')) {
              acc.x = link;  // Handle both Twitter and X URLs
            } else if (link.includes('linkedin')) {
              acc.linkedin = link;
            } else if (link.includes('youtube')) {
              acc.youtube = link;
            } else if (link.includes('tiktok')) {
              acc.tiktok = link;
            }
          }
          return acc;
        }, {
          facebook: '',
          instagram: '',
          x: '',
          linkedin: '',
          youtube: '',
          tiktok: ''
        });
        setSocialLinks(socialMediaLinks);

        setPhotos(profile.photos);
      }
    },
  });

  // Mutation to update service provider details
  const [updateServiceProvider] = useMutation(UPDATE_SERVICE_PROVIDER_MUTATION, {
    onCompleted: () => {
      setShowSuccess(true);
      refetch(); // Refetch the profile data after update
    },
    onError: (error) => {
      console.error('Error updating profile:', error);
    }
  });

  // Handle accordion toggle
  const toggle = (id) => {
    setOpen(open === id ? '' : id); // Toggle open/close
  };

  // Save functions
  const handleSaveInfo = () => {
    updateServiceProvider({
      variables: {
        id: serviceProviderId,
        data: {
          email: info.email,
          phone: info.phone,
          description: info.description,
          password: info.password
        }
      }
    });
  };

  const handleSaveOpeningHours = () => {
    console.log("openingHours",openingHours)
    
    // Validate for overlapping time slots
    if (checkOverlap(openingHours)) {
      alert('Overlapping time slots detected. Please adjust the times.');
      return;
    }
    updateServiceProvider({
      variables: {
        id: serviceProviderId,
        data: { opening_hours: openingHours }
      }
    });
  };

  const handleSaveSocialLinks = () => {
    console.log(socialLinks)
    updateServiceProvider({
      variables: {
        id: serviceProviderId,
        data: {
          social_media_links: [
            socialLinks.facebook,
            socialLinks.instagram,
            socialLinks.x,
            socialLinks.linkedin,
            socialLinks.youtube,
            socialLinks.tiktok,
          ],
        },
      },
    });
  };

// GraphQL Mutations (now handling jsonb type)
const UPDATE_PHOTOS_MUTATION = gql`
  mutation UpdatePhotos($id: Int!, $photos: jsonb!) {
    update_service_providers_by_pk(pk_columns: { id: $id }, _set: { photos: $photos }) {
      id
      photos
    }
  }
`;

const DELETE_IMAGE_MUTATION = gql`
  mutation DeleteImage($id: Int!, $photos: jsonb!) {
    update_service_providers_by_pk(pk_columns: { id: $id }, _set: { photos: $photos }) {
      id
      photos
    }
  }
`;

const ImageGallery = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null); // Track the image to delete
  const [deleteModal, setDeleteModal] = useState(false); // Modal visibility state

  // Mutation to update photos after upload
  const [updatePhotos] = useMutation(UPDATE_PHOTOS_MUTATION);

  // Mutation to delete photo
  const [deleteImageMutation] = useMutation(DELETE_IMAGE_MUTATION);

  // Handle file selection and preview
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages(files);

    const previews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  // Handle image upload
  const handleImageUpload = async () => {
    if (selectedImages.length === 0) return;

    setIsLoading(true);
    const YOUR_API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/upload/`;
    const token = localStorage.getItem("token");

    const uploadPromises = selectedImages.map(async (file) => {
      const formData = new FormData();
      formData.append("myfile", file);
      formData.append("token", token);

      const GraphQLrequestOptions = {
        method: "POST",
        headers: { authorization: token },
        body: formData,
      };

      try {
        const response = await fetch(YOUR_API_URL, GraphQLrequestOptions);
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || "Failed to upload");
        }

        return data.url; // Return the uploaded image URL
      } catch (error) {
        console.error("Error uploading file:", error);
        return null;
      }
    });

    const uploadedUrls = await Promise.all(uploadPromises);
    const validUrls = uploadedUrls.filter((url) => url); // Remove null values

    // Update photos state and send mutation to backend
    const updatedPhotos = [...photos, ...validUrls];
    setPhotos(updatedPhotos);

    // Call the mutation to save updated photos in the backend
    await updatePhotos({
      variables: {
        id: serviceProviderId,
        photos: updatedPhotos,
      },
    });

    setIsLoading(false);
  };

  // Show delete confirmation modal
  const handleDeleteClick = (image) => {
    setImageToDelete(image);
    setDeleteModal(true); // Show the modal
  };

  // Handle image deletion
  const handleImageDelete = async () => {
    setIsLoading(true);

    const updatedPhotos = photos.filter((photo) => photo !== imageToDelete); // Remove the image from state

    // Call the mutation to update the photos field in the backend
    await deleteImageMutation({
      variables: {
        id: serviceProviderId,
        photos: updatedPhotos,
      },
    });

    // Update the photos state locally
    setPhotos(updatedPhotos);
    setDeleteModal(false);
    setIsLoading(false);
  };

  return (
    <div>
      <h5>Image Gallery</h5>

     {/* Display existing images */}
      <div className="image-gallery">
        {photos.map((photo, index) => (
          <div key={index} className="image-container">
            <img
              src={photo}
              alt={`Photo ${index}`}
              style={{ width: "100px", marginRight: "10px" }}
            />
                          

            <button className="delete-button" onClick={() => handleDeleteClick(photo)}>
              &times;
            </button>
          </div>
        ))}
      </div>

      {/* File input */}
      <div className="mt-3">
        <input type="file" multiple accept="image/*" onChange={handleFileChange} />
      </div>

      {/* Preview selected images */}
      <div className="image-previews mt-2">
        {imagePreviews.map((src, index) => (
          <div key={index} className="image-container">
            <img src={src} alt={`Preview ${index}`} style={{ width: "100px", marginRight: "10px" }} />
            {/* <button className="delete-button">&times;</button> */}
          </div>
        ))}
      </div>

      {/* Upload Button */}
      <Button color="primary" onClick={handleImageUpload} disabled={isLoading}>
        {isLoading ? "Uploading..." : "Upload Images"}
      </Button>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)}>
        <ModalBody>Are you sure you want to delete this image?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleImageDelete} disabled={isLoading}>
            {isLoading ? "Deleting..." : "Yes"}
          </Button>{" "}
          <Button color="secondary" onClick={() => setDeleteModal(false)}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
  

  // Function to check overlap in time slots
  const checkOverlap = (hours) => {
    for (const day in hours) {
      const slots = hours[day];
      for (let i = 0; i < slots.length; i++) {
        for (let j = i + 1; j < slots.length; j++) {
          const start1 = new Date(`1970-01-01T${slots[i].start}:00`).getTime();
          const end1 = new Date(`1970-01-01T${slots[i].end}:00`).getTime();
          const start2 = new Date(`1970-01-01T${slots[j].start}:00`).getTime();
          const end2 = new Date(`1970-01-01T${slots[j].end}:00`).getTime();

          if ((start1 < end2 && start2 < end1) || (start2 < end1 && start1 < end2)) {
            return true; // Overlap found
          }
        }
      }
    }
    return false; // No overlap
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Profile" pageTitle="Home" />
          <Card>
            <CardBody>
              {/* Success Alert */}
              {showSuccess && <Alert color="success">Changes saved successfully!</Alert>}

              <Accordion open={open} toggle={toggle}>
                {/* Info Section */}
                <AccordionItem>
                  <AccordionHeader targetId="1">Info</AccordionHeader>
                  <AccordionBody accordionId="1">
                    <Row className="mb-3">
                      <Col md={6}>
                        <Label>Email</Label>
                        <Input
                          type="email"
                          value={info.email}
                          onChange={(e) => setInfo({ ...info, email: e.target.value })}
                          disabled
                        />
                      </Col>
                      <Col md={6}>
                        <Label>Phone</Label>
                        <Input
                          type="text"
                          value={info.phone}
                          onChange={(e) => setInfo({ ...info, phone: e.target.value })}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={6}>
                        <Label>Password</Label>
                        <Input
                          type="password"
                          value={info.password}
                          onChange={(e) => setInfo({ ...info, password: e.target.value })}
                        />
                      </Col>
                      <Col md={6}>
                        <Label>Description</Label>
                        <Input
                          type="textarea"
                          value={info.description}
                          onChange={(e) => setInfo({ ...info, description: e.target.value })}
                        />
                      </Col>
                    </Row>
                    <Button color="primary" onClick={handleSaveInfo}>
                      Save Changes
                    </Button>
                  </AccordionBody>
                </AccordionItem>

                {/* Opening Hours Section */}
                <AccordionItem>
                  <AccordionHeader targetId="2">Opening Hours</AccordionHeader>
                  <AccordionBody accordionId="2">
                    {/* Opening Hours Component */}
                    <OpeningHours hours={openingHours} setHours={setOpeningHours} />
                    <Button color="primary" onClick={handleSaveOpeningHours}>
                      Save Changes
                    </Button>
                    {showSuccess && <Alert color="success">Changes saved successfully!</Alert>}

                  </AccordionBody>
                </AccordionItem>

                {/* Gallery Section */}
                <AccordionItem>
                  <AccordionHeader targetId="3">Gallery</AccordionHeader>
                  <AccordionBody accordionId="3">
                    <ImageGallery />
                  </AccordionBody>
                </AccordionItem>

                {/* Social Media Links Section */}
                ;

<AccordionItem>
  <AccordionHeader targetId="4">Social Media Links</AccordionHeader>
  <AccordionBody accordionId="4">
    <Row className="mb-3">
      <Col md={6}>
        <Label>
          <FaFacebook style={{ marginRight: '10px' }} />
          Facebook
        </Label>
        <Input
          type="url"
          value={socialLinks.facebook}
          onChange={(e) => setSocialLinks({ ...socialLinks, facebook: e.target.value })}
          placeholder="https://www.facebook.com/yourprofile"
        />
      </Col>
      <Col md={6}>
        <Label>
          <FaInstagram style={{ marginRight: '10px' }} />
          Instagram
        </Label>
        <Input
          type="url"
          value={socialLinks.instagram}
          onChange={(e) => setSocialLinks({ ...socialLinks, instagram: e.target.value })}
          placeholder="https://www.instagram.com/yourprofile"
        />
      </Col>
    </Row>

    <Row className="mb-3">
      <Col md={6}>
        <Label>
          {/* <FaTwitter style={{ marginRight: '10px' }} /> */}
          X
        </Label>
        <Input
          type="url"
          value={socialLinks.x}
          onChange={(e) => setSocialLinks({ ...socialLinks, x: e.target.value })}
          placeholder="https://www.x.com/yourprofile"
        />
      </Col>
      <Col md={6}>
        <Label>
          <FaLinkedin style={{ marginRight: '10px' }} />
          LinkedIn
        </Label>
        <Input
          type="url"
          value={socialLinks.linkedin}
          onChange={(e) => setSocialLinks({ ...socialLinks, linkedin: e.target.value })}
          placeholder="https://www.linkedin.com/in/yourprofile"
        />
      </Col>
    </Row>

    <Row className="mb-3">
      <Col md={6}>
        <Label>
          <FaYoutube style={{ marginRight: '10px' }} />
          YouTube
        </Label>
        <Input
          type="url"
          value={socialLinks.youtube}
          onChange={(e) => setSocialLinks({ ...socialLinks, youtube: e.target.value })}
          placeholder="https://www.youtube.com/channel/yourprofile"
        />
      </Col>
      <Col md={6}>
        <Label>
          <FaTiktok style={{ marginRight: '10px' }} />
          TikTok
        </Label>
        <Input
          type="url"
          value={socialLinks.tiktok}
          onChange={(e) => setSocialLinks({ ...socialLinks, tiktok: e.target.value })}
          placeholder="https://www.tiktok.com/@yourprofile"
        />
      </Col>
    </Row>

    <Button color="primary" onClick={handleSaveSocialLinks}>
      Save Changes
    </Button>
    {showSuccess && <Alert color="success">Changes saved successfully!</Alert>}

  </AccordionBody>
</AccordionItem>
              </Accordion>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

// Opening Hours Component
const OpeningHours = ({ hours, setHours }) => {
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const [slotChanged, setSlotChanged] = useState({}); // Track changes for each slot
  const [newSlots, setNewSlots] = useState({}); // Track new slots

  // Handle adding a new slot with default values
  const handleAddSlot = (day) => {
    const newHours = { ...hours }; // Make a copy of the hours object
    if (!newHours[day]) newHours[day] = []; // Initialize array for the day if empty
    newHours[day] = [...newHours[day], { start: '09:00', end: '17:00' }]; // Add a new slot
    setHours(newHours); // Update the state

    // Mark this slot as a new slot that can be edited
    setNewSlots((prev) => ({
      ...prev,
      [`${day}-${newHours[day].length - 1}`]: true,
    }));
  };

  // Handle removing a slot
  const handleRemoveSlot = (day, index) => {
    const newHours = { ...hours }; // Make a copy of the hours object
    if (newHours[day] && newHours[day][index]) {
      const updatedDaySlots = Array.from(newHours[day]); // Make a copy of the day's slots array
      updatedDaySlots.splice(index, 1); // Remove the slot at the given index
      newHours[day] = updatedDaySlots; // Update the day's slots
      setHours(newHours); // Update the state

      // Remove from new slots and slot changed trackers
      setNewSlots((prev) => {
        const updatedNewSlots = { ...prev };
        delete updatedNewSlots[`${day}-${index}`];
        return updatedNewSlots;
      });

      setSlotChanged((prev) => {
        const updatedSlotChanged = { ...prev };
        delete updatedSlotChanged[`${day}-${index}`];
        return updatedSlotChanged;
      });
    }
  };

  // Handle time change and mark the slot as changed
  const handleTimeChange = (day, index, type, value) => {
    const newHours = { ...hours }; // Make a copy of the hours object
    const updatedDaySlots = Array.from(newHours[day]); // Make a copy of the day's slots array
    updatedDaySlots[index][type] = value; // Update the time (either 'start' or 'end')
    newHours[day] = updatedDaySlots; // Update the day's slots in hours
    setHours(newHours); // Update the state

    // Mark the slot as changed locally
    setSlotChanged((prev) => ({
      ...prev,
      [`${day}-${index}`]: true,
    }));
  };

  return (
    <div>
      {daysOfWeek.map((day) => (
        <div key={day}>
          <strong>{day}:</strong>
          {hours[day] && hours[day].length > 0 ? (
            hours[day].map((slot, index) => (
              <div key={index} className="slot d-flex align-items-center">
                <Input
                  type="time"
                  value={slot.start}
                  onChange={(e) => handleTimeChange(day, index, 'start', e.target.value)}
                  disabled={!newSlots[`${day}-${index}`]} // Disable for existing slots
                  className="timepicker"
                />
                <span className="mx-2">-</span>
                <Input
                  type="time"
                  value={slot.end}
                  onChange={(e) => handleTimeChange(day, index, 'end', e.target.value)}
                  disabled={!newSlots[`${day}-${index}`]} // Disable for existing slots
                  className="timepicker"
                />
                <Button color="danger" onClick={() => handleRemoveSlot(day, index)} className="ms-2">
                  X
                </Button>
              </div>
            ))
          ) : (
            <p>No slots available</p>
          )}
          <Button color="link" onClick={() => handleAddSlot(day)}>
            + Add Slot
          </Button>
        </div>
      ))}
    </div>
  );
};




export default ProfilePage;
