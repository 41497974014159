import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Card, CardBody, Col, Container, Row, Button, Modal, ModalBody, ModalHeader, ModalFooter, Input, Label, Alert
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from 'react-data-table-component';
import { useQuery, useMutation, gql } from '@apollo/client';
import { FiPlus, FiCheckCircle, FiXCircle } from 'react-icons/fi';
import { DateRangePicker } from 'rsuite';  // Use rsuite DateRangePicker
import 'rsuite/dist/rsuite.min.css'; // Import rsuite CSS
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // Replace with your actual token
  const token = localStorage.getItem("token");

  // Add custom headers
  return {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'authorization': localStorage.getItem("token"),
    },
  };
});
// Initialize Apollo Client with custom headers
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // cache: 'no-cache'
  cache: new InMemoryCache(),
});

const SERVICES_QUERY = gql`
query MyQuery($serviceProviderId: Int!) {
  services(where: {service_provider_id: {_eq: $serviceProviderId}} order_by: {id: asc}) {
    id
    description
    is_enabled
    name
    price
    color
    duration
    is_popular
    staff_to_service_relationship {
      id
      staff {
        name
        id
      }
    }
    promotion {
      id
      amount
      description
      end_date
      is_percentage
      start_date
    }
  }
}
`;

const STAFF_QUERY = gql`
query MyQuery($serviceProviderId: Int!) {
  staff(where: {service_provider_id: {_eq: $serviceProviderId}}) {
    id
    name
  }
}
`;

const UPDATE_SERVICE_MUTATION = gql`
mutation UpdateService($id: Int!, $name: String!, $description: String!, $price: Int!, $duration: Int!, $color: String!, $is_popular: Boolean!, $is_enabled: Boolean!) {
  update_services_by_pk(pk_columns: {id: $id}, _set: {name: $name, description: $description, price: $price, duration: $duration, color: $color, is_popular: $is_popular, is_enabled: $is_enabled}) {
    id
  }
}
`;

const DELETE_RELATIONSHIPS_MUTATION = gql`
mutation DeleteStaffServiceRelationships($service_id: Int!, $staff_ids: [Int!]) {
  delete_staff_to_service_relationship(where: {service_id: {_eq: $service_id}, staff_id: {_in: $staff_ids}}) {
    affected_rows
  }
}
`;


const INSERT_RELATIONSHIPS_MUTATION = gql`
mutation InsertStaffServiceRelationships($relationships: [staff_to_service_relationship_insert_input!]!) {
  insert_staff_to_service_relationship(objects: $relationships) {
    affected_rows
  }
}
`;

const CREATE_PROMOTION_MUTATION = gql`
mutation CreatePromotion($service_id: Int!, $amount: Int!, $description: String!, $is_percentage: Boolean!, $start_date: timestamptz!, $end_date: timestamptz!) {
  insert_promotions_one(object: {service_id: $service_id, amount: $amount, description: $description, is_percentage: $is_percentage, start_date: $start_date, end_date: $end_date}) {
    id
  }
}
`;

const UPDATE_PROMOTION_MUTATION = gql`
mutation UpdatePromotion($id: Int!, $amount: Int!, $description: String!, $is_percentage: Boolean!, $start_date: timestamptz!, $end_date: timestamptz!) {
  update_promotions_by_pk(pk_columns: {id: $id}, _set: {amount: $amount, description: $description, is_percentage: $is_percentage, start_date: $start_date, end_date: $end_date}) {
    id
  }
}
`;

const Starter = () => {
  const { t } = useTranslation();
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedService, setSelectedService] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [promoModalOpen, setPromoModalOpen] = useState(false); 
  const [staffList, setStaffList] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const serviceProviderId = parseInt(localStorage.getItem("opID"));

  const { loading, data, refetch } = useQuery(SERVICES_QUERY, {
    variables: { serviceProviderId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.services) {
        setFilteredData(data.services);
      }
    },
  });

  const { loading: loadingStaff, data: staffData } = useQuery(STAFF_QUERY, {
    variables: { serviceProviderId },
    fetchPolicy: 'network-only',
    onCompleted: (staffData) => {
      if (staffData && staffData.staff) {
        setStaffList(staffData.staff);
      }
    }
  });

  const [createPromotion] = useMutation(CREATE_PROMOTION_MUTATION, {
    onCompleted: () => {
      refetch();
      setPromoModalOpen(false);
    }
  });

  const [updatePromotion] = useMutation(UPDATE_PROMOTION_MUTATION, {
    onCompleted: () => {
      refetch();
      setPromoModalOpen(false);
    }
  });

  const [updateService] = useMutation(UPDATE_SERVICE_MUTATION, {
    onCompleted: () => {
      setShowSuccess(true);
      refetch();
    }
  });

  const [deleteRelationships] = useMutation(DELETE_RELATIONSHIPS_MUTATION);
  const [insertRelationships] = useMutation(INSERT_RELATIONSHIPS_MUTATION);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = data.services.filter(service => 
      service.name.toLowerCase().includes(value) ||
      service.description.toLowerCase().includes(value) ||
      service.price.toString().includes(value)
    );
    setFilteredData(filtered);
  };

  const handleEditService = (service) => {
    setSelectedService({
      ...service,
      staffIds: service.staff_to_service_relationship.map(rel => rel.staff.id),
    });
    setSelectedStaff(service.staff_to_service_relationship.map(rel => rel.staff.id));
    setModalOpen(true);
  };

  const handleUpdateService = async () => {
    const current_time = new Date().toISOString();
  
    const newStaffIds = selectedStaff;
    const originalStaffIds = selectedService.staff_to_service_relationship.map(rel => rel.staff.id);
  
    const staffToRemove = originalStaffIds.filter(id => !newStaffIds.includes(id));
    const staffToAdd = newStaffIds.filter(id => !originalStaffIds.includes(id));
  
    // Check for future confirmed appointments for staff being removed and related to this specific service
    if (staffToRemove.length > 0) {
      const { data: confirmedAppointments } = await client.query({
        query: gql`
          query ConfirmedAppointments($service_id: Int!, $staff_ids: [Int!]!, $current_time: timestamptz!) {
            appointments(where: {
              staff_to_service_relationship: {
                service_id: {_eq: $service_id}, 
                staff_id: {_in: $staff_ids}
              },
              status: {_eq: "CONFIRMED"},
              start_time: {_gt: $current_time}
            }) {
              id
              start_time
              staff_to_service_relationship {
                staff {
                  name
                }
                service {
                  name
                }
              }
            }
          }
        `,
        variables: { service_id: selectedService.id, staff_ids: staffToRemove, current_time }, // Only check for this service
      });
  
      if (confirmedAppointments.appointments.length > 0) {
        const staffNames = confirmedAppointments.appointments
          .map(app => `${app.staff_to_service_relationship.staff.name} (${app.staff_to_service_relationship.service.name})`)
          .join(", ");
        alert(`There are future confirmed appointments for staff: ${staffNames} related to this service. Please settle them before reassigning staff.`);
        return;
      }
    }
  
    try {
      // Delete relationships for removed staff
      if (staffToRemove.length > 0) {
        await deleteRelationships({ variables: { service_id: selectedService.id, staff_ids: staffToRemove } });
      }
  
      // Insert new relationships for added staff
      if (staffToAdd.length > 0) {
        const relationships = staffToAdd.map(staffId => ({
          staff_id: staffId,
          service_id: selectedService.id,
        }));
        await insertRelationships({ variables: { relationships } });
      }
  
      // Update the service details
      await updateService({
        variables: {
          id: selectedService.id,
          name: selectedService.name,
          description: selectedService.description,
          price: parseInt(selectedService.price, 10),
          duration: parseInt(selectedService.duration, 10),
          color: selectedService.color,
          is_popular: selectedService.is_popular,
          is_enabled: selectedService.is_enabled,
        },
      });
  
      setModalOpen(false);
    } catch (error) {
      console.error("Error updating service:", error);
    }
  };
  
  

  const handlePromotionClick = (promotion, serviceId) => {
    if (promotion) {
      setSelectedPromotion({ ...promotion, service_id: serviceId });
      setDateRange([new Date(promotion.start_date), new Date(promotion.end_date)]);
    } else {
      setSelectedPromotion({ service_id: serviceId, amount: '', description: '', is_percentage: true });
      setDateRange([new Date(), new Date()]);
    }
    setPromoModalOpen(true);
  };

  const handlePromotionSave = () => {
    const { id, amount, description, is_percentage, service_id } = selectedPromotion;
    if (id) {
      updatePromotion({ variables: { id, amount: parseInt(amount, 10), description, is_percentage, start_date: dateRange[0], end_date: dateRange[1] } });
    } else {
      createPromotion({ variables: { service_id, amount: parseInt(amount, 10), description, is_percentage, start_date: dateRange[0], end_date: dateRange[1] } });
    }
  };

  const calculateDiscountedPrice = (service) => {
    if (service.promotion) {
      const { amount, is_percentage } = service.promotion;
      if (is_percentage) {
        return service.price - (service.price * amount / 100);
      }
      return service.price - amount;
    }
    return service.price;
  };

  useEffect(() => {
    if (showSuccess) {
      const timer = setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
      return () => clearTimeout(timer); // Cleanup timeout on unmount or re-trigger
    }
  }, [showSuccess]);

  const columns = [
    {
      name: t("Name"),
      sortable: true,
      selector: (cell) => (
        <Button color="link" onClick={() => handleEditService(cell)}>
          {cell.name}
        </Button>
      ),
    },
    {
      name: t("Description"),
      selector: row => row.description,
      sortable: true,
    },
    {
      name: t("Price"),
      selector: row => (
        row.promotion ? (
          <>
            <span>{calculateDiscountedPrice(row)} €</span>{' '}
            <span style={{ textDecoration: 'line-through', color: 'gray' }}>{row.price} €</span>
          </>
        ) : (
          <span>{row.price} €</span>
        )
      ),
      sortable: true,
    },
    {
      name: t("Promotion"),
      selector: row => row.promotion ? (
        <Button color="info" onClick={() => handlePromotionClick(row.promotion, row.id)}>
          {row.promotion.is_percentage ? `${row.promotion.amount}%` : `-${row.promotion.amount}`}
        </Button>
      ) : (
        <Button color="primary" onClick={() => handlePromotionClick(null, row.id)}>
          <FiPlus /> {t("Promotion")}
        </Button>
      ),
    },
    {
      name: t("Enabled"),
      selector: row => row.is_enabled ? (
        <FiCheckCircle color="green" />
      ) : (
        <FiXCircle color="red" />
      ),
      sortable: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Services")} pageTitle={t("Home")} />
          <Card>
            <CardBody>
              <Row className="g-4 mb-3">
                <Col md="3"></Col>
                <Col md="3"></Col>
                <Col md="6">
                  <div className="d-flex justify-content-md-end">
                    <div className="search-box sm-12" style={{ "width": "300px" }}>
                      <input 
                        type="text" 
                        className="form-control search" 
                        placeholder={t("Search...")} 
                        value={searchTerm} 
                        onChange={handleSearch} 
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </Col>
              </Row>

              {showSuccess && <Alert color="success">Service updated successfully!</Alert>}

              <DataTable
                columns={columns}
                data={filteredData}
                progressPending={loading}
                pagination
                highlightOnHover
              />
            </CardBody>
          </Card>
        </Container>

        {/* Service Edit Modal */}
        {selectedService && (
          <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
            <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Edit Service</ModalHeader>
            <ModalBody>
              <Label>Name</Label>
              <Input
                type="text"
                value={selectedService.name}
                onChange={(e) => setSelectedService({ ...selectedService, name: e.target.value })}
              />
              <Label>Description</Label>
              <Input
                type="textarea"
                value={selectedService.description}
                onChange={(e) => setSelectedService({ ...selectedService, description: e.target.value })}
              />
              <Label>Price</Label>
              <Input
                type="number"
                value={selectedService.price}
                onChange={(e) => setSelectedService({ ...selectedService, price: e.target.value })}
              />
              <Label>Duration</Label>
              <Input
                type="select"
                value={selectedService.duration}
                onChange={(e) => setSelectedService({ ...selectedService, duration: e.target.value })}
              >
                <option value="15">15 {t("minutes")}</option>
                <option value="30">30 {t("minutes")}</option>
                <option value="45">45 {t("minutes")}</option>
                <option value="60">1 {t("hour")}</option>
                {/* Add more options as needed */}
              </Input>
              <Label>Color</Label>
              <Input
                type="color"
                value={selectedService.color}
                onChange={(e) => setSelectedService({ ...selectedService, color: e.target.value })}
              />
              <Label>Popular</Label>
              <Input
                type="checkbox"
                checked={selectedService.is_popular}
                onChange={(e) => setSelectedService({ ...selectedService, is_popular: e.target.checked })}
              />
              <br></br>
              <Label>Assign Staff:</Label>
              {staffList && staffList.map(staff => (
                <div key={staff.id}>
                  <Input 
                    type="checkbox" 
                    checked={selectedStaff.includes(staff.id)} 
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedStaff([...selectedStaff, staff.id]);
                      } else {
                        setSelectedStaff(selectedStaff.filter(id => id !== staff.id));
                      }
                    }} 
                  /> 
                  {staff.name}
                </div>
              ))}
              <br></br>
              <Label>Enabled</Label>
              <Input
                type="checkbox"
                checked={selectedService.is_enabled}
                onChange={(e) => setSelectedService({ ...selectedService, is_enabled: e.target.checked })}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleUpdateService}>Update</Button>
              <Button color="secondary" onClick={() => setModalOpen(false)}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )}

        {/* Promotion Modal */}
        {selectedPromotion && (
          <Modal isOpen={promoModalOpen} toggle={() => setPromoModalOpen(!promoModalOpen)}>
            <ModalHeader toggle={() => setPromoModalOpen(!promoModalOpen)}>
              {selectedPromotion.id ? t("Edit Promotion") : t("Create Promotion")}
            </ModalHeader>
            <ModalBody>
              <Label>{t("Amount")}</Label>
              <Input
                type="number"
                value={selectedPromotion.amount}
                onChange={(e) => setSelectedPromotion({ ...selectedPromotion, amount: e.target.value })}
              />
              <Label>{t("Description")}</Label>
              <Input
                type="textarea"
                value={selectedPromotion.description}
                onChange={(e) => setSelectedPromotion({ ...selectedPromotion, description: e.target.value })}
              />
              <br />
              <Label>{t("Percentage")}</Label>
              <Input
                type="checkbox"
                checked={selectedPromotion.is_percentage}
                onChange={(e) => setSelectedPromotion({ ...selectedPromotion, is_percentage: e.target.checked })}
              />
              <br />
              <Label>{t("Date Range")}</Label>
              <DateRangePicker
                format="yyyy-MM-dd HH:mm:ss"
                placeholder={t("Choose Range")}
                value={dateRange}
                onChange={(value) => setDateRange(value)}
                showMeridian
                ranges={[]}
                style={{ width: '100%' }}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handlePromotionSave}>
                {selectedPromotion.id ? t("Save Changes") : t("Create Promotion")}
              </Button>
              <Button color="secondary" onClick={() => setPromoModalOpen(false)}>
                {t("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};

export default Starter;
