import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Card, CardHeader, CardBody, Col, Container, Row, Button, Modal, ModalBody, ModalHeader, ModalFooter, Input, Label
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from 'react-data-table-component';
import { useQuery, useMutation, gql } from '@apollo/client';

// Queries
const STAFF_QUERY = gql`
  query MyQuery($serviceProviderId: Int!) {
    staff(where: {service_provider_id: {_eq: $serviceProviderId}}) {
      id
      name
      username
      staff_to_service_relationship {
        service {
          id
          name
        }
      }
    }
  }
`;

const SERVICES_QUERY = gql`
  query MyQuery($serviceProviderId: Int!) {
    services(where: {service_provider_id: {_eq: $serviceProviderId}}) {
      id
      name
    }
  }
`;

const DELETE_RELATIONSHIPS_MUTATION = gql`
  mutation DeleteStaffServiceRelationships($staff_id: Int!) {
    delete_staff_to_service_relationship(where: {staff_id: {_eq: $staff_id}}) {
      affected_rows
    }
  }
`;

const INSERT_RELATIONSHIPS_MUTATION = gql`
  mutation InsertStaffServiceRelationships($relationships: [staff_to_service_relationship_insert_input!]!) {
    insert_staff_to_service_relationship(objects: $relationships) {
      affected_rows
    }
  }
`;

const UPDATE_STAFF_MUTATION = gql`
  mutation UpdateStaff($id: Int!, $name: String!, $username: String!) {
    update_staff_by_pk(pk_columns: {id: $id}, _set: {name: $name, username: $username}) {
      id
    }
  }
`;

const Starter = () => {
  const { t } = useTranslation();
  const serviceProviderId = parseInt(localStorage.getItem("opID"));
  
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  
  const { loading: loadingStaff, data: staffData, refetch: refetchStaff } = useQuery(STAFF_QUERY, {
    variables: { serviceProviderId },
  });

  const { loading: loadingServices, data: servicesData } = useQuery(SERVICES_QUERY, {
    variables: { serviceProviderId },
  });

  const [deleteRelationships] = useMutation(DELETE_RELATIONSHIPS_MUTATION);
  const [insertRelationships] = useMutation(INSERT_RELATIONSHIPS_MUTATION);
  const [updateStaff] = useMutation(UPDATE_STAFF_MUTATION);

  useEffect(() => {
    if (staffData && staffData.staff) {
      setFilteredData(staffData.staff);
    }
  }, [staffData]);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = staffData.staff.filter(staff => 
      staff.name.toLowerCase().includes(value) ||
      staff.username.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };

  const handleEditStaff = (staff) => {
    setSelectedStaff({
      ...staff,
      serviceIds: staff.staff_to_service_relationship.map(rel => rel.service.id),
    });
    setSelectedServices(staff.staff_to_service_relationship.map(rel => rel.service.id));
    setModalOpen(true);
  };

  const handleUpdateStaff = async () => {
    const relationships = selectedServices.map(serviceId => ({
      service_id: serviceId,
      staff_id: selectedStaff.id,
    }));

    try {
      // Step 1: Delete existing relationships
      await deleteRelationships({ variables: { staff_id: selectedStaff.id } });

      // Step 2: Insert new relationships
      await insertRelationships({ variables: { relationships } });

      // Step 3: Update the staff name and username
      await updateStaff({
        variables: {
          id: selectedStaff.id,
          name: selectedStaff.name,
          username: selectedStaff.username,
        },
      });

      // Close modal and refetch data
      setModalOpen(false);
      refetchStaff();

    } catch (error) {
      console.error("Error updating staff and services:", error);
    }
  };

  const columns = [
    {
      name: t("Name"),
      selector: row => row.name,
      sortable: true,
      cell: (staff) => (
        <Button color="link" onClick={() => handleEditStaff(staff)}>
          {staff.name}
        </Button>
      ),
    },
    {
      name: t("Username"),
      selector: row => row.username,
      sortable: true,
    },
    {
      name: t("Assigned Services"),
      cell: (staff) => staff.staff_to_service_relationship.map(rel => rel.service.name).join(", "),
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Staff")} pageTitle={t("Home")} />
          <Card>
            <CardBody>
              <Row className="g-4 mb-3">
                <Col md="6">
                  <div className="search-box sm-12" style={{ "width": "300px" }}>
                    <input 
                      type="text" 
                      className="form-control search" 
                      placeholder={t("Search...")} 
                      value={searchTerm} 
                      onChange={handleSearch} 
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
              </Row>
              <DataTable
                columns={columns}
                data={filteredData}
                progressPending={loadingStaff}
                pagination
                highlightOnHover
              />
            </CardBody>
          </Card>
        </Container>

        {selectedStaff && (
          <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
            <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Edit Staff</ModalHeader>
            <ModalBody>
              <Label>Name</Label>
              <Input
                type="text"
                value={selectedStaff.name}
                onChange={(e) => setSelectedStaff({ ...selectedStaff, name: e.target.value })}
              />
              <Label>Username</Label>
              <Input
                type="text"
                value={selectedStaff.username}
                onChange={(e) => setSelectedStaff({ ...selectedStaff, username: e.target.value })}
              />
              <Label>Assigned Services</Label>
              {servicesData && servicesData.services.map(service => (
                <div key={service.id}>
                  <Input 
                    type="checkbox" 
                    checked={selectedServices.includes(service.id)} 
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedServices([...selectedServices, service.id]);
                      } else {
                        setSelectedServices(selectedServices.filter(id => id !== service.id));
                      }
                    }} 
                  /> 
                  {service.name}
                </div>
              ))}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleUpdateStaff}>Update</Button>
              <Button color="secondary" onClick={() => setModalOpen(false)}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};

export default Starter;
